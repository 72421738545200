import { Container } from "@mui/material"
import { useSnackbar } from "notistack"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import { TLineItemTypeCreate } from "src/@types/lineItemType"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import LineItemTypeEditForm from "src/forms/line-item-type-edit"
import { createLineItemType } from "src/redux/slices/lineItemTypes"
import { useDispatch } from "src/redux/store"
import { PATHS } from "src/routes/paths"

export default function LineItemTypeCreatePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = async (data: TLineItemTypeCreate) => {
        try {
            const response = await dispatch(createLineItemType(data))
            if (response.status === 201) {
                enqueueSnackbar("Service created successfully", { variant: "success" })
                navigate(PATHS.quotes.lineItemTypes.details(response.data.id))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create a Service</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Create a Service"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Services",
                            href: PATHS.quotes.lineItemTypes.list(),
                        },
                        {
                            name: "Create",
                        },
                    ]}
                />
                <LineItemTypeEditForm onSubmit={onSubmit} />
            </Container>
        </>
    )
}
