import React, { useEffect, useRef } from "react"
import JSONEditor, { JSONEditorMode } from "jsoneditor"
import "jsoneditor/dist/jsoneditor.css"

interface JSONEditorProps {
    value: string | object
    onChange?: (jsonString: object) => void
}

const JsonEditorComponent: React.FC<JSONEditorProps> = ({ value, onChange }) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const jsoneditor = useRef<JSONEditor | null>(null)

    useEffect(() => {
        const options = {
            mode: "tree" as JSONEditorMode,
            modes: ["code", "tree", "view", "form", "text", "preview"] as JSONEditorMode[],
            onChange: () => {
                if (jsoneditor.current && onChange) {
                    const updatedJson = jsoneditor.current.get()
                    onChange(updatedJson)
                }
            },
        }

        if (containerRef.current) {
            jsoneditor.current = new JSONEditor(containerRef.current, options)
            jsoneditor.current.set(value)
        }

        return () => {
            if (jsoneditor.current) {
                jsoneditor.current.destroy()
            }
        }
    }, [onChange, value])

    useEffect(() => {
        if (jsoneditor.current) {
            jsoneditor.current.update(value)
        }
    }, [value])

    return <div className="jsoneditor-react-container" ref={containerRef} style={{ height: 400 }} />
}

export default JsonEditorComponent
