import axios from "axios"
import Cookies from "js-cookie"
// config
import { HOST_API_KEY } from "../config-global"

// ----------------------------------------------------------------------

function getCSRFToken() {
    const csrfToken = Cookies.get("csrftoken")
    if (csrfToken) {
        return csrfToken
    }
    return null
}

const axiosInstance = axios.create({
    baseURL: HOST_API_KEY,
    headers: {

        "Content-Type": "application/json",
    }, withCredentials: true,
})

axiosInstance.interceptors.request.use((config) => {
    const csrfToken = getCSRFToken()
    if (csrfToken) {
        config.headers["X-CSRFToken"] = csrfToken
    }
    return config
})

axiosInstance.interceptors.response.use(
    (response) => response
    // (error) => Promise.reject(error.response)
)

export default axiosInstance
