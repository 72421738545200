import { TFeeTypeCreate, TFeeTypeUpdate } from "src/@types/fee-type"
import axios from "../utils/axios"

const apiFetchFeeTypes = () => axios.get("/api/v1/quotes/fee-types/")

const apiFetchFeeType = (id: number) => axios.get(`/api/v1/quotes/fee-types/${id}/`)

const apiCreateFeeType = (data: TFeeTypeCreate) => axios.post("/api/v1/quotes/fee-types/", data)

const apiUpdateFeeType = (id: number, data: TFeeTypeUpdate) =>
    axios.put(`/api/v1/quotes/fee-types/${id}/`, data)

const apiDeleteFeeType = (id: number) => axios.delete(`/api/v1/quotes/fee-types/${id}/`)

export { apiFetchFeeTypes, apiFetchFeeType, apiCreateFeeType, apiUpdateFeeType, apiDeleteFeeType }
