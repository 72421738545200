import { TPackageItem, TPackage } from "src/@types/package"
import { useDispatch } from "src/redux/store"
import { addPackageItem, deletePackageItem, updateFee } from "src/redux/slices/quotes"
import { TFee } from "src/@types/fee"
import CheckboxCell from "./checkbox-cell"
import EditTextCell from "./edit-text-cell"
import EditNumberCell from "./edit-number-cell"

type Props = {
    fee: TFee
    packages: TPackage[]
    packageItems: TPackageItem[]
    displayOptions: (1 | 2 | 3)[]
    allFees: TFee[]
}

export default function FeeItemRow({
    fee,
    packages,
    packageItems,
    displayOptions,
    allFees,
}: Props) {
    const dispatch = useDispatch()

    const packageAlaCarte = packages[0]
    const package1 = packages[1]
    const package2 = packages[2]
    const package3 = packages[3]

    const packageAlaCarteItem =
        packageItems.filter((packageItem) => packageItem.package_id === packageAlaCarte.id)[0] ||
        null
    const package1Item =
        packageItems.filter((packageItem) => packageItem.package_id === package1.id)[0] || null
    const package2Item =
        packageItems.filter((packageItem) => packageItem.package_id === package2.id)[0] || null
    const package3Item =
        packageItems.filter((packageItem) => packageItem.package_id === package3?.id)[0] || null

    const onChangeOption = async (packageId: number, state: boolean) => {
        if (!state) {
            const packageItem = packageItems.filter((pi) => pi.package_id === packageId)[0]
            if (packageItem) {
                dispatch(deletePackageItem(packageItem))
            }
            return
        }

        const response = dispatch(
            addPackageItem({
                package_id: packageId,
                fee_id: fee.id,
            })
        )
        console.log(response)
    }

    let valueCell = (
        <EditNumberCell
            cellStyle={{ fontWeight: 700 }}
            state={fee.value}
            onChange={(value) => {
                dispatch(updateFee(fee.id, { value }))
            }}
            name={fee.name}
            colSpan={displayOptions.length}
        />
    )

    if (fee.data.display_value?.type === "fixed") {
        valueCell = (
            <td
                className="text-regular"
                style={{
                    textAlign: "center",
                    fontWeight: 700,
                }}
                colSpan={displayOptions.length}
            >
                {fee.data.display_value.value}
            </td>
        )
    }

    console.log(fee)

    if (
        fee.data.display_value !== undefined &&
        fee.data.display_value.value_0 !== undefined &&
        fee.data.display_value.type === "dynamic" &&
        fee.value === 0
    ) {
        const groupFees = allFees.filter(
            (f) => f.data.group && f.data.group === fee.data.display_value?.value_0?.group
        )
        console.log("groupFees", groupFees)
        const operation = fee.data.display_value?.value_0?.operation
        if (operation === "max") {
            valueCell = (
                <td
                    className="text-regular"
                    style={{
                        textAlign: "center",
                        fontWeight: 700,
                    }}
                    colSpan={displayOptions.length}
                >
                    {Math.max(...groupFees.map((f) => f.value))}
                </td>
            )
        }
    }

    return (
        <tr key={fee.id}>
            <CheckboxCell
                state={Boolean(package1Item || fee.data.packages?.mock_include?.includes(1))}
                onChange={(value) => {
                    onChangeOption(package1.id, value)
                }}
                bgColor="orange"
                disabled={fee.data.packages?.restrict_include_change?.includes(1)}
            />
            <CheckboxCell
                state={Boolean(package2Item || fee.data.packages?.mock_include?.includes(2))}
                onChange={(value) => {
                    onChangeOption(package2.id, value)
                }}
                bgColor="yellow"
                disabled={fee.data.packages?.restrict_include_change?.includes(2)}
            />
            <CheckboxCell
                state={Boolean(package3Item || fee.data.packages?.mock_include?.includes(3))}
                onChange={(value) => {
                    onChangeOption(package3.id, value)
                }}
                bgColor="LawnGreen"
                disabled={fee.data.packages?.restrict_include_change?.includes(3)}
            />
            <EditTextCell
                state={fee.display_name || fee.name}
                // formatter={(state) => formatLineItemDescription({ text: state, lineItem })}
                onChange={(value) => {
                    dispatch(updateFee(fee.id, { display_name: value }))
                }}
                name="Fee Name"
                textStyles={{ fontWeight: 700 }}
            />
            {/* <td className="text-regular">{fee.display_name || fee.name}</td> */}
            <EditTextCell
                state={fee.description}
                onChange={(value) => {
                    dispatch(updateFee(fee.id, { description: value }))
                }}
                name={fee.name}
                // textStyles={{ fontSize: 8 }}
            />
            {valueCell}
            <CheckboxCell
                state={Boolean(packageAlaCarteItem || fee.data.packages?.mock_include?.includes(0))}
                onChange={(value) => {
                    onChangeOption(packageAlaCarte.id, value)
                }}
                disabled={fee.data.packages?.restrict_include_change?.includes(0)}
            />
        </tr>
    )
}
