import { TContainerCreate, TContainerUpdate } from "src/@types/containers"
import axios from "../utils/axios"

const apiUpdateContainer = (id: number, changes: TContainerUpdate) =>
    axios.patch(`/api/v1/content/containers/${id}/`, changes)

const apiCreateContainer = (payload: TContainerCreate) =>
    axios.post(`/api/v1/content/containers/`, payload)

const apiDeleteContainer = (id: number) => axios.delete(`/api/v1/content/containers/${id}/`)

export type FetchContainerFilters = {
    related_entity_ids?: number[]
    related_entity_type?: string
    types?: string[]
}

const apiFetchContainers = (filters: FetchContainerFilters) => {
    const params: any = {
        page_size: 1000,
    }
    if (filters.related_entity_ids) {
        params.related_entity_ids = filters.related_entity_ids.join(",")
    }
    if (filters.related_entity_type) {
        params.related_entity_type = filters.related_entity_type
    }
    if (filters.types) {
        params.type__in = filters.types.join(",")
    }

    return axios.get(`/api/v1/content/containers/`, { params })
}

const apiFetchContainer = (id: number) => axios.get(`/api/v1/content/containers/${id}/`)

export {
    apiUpdateContainer,
    apiCreateContainer,
    apiFetchContainers,
    apiDeleteContainer,
    apiFetchContainer,
}
