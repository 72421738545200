import axios from '../utils/axios'


const apiLogin = (email: string, password: string) =>
    axios.post('/api/v1/auth/login/',
        {
            email,
            password,
        })


const apiLogout = () => axios.post('/api/v1/auth/logout/')


const apiFetchCurrentUser = () =>
    axios.get('/api/v1/auth/users/me/'
    )



export { apiLogin, apiFetchCurrentUser, apiLogout }