import { TPermission } from "./permission"
import { TUser, TUserNested } from "./user"

export enum Permissions {
    GROUP_VIEW = "view_group",
    GROUP_UPDATE = "change_group",
    GROUP_DELETE = "delete_group",
    GROUP_CREATE = "add_group",

    USER_VIEW = "view_customuser",
    USER_CREATE = "add_customuser",
    USER_UPDATE = "change_customuser",
    USER_DELETE = "delete_customuser",

    PERMISSION_VIEW = "view_permission",

    QUOTE_CREATE = "add_quote",
    QUOTE_DELETE = "delete_quote",
    QUOTE_EXPORT = "export_quote",

    PACKAGE_TYPE_VIEW = "view_packagetype",
    PACKAGE_TYPE_CREATE = "add_packagetype",
    PACKAGE_TYPE_UPDATE = "change_packagetype",
    PACKAGE_TYPE_DELETE = "delete_packagetype",

    FINANCING_OPTION_TYPE_VIEW = "view_financingoptiontype",
    FINANCING_OPTION_TYPE_CREATE = "add_financingoptiontype",
    FINANCING_OPTION_TYPE_UPDATE = "change_financingoptiontype",
    FINANCING_OPTION_TYPE_DELETE = "delete_financingoptiontype",

    FINANCING_OPTION_DELETE = "delete_financingoption",

    MATERIAL_TYPE_VIEW = "view_materialtype",
    MATERIAL_TYPE_CREATE = "add_materialtype",
    MATERIAL_TYPE_UPDATE = "change_materialtype",
    MATERIAL_TYPE_DELETE = "delete_materialtype",

    // TODO: View permission does not block access to prices via material type
    MATERIAL_TYPE_PRICE_VIEW = "view_materialtypeprice",
    MATERIAL_TYPE_PRICE_CREATE = "add_materialtypeprice",
    MATERIAL_TYPE_PRICE_UPDATE = "change_materialtypeprice",
    MATERIAL_TYPE_PRICE_DELETE = "delete_materialtypeprice",

    LINE_ITEM_TYPE_VIEW = "view_lineitemtype",
    LINE_ITEM_TYPE_CREATE = "add_lineitemtype",
    LINE_ITEM_TYPE_UPDATE = "change_lineitemtype",
    LINE_ITEM_TYPE_DELETE = "delete_lineitemtype",

    FEE_TYPE_VIEW = "view_feetype",
    FEE_TYPE_CREATE = "add_feetype",
    FEE_TYPE_UPDATE = "change_feetype",
    FEE_TYPE_DELETE = "delete_feetype",

    MEASUREMENT_TYPE_VIEW = "view_measurementtype",
    MEASUREMENT_TYPE_CREATE = "add_measurementtype",
    MEASUREMENT_TYPE_UPDATE = "change_measurementtype",
    MEASUREMENT_TYPE_DELETE = "delete_measurementtype",

    MEASUREMENT_TYPE_FIELD_VIEW = "view_measurementtypefield",
    MEASUREMENT_TYPE_FIELD_CREATE = "add_measurementtypefield",
    MEASUREMENT_TYPE_FIELD_UPDATE = "change_measurementtypefield",
    MEASUREMENT_TYPE_FIELD_DELETE = "delete_measurementtypefield",

    QUOTE_TEMPLATE_VIEW = "view_quotetemplate",
    QUOTE_TEMPLATE_CREATE = "add_quotetemplate",
    QUOTE_TEMPLATE_UPDATE = "change_quotetemplate",
    QUOTE_TEMPLATE_DELETE = "delete_quotetemplate",

    QUOTE_TEMPLATE_ITEM_VIEW = "view_quotetemplateitem",
    QUOTE_TEMPLATE_ITEM_CREATE = "add_quotetemplateitem",
    QUOTE_TEMPLATE_ITEM_UPDATE = "change_quotetemplateitem",
    QUOTE_TEMPLATE_ITEM_DELETE = "delete_quotetemplateitem",

    QUOTE_TEMPLATE_RELATIONSHIP_VIEW = "view_quotetemplaterelationship",
    QUOTE_TEMPLATE_RELATIONSHIP_CREATE = "add_quotetemplaterelationship",
    QUOTE_TEMPLATE_RELATIONSHIP_UPDATE = "change_quotetemplaterelationship",
    QUOTE_TEMPLATE_RELATIONSHIP_DELETE = "delete_quotetemplaterelationship",

    CATEGORY_VIEW = "view_category",
    CATEGORY_CREATE = "add_category",
    CATEGORY_UPDATE = "change_category",
    CATEGORY_DELETE = "delete_category",

    CONTAINER_VIEW = "view_container",
    CONTAINER_CREATE = "add_container",
    CONTAINER_UPDATE = "change_container",
    CONTAINER_DELETE = "delete_container",

    BETA = "beta",
    ADMIN = "admin",
}

export type TGroup = {
    id: number
    name: string
    permissions: TPermission[]
    users: TUser[]
}

export type TGroupCreate = {
    name: string
}

export type TGroupUpdate = {
    name?: string
    permission_ids?: number[]
    user_ids?: number[]
}

export type TGroupsState = {
    groups: TGroup[]
    isLoading: boolean
    users: TUserNested[]
    permissions: TPermission[]
}
