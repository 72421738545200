import { TLineItem } from "src/@types/lineItem"
import { TMaterial } from "src/@types/material"
import { TPackageItem, TPackage } from "src/@types/package"
import { formatLineItemDescription, getLineItemName } from "src/services/line-item-service"
import { useDispatch } from "src/redux/store"
import {
    addPackageItem,
    deletePackageItem,
    updateLineItem,
    updateMaterial,
} from "src/redux/slices/quotes"
import ChooseProductCell from "./choose-option-cell"
import EditTextCell from "./edit-text-cell"
import EditNumbersCell from "./edit-numbers-cell"

type Props = {
    lineItem: TLineItem
    packages: TPackage[]
    packageItems: TPackageItem[]
    materials: TMaterial[]
    displayOptions: (1 | 2 | 3)[]
    displayOptionsLabels: string[]
}

export default function LineItemRowTwoOptions({
    lineItem,
    packages,
    packageItems,
    materials,
    displayOptions,
    displayOptionsLabels,
}: Props) {
    const dispatch = useDispatch()

    const materialOption1 = materials.filter((material) => material.option === 1)[0] || null
    const materialOption2 = materials.filter((material) => material.option === 2)[0] || null
    const materialOption3 = materials.filter((material) => material.option === 3)[0] || null
    const materialOptions: { [key: number]: TMaterial } = {}
    if (materialOption1) materialOptions[materialOption1.id] = materialOption1
    if (materialOption2) materialOptions[materialOption2.id] = materialOption2
    if (materialOption3) materialOptions[materialOption3.id] = materialOption3

    const packageAlaCarte = packages[0]
    const package1 = packages[1]
    const package2 = packages[2]
    const package3 = packages[3]

    const packageAlaCarteItem =
        packageItems.filter((packageItem) => packageItem.package_id === packageAlaCarte.id)[0] ||
        null
    const package1Item =
        packageItems.filter((packageItem) => packageItem.package_id === package1.id)[0] || null
    const package2Item =
        packageItems.filter((packageItem) => packageItem.package_id === package2.id)[0] || null
    const package3Item =
        packageItems.filter((packageItem) => packageItem.package_id === package3?.id)[0] || null

    const packageAlaCarteOption = packageAlaCarteItem
        ? materialOptions[packageAlaCarteItem.material_id].option
        : null
    const package1Option = package1Item ? materialOptions[package1Item.material_id].option : null
    const package2Option = package2Item ? materialOptions[package2Item.material_id].option : null
    const package3Option = package3Item ? materialOptions[package3Item.material_id].option : null

    const onChangeOption = async (packageId: number, option: number | null) => {
        console.log("onChangeOption", packageId, option)
        if (option === null) {
            const packageItem = packageItems.filter((pi) => pi.package_id === packageId)[0]
            if (packageItem) {
                dispatch(deletePackageItem(packageItem))
            }
            return
        }
        // const material = option === 1 ? materialOption1 : materialOption2
        const material = materials.filter((m) => m.option === option)[0]

        const response = dispatch(
            addPackageItem({
                package_id: packageId,
                line_item_id: lineItem.id,
                material_id: material.id,
            })
        )
        console.log(response)
    }

    const onUpdateMaterialTotal = (materialId: number | null, total: number) => {
        if (!materialId) return
        const response = dispatch(updateMaterial(materialId, { total }))
        console.log(response)
    }

    const lineItemValues: any = []
    if (lineItem.data.allow_flat_fee) {
        lineItemValues.push({
            value: lineItem.flat_fee,
            onChange: (value: number) => {
                dispatch(updateLineItem(lineItem.id, { flat_fee: value }))
            },
            name: "Flat Fee",
        })
    }

    return (
        <tr key={lineItem.id}>
            <ChooseProductCell
                state={package1Option}
                bgColor="orange"
                onChange={(value) => onChangeOption(package1.id, value)}
                materials={materials}
                displayOptions={displayOptions}
                displayOptionsLabels={displayOptionsLabels}
            />
            <ChooseProductCell
                state={package2Option}
                bgColor="yellow"
                onChange={(value) => onChangeOption(package2.id, value)}
                materials={materials}
                displayOptions={displayOptions}
                displayOptionsLabels={displayOptionsLabels}
            />
            <ChooseProductCell
                state={package3Option}
                bgColor="LawnGreen"
                onChange={(value) => onChangeOption(package3.id, value)}
                materials={materials}
                displayOptions={displayOptions}
                displayOptionsLabels={displayOptionsLabels}
            />
            <EditTextCell
                state={getLineItemName(lineItem)}
                // formatter={(state) => formatLineItemDescription({ text: state, lineItem })}
                onChange={(value) => {
                    dispatch(updateLineItem(lineItem.id, { display_name: value }))
                }}
                name="Service Name"
                textStyles={{ fontSize: 8, fontWeight: 700 }}
            />
            {/* <td className="text-regular">{getLineItemName(lineItem)}</td> */}
            <EditTextCell
                state={lineItem.description}
                formatter={(state) => formatLineItemDescription({ text: state, lineItem })}
                onChange={(value) => {
                    dispatch(updateLineItem(lineItem.id, { description: value }))
                }}
                name={getLineItemName(lineItem)}
                // textStyles={{ fontSize: 8 }}
            />
            {displayOptions.includes(1) &&
                (materialOption1 ? (
                    <EditNumbersCell
                        cellStyle={{ fontWeight: 700 }}
                        state={(materialOption1?.total || 0) + lineItem.flat_fee || null}
                        values={[
                            ...lineItemValues,
                            {
                                value: materialOption1?.total || 0,
                                name: materialOption1?.display_name || materialOption1?.name,
                                onChange: (value) =>
                                    onUpdateMaterialTotal(materialOption1?.id, value),
                            },
                        ]}
                        name={lineItem.name}
                    />
                ) : (
                    <td style={{ textAlign: "center" }}>-</td>
                ))}
            {displayOptions.includes(2) &&
                (materialOption2 ? (
                    <EditNumbersCell
                        cellStyle={{ fontWeight: 700 }}
                        state={(materialOption2?.total || 0) + lineItem.flat_fee || null}
                        values={[
                            ...lineItemValues,
                            {
                                value: materialOption2?.total || 0,
                                name: materialOption2?.display_name || materialOption2?.name,
                                onChange: (value) =>
                                    onUpdateMaterialTotal(materialOption2?.id, value),
                            },
                        ]}
                        name={lineItem.name}
                    />
                ) : (
                    <td style={{ textAlign: "center" }}>-</td>
                ))}
            {displayOptions.includes(3) &&
                (materialOption3 ? (
                    <EditNumbersCell
                        cellStyle={{ fontWeight: 700 }}
                        state={(materialOption3?.total || 0) + lineItem.flat_fee || null}
                        values={[
                            ...lineItemValues,
                            {
                                value: materialOption3?.total || 0,
                                name: materialOption3?.display_name || materialOption3?.name,
                                onChange: (value) =>
                                    onUpdateMaterialTotal(materialOption3?.id, value),
                            },
                        ]}
                        name={lineItem.name}
                    />
                ) : (
                    <td style={{ textAlign: "center" }}>-</td>
                ))}
            <ChooseProductCell
                state={packageAlaCarteOption}
                onChange={(value) => onChangeOption(packageAlaCarte?.id, value)}
                materials={materials}
                displayOptions={displayOptions}
                displayOptionsLabels={displayOptionsLabels}
            />
        </tr>
    )
}
