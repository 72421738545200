import { useNavigate } from "react-router"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, Stack } from "@mui/material"
import { RHFTextField } from "src/components/hook-form"
import { LoadingButton } from "@mui/lab"
import { PATHS } from "src/routes/paths"
import JsonEditorComponent from "src/components/json-editor"

type FormData = {
    id: number | null
    name: string
    display_name: string
    description: string
    value: number | null
    data: {
        [key: string]: any
    }
}

type Props = {
    initialData?: FormData
    onSubmit: (data: FormData) => void
}

export default function FeeTypeForm({ initialData, onSubmit }: Props) {
    const navigate = useNavigate()

    const [editorData, setEditorData] = useState(initialData?.data || {})

    const defaultValues = {
        id: null,
        name: "",
        display_name: "",
        description: "",
        value: null,
        data: {},
    }

    const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        // value: Yup.number().required("Value is required"),
        // description: Yup.string().required("Description is required"),
        // category_id: Yup.number().nullable().required('Category is required'),
    })

    const methods = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues,
    })

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData)
            setEditorData(initialData.data)
        }
    }, [initialData, methods])

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }}>
                            <RHFTextField name="name" label="Name" required autoFocus fullWidth />
                            <RHFTextField name="display_name" label="Display Name" fullWidth />
                            <RHFTextField name="description" label="Description" fullWidth />
                            <RHFTextField name="value" label="Value" type="number" fullWidth />
                        </Stack>
                        <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }}>
                            <JsonEditorComponent
                                value={editorData}
                                onChange={(value) => methods.setValue("data", value)}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.feeTypes.list())}
                >
                    Cancel
                </Button>
                <LoadingButton
                    size="small"
                    variant="contained"
                    loading={false}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </LoadingButton>
            </Stack>
        </FormProvider>
    )
}
