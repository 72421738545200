import { useEffect, useState } from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    IconButton,
    InputAdornment,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

type Props = {
    state: number | null
    onChange: (value: number) => void
    name: string
    colSpan?: number
    cellStyle?: React.CSSProperties
    formatState?: (value: number | null) => string | number | null
    disabled?: boolean
}

export default function EditNumberCell({
    state,
    onChange,
    name,
    colSpan,
    cellStyle = {},
    formatState = (value) => value,
    disabled = false,
}: Props) {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState<number>(state || 0)

    console.log(state)

    useEffect(() => {
        setValue(state || 0)
    }, [state])

    const handleOpenDialog = (event: any) => {
        if (disabled) return
        setOpen(true)
        setValue(state || 0)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }

    const adjustValue = (amount: number) => {
        if ((value || 0) + amount < 0) return
        const newValue = (value || 0) + amount
        setValue(newValue)
    }

    return (
        <>
            <td
                style={{
                    padding: "0px",
                    position: "relative",
                    // fontSize: "10px",
                    ...cellStyle,
                }}
                colSpan={colSpan}
                className="text-regular"
            >
                <div
                    role="button"
                    tabIndex={0}
                    onClick={handleOpenDialog}
                    onKeyDown={() => {}} // For accessibility
                    style={{
                        cursor: disabled ? "unset" : "pointer",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        // minHeight: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        outline: "none",
                    }}
                >
                    {disabled ? "-" : (formatState(state) || 0).toLocaleString()}
                </div>
            </td>
            <Dialog open={open} onClose={handleCloseDialog} maxWidth="xs">
                <DialogTitle>Edit</DialogTitle>
                <DialogContent>
                    <DialogContentText>{name}</DialogContentText>
                    <TextField
                        autoFocus
                        sx={{ mt: 1 }}
                        fullWidth
                        value={value}
                        type="number"
                        inputMode="numeric"
                        onChange={(e) => setValue(parseInt(e.target.value, 10))}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={() => adjustValue(-5)}>
                                        <RemoveIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => adjustValue(5)}>
                                        <AddIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{
                            min: 0,
                            step: 5,
                            pattern: "[0-9]*",
                            style: { textAlign: "center" },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="error">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onChange(value || 0)
                            handleCloseDialog()
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
