// import { Unit } from "src/@types/units"
import * as Yup from "yup"
import { useNavigate } from "react-router"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, MenuItem, Stack } from "@mui/material"
import { RHFSelect, RHFTextField } from "src/components/hook-form"
import { PATHS } from "src/routes/paths"
import JsonEditorComponent from "src/components/json-editor"
import { TContainerType } from "src/@types/containers"

type FormData = {
    id: number | null
    name: string
    value: number | null
    status: string
    effective_from: string | null
    effective_to: string | null
    type: string
    data: Object
}

type Props = {
    initialData?: FormData
    onSubmit: (data: FormData) => void
    type: string
}

export default function ContainerForm({ initialData, onSubmit, type }: Props) {
    const navigate = useNavigate()

    const [editorData, setEditorData] = useState(initialData?.data || {})

    const defaultValues = {
        id: null,
        name: "",
        value: 0,
        status: "active",
        effective_from: null,
        effective_to: null,
        type,
        data: {},
    }

    const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        // description: Yup.string().required("Description is required"),
        value: Yup.number(),
        type: Yup.string().required("Type is required"),
    })

    const methods = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues,
    })

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData)
            setEditorData(initialData.data)
        }
    }, [initialData, methods])

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        <Stack direction="column" spacing={2} flex={1}>
                            <RHFTextField name="name" label="Name" required />
                            <RHFSelect name="type" label="Type" required>
                                {Object.values(TContainerType).map((containerType) => (
                                    <MenuItem key={containerType} value={containerType}>
                                        {containerType}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFTextField name="value" label="Value" type="number" />
                            <RHFTextField
                                name="effective_from"
                                label="Effective From (YYYY-MM-DD)"
                                defaultValue=""
                            />
                            <RHFTextField
                                name="effective_to"
                                label="Effective To (YYYY-MM-DD)"
                                defaultValue=""
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={2}
                        // justifyContent="space-between"
                        sx={{ mt: 2 }}
                        flex={1}
                    >
                        <JsonEditorComponent
                            value={editorData}
                            onChange={(value) => methods.setValue("data", value)}
                        />
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.containers.list())}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={methods.handleSubmit(onSubmit)}
                    disabled={!methods.formState.isValid}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </Button>
            </Stack>
        </FormProvider>
    )
}
