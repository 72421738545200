import {
    TLineItemTypeUpdate,
    TLineItemTypeCreate,
    TLineItemTypesRelationshipCreate,
} from "src/@types/lineItemType"
import axios from "../utils/axios"

const apiFetchLineItemTypes = () => axios.get("/api/v1/quotes/line-item-types/")

const apiFetchLineItemType = (id: number) => axios.get(`/api/v1/quotes/line-item-types/${id}/`)

const apiUpdateLineItemType = (id: number, changes: TLineItemTypeUpdate) =>
    axios.patch(`/api/v1/quotes/line-item-types/${id}/`, changes)

const apiCreateLineItemType = (payload: TLineItemTypeCreate) =>
    axios.post(`/api/v1/quotes/line-item-types/`, payload)

const apiDeleteLineItemType = async (id: number) => {
    try {
        return await axios.delete(`/api/v1/quotes/line-item-types/${id}/`)
    } catch (error) {
        console.log("Error:", error)
        return error.response
    }
}

const apiCreateLineItemTypesRelationship = (payload: TLineItemTypesRelationshipCreate) =>
    axios.post(`/api/v1/quotes/line-item-types/relationships/`, payload)

const apiDeleteLineItemTypesRelationship = (id: number) =>
    axios.delete(`/api/v1/quotes/line-item-types/relationships/${id}/`)

const apiCopyLineItemType = (id: number) => axios.post(`/api/v1/quotes/line-item-types/${id}/copy/`)

export {
    apiUpdateLineItemType,
    apiFetchLineItemType,
    apiFetchLineItemTypes,
    apiCreateLineItemType,
    apiDeleteLineItemType,
    apiCreateLineItemTypesRelationship,
    apiDeleteLineItemTypesRelationship,
    apiCopyLineItemType,
}
