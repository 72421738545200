import { TMaterial } from "src/@types/material"
import { Menu, MenuItem } from "@mui/material"
import { useState } from "react"

type MaterialOptions = 1 | 2 | 3 | null

type Props = {
    state: MaterialOptions
    bgColor?: string
    onChange: (value: MaterialOptions) => void
    materials: TMaterial[]
    displayOptions: (1 | 2 | 3)[]
    displayOptionsLabels: string[]
}

export default function ChooseProductCell({
    state,
    bgColor = "white",
    onChange,
    materials,
    displayOptions,
    displayOptionsLabels,
}: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleOpenMenu = (event: any) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const availableMaterialOptions = materials.map((material) => material.option)

    const menuItems: any = []
    displayOptions.forEach((option) => {
        if (state === option) return
        if (!availableMaterialOptions.includes(option)) {
            console.log("Option not available", option)
            return
        }
        menuItems.push({
            value: option,
            text:
                displayOptionsLabels[option - 1] === "Traditional"
                    ? "Trad"
                    : displayOptionsLabels[option - 1],
        })
    })

    // console.log("menuItems", menuItems)

    if (state !== null) {
        menuItems.push({
            value: null,
            text: "N/A",
        })
    }

    let stateText: string | null = null

    // console.log("displayOptions", displayOptions)
    // console.log("displayOptionsLabels", displayOptionsLabels)

    if (state !== null) {
        stateText =
            displayOptionsLabels[state - 1] === "Traditional"
                ? "Trad"
                : displayOptionsLabels[state - 1]
    }

    return (
        <>
            <td
                style={{
                    backgroundColor: bgColor,
                    width: "40px",
                    padding: "0px",
                    position: "relative",
                    backgroundClip: "padding-box",
                    margin: 0,
                }}
            >
                <div
                    role="button"
                    tabIndex={0}
                    onClick={handleOpenMenu}
                    onKeyDown={() => {}} // For accessibility
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "8px",
                    }}
                >
                    {stateText}
                </div>
            </td>
            <Menu
                id="choose-option-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                transformOrigin={{ vertical: "center", horizontal: "center" }}
            >
                {menuItems.map((item: any) => (
                    <MenuItem
                        key={item.value}
                        dense
                        onClick={(e: any) => {
                            onChange(item.value)
                            handleCloseMenu()
                        }}
                    >
                        {item.text}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
