/* eslint-disable */
import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    ListItem,
    ListItemText,
    Radio,
    RadioGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getLineItemTypes } from "src/redux/slices/lineItemTypes"
import { getCategories } from "src/redux/slices/categories"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { Category } from "src/@types/category"
import { TLineItemType } from "src/@types/lineItemType"
import {
    createQuoteTemplateItem,
    createQuoteTemplateRelationship,
    createQuoteTemplateSection,
    deleteQuoteTemplateRelationship,
    getQuoteTemplate,
    getQuoteTemplates,
    reorderQuoteTemplateItems,
    updateQuoteTemplate,
} from "src/redux/slices/quote-templates"
import QuoteTemplateEditForm from "src/forms/quote-template-edit"
import { TQuoteTemplate, TQuoteTemplateItem, TQuoteTemplateUpdate } from "src/@types/quote-template"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import QuoteTemplateItem from "./item"
import { getFeeTypes } from "src/redux/slices/fee-types"
import { TFeeType } from "src/@types/fee-type"
import { TFinancingOptionType } from "src/@types/financing-option-type"
import { getFinancingOptionTypes } from "src/redux/slices/financing-option-types"
import { getPackageTypes } from "src/redux/slices/package-types"
import { TPackageType } from "src/@types/package-type"
import { getCategoriesPath } from "../../line-item-types/line-item-types-list"
import { getContainers } from "src/redux/slices/containers"
import { TContainer } from "src/@types/containers"
import QuoteTemplateSection from "./section"
import { NavLink } from "react-router-dom"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"

// format the id to number, for example "session-1" to 1
const extractId = (id: string | null): number | null => {
    if (id === null) return null
    const parts = id.split("-")
    if (parts.length !== 2) return null
    return parseInt(parts[1], 10) || null
}

const getAllCategoryChildren = (id: number | null, categories: Category[]): Category[] => {
    if (id === null) return []
    const children = categories.filter((item) => item.parent_id === id)
    if (children.length === 0) return []

    return children.reduce((acc: Category[], category: Category) => {
        if (acc.includes(category)) return acc
        return acc.concat(category, getAllCategoryChildren(category.id, categories))
    }, [])
}

export default function QuoteTemplateDetailsPage() {
    const { id } = useParams<{ id: string }>()

    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)
    const [feeTypeToAdd, setFeeTypeToAdd] = useState<TFeeType | null>(null)
    const [sectionName, setSectionName] = useState<string>("")
    const [financingOptionToAdd, setFinancingOptionToAdd] = useState<TFinancingOptionType | null>(
        null
    )
    const [selectedChildTemplate, setSelectedChildTemplate] = useState<TQuoteTemplate | null>(null)
    const [selectedChildTemplateMessage, setSelectedChildTemplateMessage] = useState<string>("")
    const [isSending, setIsSending] = useState(false)
    const [lineItemsState, setLineItemsState] = useState<TQuoteTemplateItem[]>([])
    const [feesItemsState, setFeesItemsState] = useState<TQuoteTemplateItem[]>([])

    const dispatch = useDispatch()
    const containers = useSelector((state) =>
        state.containers.containers.filter((container) =>
            ["terms_and_conditions", "jha_item"].includes(container.type)
        )
    )

    // console.log(containers)
    const { enqueueSnackbar } = useSnackbar()

    const { hasPermission } = useAuthContext()

    useEffect(() => {
        dispatch(getCategories())
        dispatch(getLineItemTypes())
        dispatch(getFeeTypes())
        dispatch(getFinancingOptionTypes())
        dispatch(getPackageTypes())
        dispatch(getQuoteTemplates())
        dispatch(getContainers({ types: ["terms_and_conditions"] }))
    }, [dispatch])

    useEffect(() => {
        const getQuoteTemplateAsync = async () => {
            if (idAsNumber) {
                const response = await dispatch(getQuoteTemplate(idAsNumber))
                setIsLoading(false)
            }
        }
        getQuoteTemplateAsync()
    }, [dispatch, idAsNumber])

    const quoteTemplate = useSelector((state) =>
        state.quoteTemplates.quoteTemplates.find((item) => item.id === idAsNumber)
    )
    const sections =
        useSelector((state) => state.quoteTemplates.sectionsByQuoteTemplateId[idAsNumber]) || []
    const allLineItemTypes = useSelector((state) => state.lineItemTypes.lineItemTypes)
    const feeTypes = useSelector((state) => state.feeTypes.feeTypes)
    const financingOptionTypes = useSelector(
        (state) => state.financingOptionTypes.financingOptionTypes
    )
    const categoriesById = useSelector((state) => state.categories.categories.byId)
    const categories = Object.values(categoriesById)
    const items =
        useSelector((state) => state.quoteTemplates.itemsByQuoteTemplateId[idAsNumber]) || []

    // const lineItemTypeItems = items.filter((item) => item.line_item_type !== null)
    // const feeTypeItems = items.filter((item) => item.fee_type !== null)
    const allTemplates = useSelector((state) => state.quoteTemplates.quoteTemplates)
    const templateRelationships =
        useSelector((state) => state.quoteTemplates.childrenByQuoteTemplateId[idAsNumber]) || []
    const packageTypes = useSelector((state) => state.packageTypes.packageTypes)
    const chosenPackageTypesIds = quoteTemplate?.package_types.map((item) => item.id) || []
    const packageTypesOptions = packageTypes.filter(
        (item) => !chosenPackageTypesIds.includes(item.id)
    )

    console.log("First item", lineItemsState[0]?.line_item_type?.name)

    useEffect(() => {
        setLineItemsState(
            items.filter(
                (item) => item.line_item_type !== null || item.financing_option_type !== null
            )
        )
        setFeesItemsState(items.filter((item) => item.fee_type !== null))
        console.log("Setting items state")
    }, [JSON.stringify(items)])

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (quoteTemplate === undefined) {
        return <div>Not found</div>
    }

    const onSubmit = async (data: TQuoteTemplateUpdate) => {
        try {
            const response = await dispatch(updateQuoteTemplate(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Quote template updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const categoriesForLineItems = getAllCategoryChildren(quoteTemplate.category_id, categories)
    if (quoteTemplate.category_id !== null && categoriesById[quoteTemplate.category_id]) {
        categoriesForLineItems.push(categoriesById[quoteTemplate.category_id])
    }
    const categoriesForLineItemsIds = categoriesForLineItems.map((item) => item.id)
    const lineItemTypes = allLineItemTypes.filter((item) =>
        // @ts-ignore
        categoriesForLineItemsIds.includes(item?.category?.id)
    )

    console.log("Re-Render")

    const handleFeesDragEnd = async (result: DropResult) => {
        console.log("handleFeesDragEnd", { result })
        if (!result.destination) return
        const reorderedItems = [...feesItemsState]
        const [removed] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, removed)
        // const newState = [...reorderedItems]
        setFeesItemsState(reorderedItems)
        const newOrder: { [key: number]: { order: number; section_id: number | null } } = {}
        reorderedItems.forEach((item, index) => {
            newOrder[item.id] = {
                order: index,
                section_id: item.section_id,
            }
        })

        const response = await dispatch(reorderQuoteTemplateItems(idAsNumber, newOrder))
        if (response.status === 200) {
            enqueueSnackbar("Quote template updated", { variant: "success" })
        }
    }

    const onCreateFinancingOptionTypeItem = async (item: TFinancingOptionType | null) => {
        if (!idAsNumber || !item) return
        setIsSending(true)
        let order = 0
        if (items.length > 0) {
            order = Math.max(...items.map((item) => item.order)) + 1
        }
        const response = await dispatch(
            createQuoteTemplateItem({
                quote_template_id: idAsNumber,
                financing_option_type_id: item.id,
                order,
                section_id: null,
            })
        )
        if (response.status === 201) {
            setIsSending(false)
            setFinancingOptionToAdd(null)
            enqueueSnackbar(`Financing option ${item.name} added to the template`, {
                variant: "success",
            })
        } else {
            setIsSending(false)
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    const onCreateFeeTypeItem = async (feeType: TFeeType | null) => {
        if (!idAsNumber || !feeType) return
        setIsSending(true)
        console.log("Fee Type:", { feeType })
        let order = 0
        if (items.length > 0) {
            order = Math.max(...items.map((item) => item.order)) + 1
        }
        const response = await dispatch(
            createQuoteTemplateItem({
                quote_template_id: idAsNumber,
                fee_type_id: feeType.id,
                order,
                section_id: null,
            })
        )
        if (response.status === 201) {
            setIsSending(false)
            setFeeTypeToAdd(null)
            enqueueSnackbar(`Fee ${feeType.name} added to the template`, {
                variant: "success",
            })
        } else {
            setIsSending(false)
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    const onUpdateTermsAndConditions = async (container: TContainer | null) => {
        const response = await dispatch(
            updateQuoteTemplate(idAsNumber, {
                terms_and_conditions_id: container ? container.id : null,
            })
        )
        if (response.status === 200) {
            enqueueSnackbar("Quote template updated", { variant: "success" })
        }
    }

    // const onUpdateJHAItems = async (containers: TContainer[]) => {
    //     const response = await dispatch(
    //         updateQuoteTemplate(idAsNumber, {
    //             jha_items_ids: containers.map((item) => item.id),
    //         })
    //     )
    //     if (response.status === 200) {
    //         enqueueSnackbar("Quote template updated", { variant: "success" })
    //     }
    // }

    const onCreateSection = async () => {
        if (!sectionName || isSending) return
        setIsSending(true)

        let order = 0
        if (sections.length > 0) {
            order = Math.max(...sections.map((item) => item.order)) + 1
        }

        const response = await dispatch(
            createQuoteTemplateSection({
                template_id: idAsNumber,
                name: sectionName,
                order,
            })
        )
        if (response.status === 201) {
            setSectionName("")
            enqueueSnackbar(`Template section ${sectionName} created`, { variant: "success" })
        }

        setIsSending(false)
    }

    const onUpdateAllowedLineItemCategories = async (categories: Category[]) => {
        const response = await dispatch(
            updateQuoteTemplate(idAsNumber, {
                allowed_line_item_categories_ids: categories.map((item) => item.id),
            })
        )
        if (response.status === 200) {
            enqueueSnackbar("Quote template updated", { variant: "success" })
        }
    }

    const onUpdateAllowedLineItemTypes = async (lineItemTypes: TLineItemType[]) => {
        const response = await dispatch(
            updateQuoteTemplate(idAsNumber, {
                allowed_line_item_types_ids: lineItemTypes.map((item) => item.id),
            })
        )
        if (response.status === 200) {
            enqueueSnackbar("Quote template updated", { variant: "success" })
        }
    }

    const onUpdatePackageTypes = async (packageTypes: TPackageType[]) => {
        const response = await dispatch(
            updateQuoteTemplate(idAsNumber, {
                package_type_ids: packageTypes.map((item) => item.id),
            })
        )
        if (response.status === 200) {
            enqueueSnackbar("Quote template updated", { variant: "success" })
        }
    }

    const onUpdateDisableJHA = async (v: boolean) => {
        const response = await dispatch(
            updateQuoteTemplate(idAsNumber, {
                data: {
                    ...quoteTemplate.data,
                    print_template: {
                        ...quoteTemplate.data.print_template,
                        disable_jha: v,
                    },
                },
            })
        )
        if (response.status === 200) {
            enqueueSnackbar("Quote template updated", { variant: "success" })
        }
    }

    const onEndDrag = async (result: DropResult) => {
        console.log("End drag", { result })
        if (!result.destination) {
            return
        }
        const sourceSectionId = extractId(result.source.droppableId)
        const destSectionId = extractId(result.destination.droppableId)
        console.log({ sourceSectionId, destSectionId })
        // if (sourceSectionId === destSectionId) {
        const reorderedItems = Array.from(items)
        const [removed] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, removed)
        const newState = [...reorderedItems]
        setLineItemsState(newState)
        const newOrder: { [key: number]: { order: number; section_id: number | null } } = {}
        reorderedItems.forEach((item, index) => {
            newOrder[item.id] = { order: index, section_id: item.section_id }
        })
        newOrder[removed.id] = { order: result.destination.index, section_id: destSectionId }
        const response = await dispatch(reorderQuoteTemplateItems(idAsNumber, newOrder))
        console.log("Response", { response })
        if (response.status === 200) {
            enqueueSnackbar("Quote template updated", { variant: "success" })
        }
        // }
    }

    const onChangeMaterialOptionColumns = (value: (1 | 2 | 3)[]) => {
        const newData = JSON.parse(JSON.stringify(quoteTemplate.data))
        if (!newData.print_template) newData.print_template = {}
        newData.print_template.display_options = value
        onSubmit({ data: newData })
    }

    const onChangeMaxDisplayedOptions = (event: any) => {
        const newData = JSON.parse(JSON.stringify(quoteTemplate.data))
        if (!newData.print_template) newData.print_template = {}
        newData.print_template.max_display_options = parseInt(event.target.value, 10)
        onSubmit({ data: newData })
    }

    return (
        <>
            <Helmet>
                <title>Update Quote Template</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Update Quote Template"
                    links={[
                        {
                            name: "Quote Templates",
                        },
                        {
                            name: "List",
                            href: PATHS.quotes.quoteTemplates.list(),
                        },
                        {
                            name: "Update",
                        },
                    ]}
                />
                <QuoteTemplateEditForm onSubmit={onSubmit} initialData={quoteTemplate} />
                <Typography variant="h4" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Services
                </Typography>
                <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Material Options
                </Typography>
                <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                    <Typography variant="body1">
                        Sets material options displayed by default.
                    </Typography>
                    <div>
                        <ToggleButtonGroup
                            value={quoteTemplate.data.print_template?.display_options || []}
                            onChange={async (e, values) => {
                                onChangeMaterialOptionColumns(values)
                            }}
                            size="small"
                        >
                            {[
                                quoteTemplate.data.print_template?.fields?.header_option_1,
                                quoteTemplate.data.print_template?.fields?.header_option_2,
                                quoteTemplate.data.print_template?.fields?.header_option_3,
                            ].map((title, index) => (
                                <ToggleButton key={`display_option_${index}`} value={index + 1}>
                                    {title}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                    <FormControl>
                        <FormLabel id="">Max # of Active Display Options:</FormLabel>
                        <RadioGroup
                            row
                            name="number-of-display-material-options"
                            value={quoteTemplate.data.print_template?.max_display_options || 1}
                            onChange={onChangeMaxDisplayedOptions}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="1" />
                            <FormControlLabel value={2} control={<Radio />} label="2" />
                            <FormControlLabel value={3} control={<Radio />} label="3" />
                        </RadioGroup>
                    </FormControl>
                    <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                        Allowed Services
                    </Typography>
                    <Typography variant="body1">
                        Specify the source categories for the services or specific services that can
                        added to the quote. If not specified, all services from the quote template
                        category and its subcategories are allowed.
                    </Typography>
                    <Autocomplete
                        id="allowed-line-item-categories-dropdown"
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => onUpdateAllowedLineItemCategories(value)}
                        value={quoteTemplate.allowed_line_item_categories}
                        options={categories}
                        getOptionKey={(option) => option.id}
                        multiple
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Allowed services categories"
                                helperText="Including subcategories"
                            />
                        )}
                        renderOption={(props, option) => {
                            const path = getCategoriesPath(option.id, categoriesById)
                            path.pop()
                            return (
                                <ListItem {...props}>
                                    <ListItemText
                                        primary={option.name}
                                        secondary={path.join(" > ")}
                                    />
                                </ListItem>
                            )
                        }}
                    />
                    <Autocomplete
                        id="allowed-line-item-types-dropdown"
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => onUpdateAllowedLineItemTypes(value)}
                        value={quoteTemplate.allowed_line_item_types}
                        options={allLineItemTypes}
                        getOptionKey={(option) => option.id}
                        multiple
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Allowed services"
                                helperText="Works cumulatively (OR-condition) with the categories above or the template category."
                            />
                        )}
                        renderOption={(props, option) => {
                            const path = getCategoriesPath(option.category_id || 0, categoriesById)
                            return (
                                <ListItem {...props}>
                                    <ListItemText
                                        primary={option.name}
                                        secondary={path.join(" > ")}
                                    />
                                </ListItem>
                            )
                        }}
                    />
                </Stack>
                <DragDropContext onDragEnd={onEndDrag}>
                    <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                        Default Services
                    </Typography>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="body1">
                            These services will be added to the quote by default even if they are
                            not allowed.
                        </Typography>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={1}
                            alignItems="center"
                            sx={{ mb: 2 }}
                        >
                            <TextField
                                label="Create a Section"
                                variant="outlined"
                                // size="small"
                                sx={{ width: "100%" }}
                                value={sectionName}
                                onChange={(e) => setSectionName(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{ ml: 1 }}
                                                onClick={onCreateSection}
                                                disabled={isSending}
                                            >
                                                Add
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        {sections.map((section) => (
                            <QuoteTemplateSection
                                template={quoteTemplate}
                                section={section}
                                key={section.id}
                                items={items}
                                lineItemTypes={lineItemTypes}
                            />
                        ))}
                        <QuoteTemplateSection
                            template={quoteTemplate}
                            section={null}
                            key="no-section"
                            items={items}
                            lineItemTypes={lineItemTypes}
                        />
                        {/* </DragDropContext> */}
                    </Stack>
                    <Typography variant="h4" component="h2" sx={{ mt: 4, mb: 2 }}>
                        Fees
                    </Typography>
                    <Stack direction="column" spacing={0}>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={1}
                            alignItems="center"
                            sx={{ mb: 2 }}
                        >
                            <Autocomplete
                                id="fee-types-dropdown"
                                getOptionLabel={(option) => option.name}
                                onChange={(event: React.SyntheticEvent, value: TFeeType | null) =>
                                    setFeeTypeToAdd(value)
                                }
                                value={feeTypeToAdd}
                                disabled={isSending}
                                options={feeTypes}
                                sx={{ width: "100%" }}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        // size="small"
                                        label="Add a Fee"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                        onClick={
                                                            () => onCreateFeeTypeItem(feeTypeToAdd)
                                                            // onCreateLineItem(lineItemTypeToAdd)
                                                        }
                                                        disabled={isSending}
                                                    >
                                                        Add
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                        <DragDropContext onDragEnd={handleFeesDragEnd}>
                            <Droppable droppableId="items-fee-types">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {feesItemsState.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id.toString()}
                                                index={index}
                                            >
                                                {(pr) => (
                                                    <div
                                                        ref={pr.innerRef}
                                                        {...pr.draggableProps}
                                                        {...pr.dragHandleProps}
                                                    >
                                                        <QuoteTemplateItem item={item} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Stack>
                    <Typography variant="h4" component="h2" sx={{ mt: 4, mb: 2 }}>
                        Financing Options
                    </Typography>
                    <Stack direction="column" spacing={0}>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={1}
                            alignItems="center"
                            sx={{ mb: 2 }}
                        >
                            <Autocomplete
                                id="financing-option-types-dropdown"
                                getOptionLabel={(option) => option.name}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: TFinancingOptionType | null
                                ) => setFinancingOptionToAdd(value)}
                                value={financingOptionToAdd}
                                disabled={isSending}
                                options={financingOptionTypes}
                                sx={{ width: "100%" }}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        // size="small"
                                        label="Add a Financing Option"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                        onClick={
                                                            () =>
                                                                onCreateFinancingOptionTypeItem(
                                                                    financingOptionToAdd
                                                                )
                                                            // onCreateLineItem(lineItemTypeToAdd)
                                                        }
                                                        disabled={isSending}
                                                    >
                                                        Add
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                        {/* <DragDropContext onDragEnd={handleFinancingOptionsDragEnd}> */}
                        <Droppable droppableId="items-financing-option-types">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {lineItemsState
                                        .filter((itm) => itm.financing_option_type !== null)
                                        .map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id.toString()}
                                                index={index}
                                            >
                                                {(pr) => (
                                                    <div
                                                        ref={pr.innerRef}
                                                        {...pr.draggableProps}
                                                        {...pr.dragHandleProps}
                                                    >
                                                        <QuoteTemplateItem
                                                            item={item}
                                                            getName={(item) =>
                                                                `${item.financing_option_type?.name} - APR ${item.financing_option_type?.apr}% - ${item.financing_option_type?.term} months`
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {/* </DragDropContext> */}
                    </Stack>
                </DragDropContext>
                <Typography variant="h4" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Packages
                </Typography>
                <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                    <Autocomplete
                        id="packages-dropdown"
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => onUpdatePackageTypes(value)}
                        value={quoteTemplate.package_types}
                        options={packageTypesOptions}
                        multiple
                        renderInput={(params) => <TextField {...params} label="Packages" />}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                </Stack>
                <Typography variant="h4" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Terms and Conditions
                </Typography>
                <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                    <Autocomplete
                        id="terms-and-conditions-dropdown"
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => onUpdateTermsAndConditions(value)}
                        value={quoteTemplate.terms_and_conditions}
                        options={containers.filter((item) => item.type === "terms_and_conditions")}
                        getOptionKey={(option) => option.id}
                        renderInput={(params) => (
                            <TextField {...params} label="Terms and Conditions" />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                </Stack>
                <Typography variant="h4" component="h2" sx={{ mt: 4, mb: 2 }}>
                    JHA
                </Typography>
                <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                    {/* <Autocomplete
                        id="jha-items-dropdown"
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => onUpdateJHAItems(value)}
                        value={quoteTemplate.jha_items}
                        options={containers.filter((item) => item.type === "jha_item")}
                        getOptionKey={(option) => option.id}
                        multiple
                        renderInput={(params) => <TextField {...params} label="JHA Items" />}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    /> */}
                    <FormControlLabel
                        label="Disable JHA"
                        control={
                            <Checkbox
                                checked={quoteTemplate.data.print_template?.disable_jha || false}
                                onChange={(event) => onUpdateDisableJHA(event.target.checked)}
                            />
                        }
                    />
                </Stack>
                {hasPermission(Permissions.QUOTE_TEMPLATE_RELATIONSHIP_VIEW) && (
                    <>
                        <Typography variant="h4" component="h2" sx={{ mt: 4, mb: 2 }}>
                            Related Quote Templates
                        </Typography>
                        <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                            <Typography variant="body1">
                                You will be able to create quotes based on the templates selected
                                here.
                            </Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Template</TableCell>
                                            <TableCell>Message</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {templateRelationships.map((relationship, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <NavLink
                                                        to={PATHS.quotes.quoteTemplates.details(
                                                            relationship.child.id
                                                        )}
                                                    >
                                                        {relationship.child.name}
                                                    </NavLink>
                                                </TableCell>
                                                <TableCell>{relationship.data.message}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        onClick={() =>
                                                            dispatch(
                                                                deleteQuoteTemplateRelationship(
                                                                    relationship
                                                                )
                                                            )
                                                        }
                                                        color="error"
                                                        size="small"
                                                        disabled={
                                                            !hasPermission(
                                                                Permissions.QUOTE_TEMPLATE_RELATIONSHIP_DELETE
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack
                                direction={{ xs: "column", md: "row" }}
                                spacing={1}
                                alignItems="center"
                            >
                                <Autocomplete
                                    id="child-template-dropdown"
                                    getOptionLabel={(option) => option.name}
                                    onChange={(
                                        event: React.SyntheticEvent,
                                        value: TQuoteTemplate | null
                                    ) => setSelectedChildTemplate(value)}
                                    value={selectedChildTemplate}
                                    disabled={
                                        isSending ||
                                        !hasPermission(
                                            Permissions.QUOTE_TEMPLATE_RELATIONSHIP_CREATE
                                        )
                                    }
                                    options={allTemplates.filter(
                                        (item) =>
                                            item.id !== idAsNumber &&
                                            !templateRelationships
                                                .map((item) => item.child.id)
                                                .includes(item.id)
                                    )}
                                    sx={{ width: "100%" }}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            // size="small"
                                            label="Select a Template"
                                        />
                                    )}
                                />
                                <TextField
                                    label="Message"
                                    variant="outlined"
                                    // size="small"
                                    sx={{ width: "100%" }}
                                    value={selectedChildTemplateMessage}
                                    onChange={(e) =>
                                        setSelectedChildTemplateMessage(e.target.value)
                                    }
                                    disabled={
                                        isSending ||
                                        !hasPermission(
                                            Permissions.QUOTE_TEMPLATE_RELATIONSHIP_CREATE
                                        )
                                    }
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                        if (!selectedChildTemplate) return
                                        if (!selectedChildTemplateMessage) return
                                        dispatch(
                                            createQuoteTemplateRelationship({
                                                parent_id: idAsNumber,
                                                child_id: selectedChildTemplate?.id || 0,
                                                data: { message: selectedChildTemplateMessage },
                                            })
                                        )
                                        setSelectedChildTemplate(null)
                                        setSelectedChildTemplateMessage("")
                                    }}
                                    disabled={
                                        isSending ||
                                        !selectedChildTemplate ||
                                        !selectedChildTemplateMessage ||
                                        !hasPermission(
                                            Permissions.QUOTE_TEMPLATE_RELATIONSHIP_CREATE
                                        )
                                    }
                                >
                                    Add
                                </Button>
                            </Stack>
                        </Stack>
                    </>
                )}
            </Container>
        </>
    )
}
