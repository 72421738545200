import { Button } from "@mui/material"
import React from "react"

interface ExportCSVProps {
    data: (string | number)[][]
    filename: string
    buttonText?: string
}

const escapeCSVValue = (value: string | number): string => {
    const stringValue = value.toString()
    if (stringValue.includes(",") || stringValue.includes('"') || stringValue.includes("\n")) {
        return `"${stringValue.replace(/"/g, '""')}"`
    }
    return stringValue
}

const ExportCSVButton: React.FC<ExportCSVProps> = ({
    data,
    filename,
    buttonText = "Export to CSV",
}) => {
    const arrayToCSV = (d: (string | number)[][]): string =>
        d.map((row) => row.map(escapeCSVValue).join(",")).join("\n")

    const downloadCSV = (): void => {
        const csvData = arrayToCSV(data)
        const blob = new Blob([csvData], { type: "text/csv" })
        const url = URL.createObjectURL(blob)

        const a = document.createElement("a")
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
    }

    return <Button onClick={downloadCSV}>{buttonText}</Button>
}

export default ExportCSVButton
