import { useNavigate } from "react-router"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, Stack } from "@mui/material"
import { RHFTextField } from "src/components/hook-form"
import { LoadingButton } from "@mui/lab"
import { PATHS } from "src/routes/paths"

type FormData = {
    id: number | null
    name: string
}

type Props = {
    initialData?: FormData
    onSubmit: (data: FormData) => void
}

export default function GroupEditForm({ initialData, onSubmit }: Props) {
    const navigate = useNavigate()

    const defaultValues = {
        id: null,
        name: "",
    }

    const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
    })

    const methods = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues,
    })

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData)
        }
    }, [initialData, methods])

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <RHFTextField name="name" label="Name" required autoFocus fullWidth />
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.auth.groups.list())}
                >
                    Cancel
                </Button>
                <LoadingButton
                    size="small"
                    variant="contained"
                    loading={false}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </LoadingButton>
            </Stack>
        </FormProvider>
    )
}
