import { TFeeCreate, TFeeUpdate } from "src/@types/fee"
import axios from "../utils/axios"

const apiCreateFeeFromType = (payload: TFeeCreate) => axios.post(`/api/v1/quotes/fees/`, payload)

const apiDeleteFee = (id: number) => axios.delete(`/api/v1/quotes/fees/${id}/`)

const apiUpdateFee = (id: number, payload: TFeeUpdate) =>
    axios.patch(`/api/v1/quotes/fees/${id}/`, payload)

export { apiCreateFeeFromType, apiDeleteFee, apiUpdateFee }
