import { TGroupCreate, TGroupUpdate } from "src/@types/security"
import axios from "../utils/axios"

const apiFetchGroups = () => axios.get("/api/v1/auth/groups/")

const apiFetchGroup = (id: number) => axios.get(`/api/v1/auth/groups/${id}/`)

const apiCreateGroup = (data: TGroupCreate) => axios.post("/api/v1/auth/groups/", data)

const apiUpdateGroup = (id: number, data: TGroupUpdate) =>
    axios.patch(`/api/v1/auth/groups/${id}/`, data)

const apiDeleteGroup = (id: number) => axios.delete(`/api/v1/auth/groups/${id}/`)

export { apiFetchGroups, apiFetchGroup, apiCreateGroup, apiUpdateGroup, apiDeleteGroup }
