import { useEffect, useState } from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material"

type Props = {
    state: string
    onChange: (value: string) => void
    formatter?: (value: string) => string
    name: string
    textStyles?: React.CSSProperties
    colSpan?: number
}

export default function EditTextCell({
    state,
    onChange,
    name,
    colSpan,
    textStyles = {},
    formatter = (str) => str,
}: Props) {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState<string>(state)

    useEffect(() => {
        setValue(state)
    }, [state])

    const handleOpenDialog = (event: any) => {
        setOpen(true)
        setValue(state)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }

    return (
        <>
            <td
                style={
                    {
                        // padding: "0px",
                        // position: "relative",
                    }
                }
                className="text-small"
                colSpan={colSpan}
            >
                <div
                    role="button"
                    tabIndex={0}
                    className="text-small"
                    onClick={handleOpenDialog}
                    onKeyDown={() => {}} // For accessibility
                    style={{
                        // cursor: "pointer",
                        // position: "absolute",
                        // top: 0,
                        // left: 0,
                        // bottom: 0,
                        // right: 0,
                        // // minHeight: "40px",
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent: "center",
                        width: "100%",
                        minHeight: state ? "0px" : "20px",
                        outline: "none",
                        ...textStyles,
                    }}
                >
                    {formatter(state)}
                </div>
            </td>
            <Dialog open={open} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
                <DialogTitle>Edit</DialogTitle>
                <DialogContent>
                    <DialogContentText>{name}</DialogContentText>
                    <TextField
                        autoFocus
                        multiline
                        maxRows={12}
                        sx={{ mt: 1 }}
                        fullWidth
                        value={value}
                        type="text"
                        onChange={(e) => setValue(e.target.value)}
                        // inputProps={{

                        //     min: 0,
                        //     step: 5,
                        //     style: { textAlign: "center" },
                        // }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="error">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onChange(value)
                            handleCloseDialog()
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
