import { createSlice, Dispatch } from "@reduxjs/toolkit"
import {
    TQuoteTemplateCreate,
    TQuoteTemplateUpdate,
    TQuoteTemplateState,
    TQuoteTemplateItem,
    TQuoteTemplateItemCreate,
    TQuoteTemplate,
    TQuoteTemplateRelationship,
    TQuoteTemplateRelationshipCreate,
} from "src/@types/quote-template"
import {
    apiCreateQuoteTemplate,
    apiDeleteQuoteTemplate,
    apiFetchQuoteTemplate,
    apiFetchQuoteTemplates,
    apiUpdateQuoteTemplate,
    apiCreateQuoteTemplateItem,
    apiDeleteQuoteTemplateItem,
    apiReorderQuoteTemplateItems,
} from "src/api/quote-templates"
import {
    apiCreateQuoteTemplateSection,
    apiUpdateQuoteTemplateSection,
    apiDeleteQuoteTemplateSection,
} from "src/api/quote-template-sections"
import {
    TQuoteTemplateSection,
    TQuoteTemplateSectionCreate,
    TQuoteTemplateSectionUpdate,
} from "src/@types/quote-template-section"
import {
    apiCreateQuoteTemplateRelationship,
    apiDeleteQuoteTemplateRelationship,
    apiFetchQuoteTemplateRelationship,
    apiFetchQuoteTemplateRelationships,
    apiUpdateQuoteTemplateRelationship,
} from "src/api/quote-template-relationships"

const initialState: TQuoteTemplateState = {
    quoteTemplates: [],
    itemsByQuoteTemplateId: {},
    childrenByQuoteTemplateId: {},
    sectionsByQuoteTemplateId: {},
    isLoading: false,
}

const processQuoteTemplate = (state: TQuoteTemplateState, template: TQuoteTemplate) => {
    state.itemsByQuoteTemplateId[template.id] = template.items
    state.itemsByQuoteTemplateId[template.id].sort((a, b) => a.order - b.order)
    state.sectionsByQuoteTemplateId[template.id] = template.sections
    state.sectionsByQuoteTemplateId[template.id].sort((a, b) => a.order - b.order)
    state.childrenByQuoteTemplateId[template.id] = template.children
}

const slice = createSlice({
    name: "quoteTemplates",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        fetchQuoteTemplatesSuccess: (state, action) => {
            state.isLoading = false
            state.quoteTemplates = action.payload
            state.quoteTemplates.forEach((template) => processQuoteTemplate(state, template))
        },
        fetchQuoteTemplateSuccess: (state, action) => {
            state.isLoading = false
            const template = action.payload
            const index = state.quoteTemplates.findIndex((item) => item.id === template.id)
            if (index !== -1) {
                state.quoteTemplates[index] = template
            } else {
                state.quoteTemplates.push(template)
            }
            processQuoteTemplate(state, template)
        },
        createQuoteTemplateSuccess: (state, action) => {
            state.isLoading = false
            state.quoteTemplates.push(action.payload)
        },
        deleteQuoteTemplateSuccess: (state, action) => {
            state.isLoading = false
            state.quoteTemplates = state.quoteTemplates.filter((item) => item.id !== action.payload)
        },
        fetchQuoteTemplateRelationshipsSuccess: (state, action) => {
            state.isLoading = false
            action.payload.forEach((relationship: TQuoteTemplateRelationship) => {
                const { parent } = relationship
                if (!state.childrenByQuoteTemplateId[parent.id]) {
                    state.childrenByQuoteTemplateId[parent.id] = []
                }
                state.childrenByQuoteTemplateId[parent.id].push(relationship)
            })
        },
        fetchQuoteTemplateRelationshipSuccess: (state, action) => {
            state.isLoading = false
            const relationship = action.payload
            const { parent } = relationship
            if (!state.childrenByQuoteTemplateId[parent.id]) {
                state.childrenByQuoteTemplateId[parent.id] = []
            }
            state.childrenByQuoteTemplateId[parent.id].push(relationship)
        },
        createQuoteTemplateRelationshipSuccess: (state, action) => {
            state.isLoading = false
            const relationship: TQuoteTemplateRelationship = action.payload
            const { parent } = relationship
            if (!state.childrenByQuoteTemplateId[parent.id]) {
                state.childrenByQuoteTemplateId[parent.id] = []
            }
            state.childrenByQuoteTemplateId[parent.id].push(relationship)
        },
        deleteQuoteTemplateRelationshipSuccess: (state, action) => {
            state.isLoading = false
            const relationship: TQuoteTemplateRelationship = action.payload
            state.childrenByQuoteTemplateId[relationship.parent.id] =
                state.childrenByQuoteTemplateId[relationship.parent.id].filter(
                    (item) => item.id !== relationship.id
                )
        },
        createItemSuccess: (state, action) => {
            state.isLoading = false
            const item: TQuoteTemplateItem = action.payload
            const { template_id } = item
            if (template_id) {
                if (!state.itemsByQuoteTemplateId[template_id]) {
                    state.itemsByQuoteTemplateId[template_id] = []
                }
                state.itemsByQuoteTemplateId[template_id].push(item)
                state.itemsByQuoteTemplateId[template_id].sort((a, b) => a.order - b.order)
            }
        },
        createQuoteTemplateSectionSuccess(state, action) {
            state.isLoading = false
            const section = action.payload
            const { template_id } = section
            if (template_id) {
                if (!state.sectionsByQuoteTemplateId[template_id]) {
                    state.sectionsByQuoteTemplateId[template_id] = []
                }
                state.sectionsByQuoteTemplateId[template_id].push(section)
                state.sectionsByQuoteTemplateId[template_id].sort((a, b) => a.order - b.order)
            }
        },
        updateQuoteTemplateSectionSuccess(state, action) {
            state.isLoading = false
            const section = action.payload
            const { template_id } = section
            if (template_id) {
                const sections = state.sectionsByQuoteTemplateId[template_id]
                if (sections) {
                    const index = sections.findIndex((item) => item.id === section.id)
                    if (index !== -1) {
                        sections[index] = section
                    } else {
                        sections.push(section)
                    }
                } else {
                    state.sectionsByQuoteTemplateId[template_id] = [section]
                }
            }
            state.sectionsByQuoteTemplateId[template_id].sort((a, b) => a.order - b.order)
        },
        deleteQuoteTemplateSectionSuccess(state, action) {
            state.isLoading = false
            const section = action.payload
            const { template_id } = section
            if (template_id) {
                state.sectionsByQuoteTemplateId[template_id] = state.sectionsByQuoteTemplateId[
                    template_id
                ].filter((item) => item.id !== section.id)
            }
        },
        deleteLineItemSuccess(state, action) {
            state.isLoading = false
            const item: TQuoteTemplateItem = action.payload
            const { template_id } = item
            if (template_id && state.itemsByQuoteTemplateId[template_id] !== undefined) {
                state.itemsByQuoteTemplateId[template_id] = state.itemsByQuoteTemplateId[
                    template_id
                ].filter((i) => i.id !== item.id)
            }
        },
        reorderItemsSuccess(state, action) {
            state.isLoading = false
            const template: TQuoteTemplate = action.payload
            if (template) {
                console.log("reorderItemsSuccess", { action })
                state.itemsByQuoteTemplateId[template.id] = template.items
                state.itemsByQuoteTemplateId[template.id].sort((a, b) => a.order - b.order)
            }
        },
    },
})

export function getQuoteTemplates() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchQuoteTemplates()
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteTemplatesSuccess(response.data.results))
        }
        return response
    }
}

export function getQuoteTemplate(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchQuoteTemplate(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteTemplateSuccess(response.data))
        }
        return response
    }
}

export function createQuoteTemplate(data: TQuoteTemplateCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateQuoteTemplate(data)
        if (response.status === 201) {
            dispatch(slice.actions.createQuoteTemplateSuccess(response.data))
        }
        return response
    }
}

export function updateQuoteTemplate(id: number, data: TQuoteTemplateUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateQuoteTemplate(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteTemplateSuccess(response.data))
        }
        return response
    }
}

export function deleteQuoteTemplate(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteQuoteTemplate(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteQuoteTemplateSuccess(id))
        }
        return response
    }
}

export function getQuoteTemplateRelationships() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchQuoteTemplateRelationships()
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteTemplateRelationshipsSuccess(response.data.results))
        }
        return response
    }
}

export function getQuoteTemplateRelationship(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchQuoteTemplateRelationship(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteTemplateRelationshipSuccess(response.data))
        }
        return response
    }
}

export function createQuoteTemplateRelationship(data: TQuoteTemplateRelationshipCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateQuoteTemplateRelationship(data)
        if (response.status === 201) {
            dispatch(slice.actions.createQuoteTemplateRelationshipSuccess(response.data))
        }
        return response
    }
}

export function updateQuoteTemplateRelationship(id: number, data: { data: object }) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateQuoteTemplateRelationship(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteTemplateRelationshipSuccess(response.data))
        }
        return response
    }
}

export function deleteQuoteTemplateRelationship(relationship: TQuoteTemplateRelationship) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteQuoteTemplateRelationship(relationship.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteQuoteTemplateRelationshipSuccess(relationship))
        }
        return response
    }
}

export function createQuoteTemplateItem(data: TQuoteTemplateItemCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateQuoteTemplateItem(data)
        if (response.status === 201) {
            dispatch(slice.actions.createItemSuccess(response.data))
        }
        return response
    }
}

export function deleteQuoteTemplateItem(item: TQuoteTemplateItem) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteQuoteTemplateItem(item.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteLineItemSuccess(item))
        }
        return response
    }
}

export function reorderQuoteTemplateItems(
    id: number,
    order: { [key: number]: { order: number; section_id: number | null } }
) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiReorderQuoteTemplateItems(id, order)
        if (response.status === 200) {
            dispatch(slice.actions.reorderItemsSuccess(response.data))
        }
        return response
    }
}

export function createQuoteTemplateSection(data: TQuoteTemplateSectionCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateQuoteTemplateSection(data)
        if (response.status === 201) {
            dispatch(slice.actions.createQuoteTemplateSectionSuccess(response.data))
        }
        return response
    }
}

export function updateQuoteTemplateSection(id: number, changes: TQuoteTemplateSectionUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateQuoteTemplateSection(id, changes)
        if (response.status === 200) {
            dispatch(slice.actions.updateQuoteTemplateSectionSuccess(response.data))
        }
        return response
    }
}

export function deleteQuoteTemplateSection(section: TQuoteTemplateSection) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteQuoteTemplateSection(section.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteQuoteTemplateSectionSuccess(section))
        }
        return response
    }
}

export default slice.reducer
