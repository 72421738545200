import { Button, Chip, Paper, Stack } from "@mui/material"
import { useState } from "react"
import { CustomField, CustomFieldValue } from "src/@types/custom-field"

interface CustomFieldProps {
    handleSave: (values: CustomFieldValue[]) => void
    field: CustomField
}

const getOptionColors = (option: CustomFieldValue) => {
    const colors: {
        color?: string
        backgroundColor?: string
        ":hover": {
            color?: string
            backgroundColor?: string
        }
    } = { ":hover": {} }
    if (option.color) {
        colors.backgroundColor = option.color
        colors[":hover"].backgroundColor = option.color
    }
    if (option.fontColor) {
        colors.color = option.fontColor
        colors[":hover"].color = option.fontColor
    }
    return colors
}

const MultiSelectChipsCustomField = ({ handleSave, field }: CustomFieldProps) => {
    const [isSending, setIsSending] = useState<boolean>(false)

    const values = field.values || []

    const handleAddItem = async (item: CustomFieldValue) => {
        setIsSending(true)
        await handleSave([...values, item])
        setIsSending(false)
    }

    const handleRemoveItem = async (index: number) => {
        setIsSending(true)
        await handleSave(values.filter((v, i: number) => i !== index))
        setIsSending(false)
    }

    return (
        <Stack direction="column">
            {field.header}
            <Paper>
                {values.map((value, index: number) => (
                    <Chip
                        disabled={isSending}
                        key={index}
                        label={value.label}
                        onDelete={() => handleRemoveItem(index)}
                        sx={{
                            mb: 1,
                            mr: 1,
                            ...getOptionColors(value),
                        }}
                    />
                ))}
            </Paper>
            <Stack direction="row" gap={1} alignItems="center" mt={1}>
                {field.maxOptions !== undefined ? `Add (max ${field.maxOptions}):` : "Add:"}
                {field.type === "multiselect" &&
                    field.visible &&
                    field.options.map((option, index) => (
                        <Button
                            disabled={
                                isSending ||
                                (field.maxOptions !== undefined &&
                                    field.maxOptions <= values.length)
                            }
                            variant="contained"
                            size="small"
                            key={index}
                            onClick={() => handleAddItem(option)}
                            sx={getOptionColors(option)}
                        >
                            {option.label}
                        </Button>
                    ))}
            </Stack>
        </Stack>
    )
}

export default MultiSelectChipsCustomField
