import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material"
import { useEffect } from "react"
import { TLineItem } from "src/@types/lineItem"
import FormProvider from "src/components/hook-form/FormProvider"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { RHFTextField } from "src/components/hook-form"
import { useDispatch } from "src/redux/store"
import { updateLineItem } from "src/redux/slices/quotes"
import { useSnackbar } from "notistack"

type Props = {
    lineItem: TLineItem | null
    onClose: () => void
}

export default function EditLineItemDialog({ lineItem, onClose }: Props) {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const open = Boolean(lineItem)

    const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
    })

    const methods = useForm<TLineItem>({
        resolver: yupResolver(schema),
        defaultValues: lineItem || {},
    })

    useEffect(() => {
        if (lineItem) {
            methods.reset(lineItem)
        }
    }, [lineItem, methods])

    if (!lineItem) return null

    const handleSubmit = async (data: TLineItem) => {
        const changes = {
            display_name: data.display_name,
            description: data.description,
            location: data.location,
        }
        const response = await dispatch(updateLineItem(lineItem.id, changes))
        if (response.status === 200) {
            enqueueSnackbar("Line Item updated", { variant: "success" })
            onClose()
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Edit Service</DialogTitle>
            <DialogContent>
                <FormProvider methods={methods}>
                    <Stack direction="column" spacing={2} flex={1}>
                        <RHFTextField name="display_name" label="Name" required sx={{ mt: 1 }} />
                        <RHFTextField name="description" label="Description" multiline rows={3} />
                        <RHFTextField name="location" label="Location" />
                    </Stack>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">
                    Cancel
                </Button>
                <Button onClick={methods.handleSubmit(handleSubmit)}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
