import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import { NavLink } from "react-router-dom"
// import { TContainer } from "src/@types/containers"
import {
    TLineItemType,
    TLineItemTypeUpdate,
    TLineItemTypesRelationship,
} from "src/@types/lineItemType"
import { TMaterialType } from "src/@types/material-type"
import { TMeasurementType } from "src/@types/measurementType"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import CategorySelectTree from "src/forms/category-select"
import LineItemTypeEditForm from "src/forms/line-item-type-edit"
import { getCategories } from "src/redux/slices/categories"
import { getContainers } from "src/redux/slices/containers"
import {
    createLineItemTypesRelationship,
    deleteLineItemTypesRelationship,
    getLineItemType,
    getLineItemTypes,
    updateLineItemType,
} from "src/redux/slices/lineItemTypes"
import { getMaterialTypes } from "src/redux/slices/material-types"
import { getMeasurementTypes } from "src/redux/slices/measurementTypes"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"

export default function LineItemTypeDetailsPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const [newSubService, setNewSubService] = useState<TLineItemType | null>(null)

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    // const containers = useSelector((state) =>
    //     state.containers.containers.filter((container) => ["jha_item"].includes(container.type))
    // )

    useEffect(() => {
        dispatch(getMeasurementTypes())
        dispatch(getMaterialTypes())
        dispatch(getCategories())
        dispatch(getContainers({ types: ["jha_item"] }))
    }, [dispatch])

    useEffect(() => {
        const getLineItemTypeAsync = async () => {
            if (idAsNumber) {
                await dispatch(getLineItemType(idAsNumber))

                setIsLoading(false)
            }
        }
        getLineItemTypeAsync()
        dispatch(getLineItemTypes())
    }, [dispatch, idAsNumber])

    const lineItemTypes = useSelector((state) => state.lineItemTypes.lineItemTypes)

    const lineItemType = lineItemTypes.find((item) => item.id === idAsNumber)
    const [minTotal, setMinTotal] = useState(lineItemType?.data.min_total || 0)
    const [materialsTitle, setMaterialsTitle] = useState(lineItemType?.data.materials_title || "")

    useEffect(() => {
        setMinTotal(lineItemType?.data.min_total || 0)
    }, [lineItemType?.data.min_total])

    const measurementTypes = useSelector((state) => state.measurementTypes.measurementTypes)
    const materials = useSelector((state) => state.materialTypes.materialTypes)
    // const categories = useSelector((state) => state.categories.categories.byId)

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (lineItemType === undefined) {
        return <div>Not found</div>
    }

    const materialPossibleIds = lineItemType.materials_possible.map((item) => item.id)

    const onSubmit = async (data: TLineItemTypeUpdate) => {
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Service updated successfully", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDefaultMeasurementsChange = async (event: any, value: TMeasurementType[]) => {
        const changes = {
            measurements_default_ids: value.map((item) => item.id),
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Default measurements updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlePossibleMeasurementsChange = async (event: any, value: TMeasurementType[]) => {
        const changes = {
            measurements_possible_ids: value.map((item) => item.id),
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Possible measurements updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlePossibleMaterialsChange = async (event: any, value: TMaterialType[]) => {
        const changes = {
            materials_possible_ids: value.map((item) => item.id),
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Possible materials updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDefaultMaterialOp1Change = async (event: any, value: TMaterialType | null) => {
        const changes = {
            material_default_op_1_id: value?.id || null,
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Default material type 1 updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDefaultMaterialOp2Change = async (event: any, value: TMaterialType | null) => {
        const changes = {
            material_default_op_2_id: value?.id || null,
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Default material type 2 updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDefaultMaterialOp3Change = async (event: any, value: TMaterialType | null) => {
        const changes = {
            material_default_op_3_id: value?.id || null,
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Default material type 3 updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlePossibleMaterialsCategoryChange = async (value: number | null) => {
        const changes = {
            materials_possible_category_ids: value ? [value] : [],
        }
        try {
            console.log(changes)
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Possible materials category updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeDoNotAllowChangeMaterials = async (event: any) => {
        const changes = {
            data: {
                ...lineItemType.data,
                do_not_allow_change_materials: event.target.checked,
            },
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            // console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Changes saved", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeCollapseMaterialsOptions = async (event: any) => {
        const changes = {
            data: {
                ...lineItemType.data,
                collapse_empty_material_options: event.target.checked,
            },
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            // console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Changes saved", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeAllowManuallyBelowMinTotal = async (event: any) => {
        const changes = {
            data: {
                ...lineItemType.data,
                allow_manually_below_min_total: event.target.checked,
            },
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            // console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Changes saved", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeAllowFlatFee = async (event: any) => {
        const changes = {
            data: {
                ...lineItemType.data,
                allow_flat_fee: event.target.checked,
            },
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            // console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Changes saved", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeMaterialsTitle = async (event: any) => {
        const changes = {
            data: {
                ...lineItemType.data,
                materials_title: event.target.value || "Materials",
            },
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            // console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Changes saved", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeMinTotal = async (event: any) => {
        const changes = {
            data: {
                ...lineItemType.data,
                min_total: Number(event.target.value) || 0,
            },
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            // console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Changes saved", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleNumberOfDisplayMaterialOptions = async (event: any) => {
        const changes = {
            data: {
                ...lineItemType.data,
                number_of_display_material_options: parseInt(event.target.value, 10),
            },
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            if (response.status === 200) {
                enqueueSnackbar("Changes saved", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // const onUpdateJHAItems = async (jhaItems: TContainer[]) => {
    //     const response = await dispatch(
    //         updateLineItemType(idAsNumber, {
    //             jha_items_ids: jhaItems.map((item) => item.id),
    //         })
    //     )
    //     if (response.status === 200) {
    //         enqueueSnackbar("JHA Items updated", { variant: "success" })
    //     }
    // }

    const handleAddSubService = async (event: any, value: TLineItemType | null) => {
        setNewSubService(value)
        if (value) {
            const response = await dispatch(
                createLineItemTypesRelationship({
                    parent_id: lineItemType.id,
                    child_id: value.id,
                })
            )
            if (response.status === 201) {
                enqueueSnackbar("Sub-Service Added", { variant: "success" })
            }
        }
        setNewSubService(null)
    }

    const handleDetachSubService = async (relationship: TLineItemTypesRelationship) => {
        try {
            const response = await dispatch(deleteLineItemTypesRelationship(relationship))
            if (response.status === 204) {
                enqueueSnackbar("Sub-Service Detached", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Update Service</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Update Service"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Services",
                            href: PATHS.quotes.lineItemTypes.list(),
                        },
                        {
                            name: "Update",
                        },
                    ]}
                />
                <LineItemTypeEditForm
                    onSubmit={onSubmit}
                    initialData={lineItemType}
                    measurementTypes={lineItemType.measurements_possible}
                />
                <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Sub-Services
                </Typography>
                <Card>
                    <CardContent>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={2} width="100%">
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {lineItemType.children.map((relationship) => (
                                                <TableRow key={relationship.child.id}>
                                                    <TableCell>
                                                        <NavLink
                                                            to={PATHS.quotes.lineItemTypes.details(
                                                                relationship.child.id
                                                            )}
                                                        >
                                                            {relationship.child.name}
                                                        </NavLink>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            onClick={() =>
                                                                handleDetachSubService(relationship)
                                                            }
                                                            color="error"
                                                            size="small"
                                                        >
                                                            Detach
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Autocomplete
                                    id="sub-services"
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={handleAddSubService}
                                    size="small"
                                    disabled={newSubService !== null}
                                    value={newSubService}
                                    options={lineItemTypes.filter(
                                        (item) =>
                                            item.id !== lineItemType.id &&
                                            !lineItemType.children
                                                .map((relationship) => relationship.child.id)
                                                .includes(item.id)
                                    )}
                                    sx={{ width: "100%", mt: 2 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Add a Sub-Service" />
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Measurements
                </Typography>
                <Card>
                    <CardContent>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={2} width="100%">
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <Autocomplete
                                    id="possible-measurements"
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    multiple
                                    onChange={handlePossibleMeasurementsChange}
                                    value={measurementTypes.filter(
                                        (item) =>
                                            lineItemType?.measurements_possible
                                                .map((mt) => mt.id)
                                                .includes(item.id)
                                    )}
                                    options={measurementTypes}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Possible Measurements" />
                                    )}
                                />
                            </Stack>
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <Autocomplete
                                    id="default-measurements"
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    multiple
                                    onChange={handleDefaultMeasurementsChange}
                                    value={measurementTypes.filter(
                                        (item) =>
                                            lineItemType?.measurements_default
                                                .map((mt) => mt.id)
                                                .includes(item.id)
                                    )}
                                    options={measurementTypes}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Default Measurements" />
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Materials
                </Typography>
                <Card>
                    <CardContent>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={2} width="100%">
                            <Stack width={{ xs: "100%", md: "50%" }} spacing={2}>
                                <TextField
                                    label="Materials Title"
                                    value={materialsTitle}
                                    onChange={(e) => setMaterialsTitle(e.target.value)}
                                    onBlur={(event) => handleChangeMaterialsTitle(event)}
                                    type="text"
                                    fullWidth
                                    helperText='Replaces default "Materials" title'
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                lineItemType.data.do_not_allow_change_materials ||
                                                false
                                            }
                                            onChange={handleChangeDoNotAllowChangeMaterials}
                                        />
                                    }
                                    label="Do not allow changing materials"
                                />
                                <FormControl>
                                    <FormLabel id="">Display # of material options:</FormLabel>
                                    <RadioGroup
                                        row
                                        name="number-of-material-options"
                                        value={
                                            lineItemType.data.number_of_display_material_options ||
                                            0
                                        }
                                        onChange={handleNumberOfDisplayMaterialOptions}
                                    >
                                        <FormControlLabel value={0} control={<Radio />} label="0" />
                                        <FormControlLabel value={1} control={<Radio />} label="1" />
                                        <FormControlLabel value={2} control={<Radio />} label="2" />
                                        <FormControlLabel value={3} control={<Radio />} label="3" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                lineItemType.data.collapse_empty_material_options ||
                                                false
                                            }
                                            onChange={handleChangeCollapseMaterialsOptions}
                                        />
                                    }
                                    label="Collapse material options if only one material is set"
                                />
                                <Autocomplete
                                    id="possible-materials"
                                    getOptionLabel={(option) => option.name}
                                    multiple
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={handlePossibleMaterialsChange}
                                    value={materials.filter((item) =>
                                        materialPossibleIds.includes(item.id)
                                    )}
                                    options={materials}
                                    // sx={{ width: "100%", marginBottom: 2 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Possible Materials" />
                                    )}
                                />
                                <Autocomplete
                                    id="default-material-op-1"
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleDefaultMaterialOp1Change}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={
                                        materials.find(
                                            (item) =>
                                                lineItemType?.material_default_op_1?.id === item.id
                                        ) || null
                                    }
                                    options={materials}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Default Material Option 1" />
                                    )}
                                />
                                <Autocomplete
                                    id="default-material-op-2"
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleDefaultMaterialOp2Change}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={
                                        materials.find(
                                            (item) =>
                                                lineItemType?.material_default_op_2?.id === item.id
                                        ) || null
                                    }
                                    options={materials}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Default Material Option 2" />
                                    )}
                                />
                                <Autocomplete
                                    id="default-material-op-3"
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleDefaultMaterialOp3Change}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={
                                        materials.find(
                                            (item) =>
                                                lineItemType?.material_default_op_3?.id === item.id
                                        ) || null
                                    }
                                    options={materials}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Default Material Option 3" />
                                    )}
                                />
                            </Stack>
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <Typography sx={{ mb: 2 }}>
                                    <b>Use possible materials from this category:</b>
                                </Typography>
                                <CategorySelectTree
                                    current={
                                        lineItemType.materials_possible_categories.length > 0
                                            ? `${lineItemType.materials_possible_categories[0]?.id}`
                                            : "none"
                                    }
                                    onChange={(value) =>
                                        handlePossibleMaterialsCategoryChange(value)
                                    }
                                    disable={[]}
                                    emptyLabel="No Category"
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Restrictions
                </Typography>
                <Card>
                    <CardContent>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={2} width="100%">
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <TextField
                                    label="Minimum Total"
                                    value={minTotal}
                                    onChange={(e) => setMinTotal(Number(e.target.value))}
                                    onBlur={(event) => handleChangeMinTotal(event)}
                                    type="number"
                                    fullWidth
                                    helperText=" Applies to all materials in this service; 0 means no restriction."
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                lineItemType.data.allow_manually_below_min_total ||
                                                false
                                            }
                                            onChange={handleChangeAllowManuallyBelowMinTotal}
                                        />
                                    }
                                    label="Allow manually adjusting the price below the minimum total"
                                />
                            </Stack>
                            <Stack width={{ xs: "100%", md: "50%" }} spacing={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={lineItemType.data.allow_flat_fee || false}
                                            onChange={handleChangeAllowFlatFee}
                                        />
                                    }
                                    label="Allow flat fee"
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                {/* <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    JHA
                </Typography> */}
                {/* <Card>
                    <CardContent> */}
                {/* <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                    <Autocomplete
                        id="jha-items-dropdown"
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => onUpdateJHAItems(value)}
                        value={lineItemType.jha_items}
                        options={containers}
                        getOptionKey={(option) => option.id}
                        multiple
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="JHA Items" />}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                </Stack> */}
                {/* </CardContent>
                </Card> */}
            </Container>
        </>
    )
}
