import { createSlice, Dispatch } from "@reduxjs/toolkit"
import { TLineItem, TLineItemCreate, TLineItemUpdate } from "src/@types/lineItem"
import { TMeasurementField, TMeasurementFieldUpdate, TMeasurement } from "src/@types/measurement"
import { QuoteCreate, QuoteUpdate, QuotesState, Quote } from "src/@types/quote"
import {
    apiCreateLineItem,
    apiDeleteLineItem,
    apiUpdateLineItem,
    apiUpdateLineItemMaterialOption,
} from "src/api/line-item"
import { apiUpdateMeasurementField } from "src/api/measurement-fields"
import { apiCreateFeeFromType, apiDeleteFee, apiUpdateFee } from "src/api/fees"
import { apiDeleteMeasurement, apiCreateMeasurement } from "src/api/measurements"
import {
    apiCreateQuote,
    apiDeleteQuote,
    apiFetchQuote,
    apiFetchQuotes,
    apiReorderQuoteFees,
    apiReorderQuoteLineItems,
    apiSetQuoteVariable,
    apiUpdateQuote,
} from "src/api/quotes"
import {
    apiCreateQuoteSection,
    apiUpdateQuoteSection,
    apiDeleteQuoteSection,
} from "src/api/quote-sections"
import {
    apiCreateFinancingOptionFromType,
    apiDeleteFinancingOption,
    apiUpdateFinancingOption,
} from "src/api/financing-options"
import { apiDeletePackageItem, apiCreatePackageItem } from "src/api/package-items"
import { QuoteSectionCreate, QuoteSectionType } from "src/@types/quote-section"
import { TFee, TFeeUpdate } from "src/@types/fee"
import { TMaterial, MaterialUpdate } from "src/@types/material"
import { apiDeleteMaterial, apiUpdateMaterial } from "src/api/material"
import { TPackageItemCreate, TPackageItem, TPackage, PackageUpdate } from "src/@types/package"
import { apiUpdatePackage } from "src/api/packages"
import { TFinancingOption, TFinancingOptionUpdate } from "src/@types/financing-option"

const initialState: QuotesState = {
    quotes: [],
    lineItemsByQuoteId: {},
    measurementsByLineItemId: {},
    measurementFieldsByMeasurementId: {},
    feesByQuoteId: {},
    financingOptionsByQuoteId: {},
    sectionsByQuoteId: {},
    materialsByLineItemId: {},
    packagesByQuoteId: {},
    packageItemsByPackageId: {},
    isLoading: false,
}

const processQuote = (state: QuotesState, quote: Quote) => {
    state.sectionsByQuoteId[quote.id] = quote.sections
    state.sectionsByQuoteId[quote.id].sort((a, b) => a.order - b.order)
    state.lineItemsByQuoteId[quote.id] = quote.line_items
    state.lineItemsByQuoteId[quote.id].sort((a, b) => {
        const aSectionOrder =
            state.sectionsByQuoteId[quote.id].find((section) => section.id === a.section_id)
                ?.order ?? 10000
        const bSectionOrder =
            state.sectionsByQuoteId[quote.id].find((section) => section.id === b.section_id)
                ?.order ?? 10000
        return aSectionOrder - bSectionOrder || a.order - b.order
    })
    state.packagesByQuoteId[quote.id] = quote.packages
    state.financingOptionsByQuoteId[quote.id] = quote.financing_options
    quote.line_items.forEach((li) => {
        state.measurementsByLineItemId[li.id] = li.measurements
        state.materialsByLineItemId[li.id] = li.materials
        li.measurements.forEach((m) => {
            state.measurementFieldsByMeasurementId[m.id] = m.m_fields
        })
    })
    quote.packages.forEach((p) => {
        state.packageItemsByPackageId[p.id] = p.items
    })
    state.feesByQuoteId[quote.id] = quote.fees
}

const processCombinedResponse = (dispatch: Dispatch, response: any) => {
    if (response.data.line_items?.length > 0) {
        response.data.line_items.forEach((li: TLineItem) => {
            dispatch(slice.actions.updateLineItemSuccess(li))
        })
    }
    if (response.data.packages?.length > 0) {
        response.data.packages.forEach((pkg: TPackage) => {
            dispatch(slice.actions.fetchPackageSuccess(pkg))
        })
    }
    if (response.data.materials?.length > 0) {
        response.data.materials.forEach((m: TMaterial) => {
            dispatch(slice.actions.updateMaterialSuccess(m))
        })
    }
    if (response.data.fees?.length > 0) {
        response.data.fees.forEach((f: TFee) => {
            dispatch(slice.actions.updateFeeSuccess(f))
        })
    }
}

const slice = createSlice({
    name: "quotes",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchQuotesSuccess(state, action) {
            state.isLoading = false
            state.quotes = action.payload
            state.quotes.forEach((q) => {
                processQuote(state, q)
            })
        },
        fetchQuoteSuccess(state, action) {
            state.isLoading = false
            const quote = action.payload
            const index = state.quotes.findIndex((item) => item.id === quote.id)
            if (index !== -1) {
                state.quotes[index] = quote
            } else {
                state.quotes.push(quote)
            }
            processQuote(state, quote)
        },
        deleteQuoteSuccess(state, action) {
            state.isLoading = false
            state.quotes = state.quotes.filter((q) => q.id !== action.payload)
        },
        createLineItemSuccess(state, action) {
            state.isLoading = false
            const lineItem: TLineItem = action.payload
            const { quote_id } = lineItem
            if (quote_id) {
                if (!state.lineItemsByQuoteId[quote_id]) {
                    state.lineItemsByQuoteId[quote_id] = []
                }
                state.lineItemsByQuoteId[quote_id].push(lineItem)
                state.lineItemsByQuoteId[quote_id].sort((a, b) => {
                    const aSectionOrder =
                        state.sectionsByQuoteId[a.quote_id][a.section_id || 0]?.order ?? 10000
                    const bSectionOrder =
                        state.sectionsByQuoteId[a.quote_id][b.section_id || 0]?.order ?? 10000
                    return aSectionOrder - bSectionOrder || a.order - b.order
                })
                state.measurementsByLineItemId[lineItem.id] = lineItem.measurements
                state.materialsByLineItemId[lineItem.id] = lineItem.materials
                lineItem.measurements.forEach((m) => {
                    state.measurementFieldsByMeasurementId[m.id] = m.m_fields
                })
            }
        },
        updateLineItemSuccess(state, action) {
            state.isLoading = false
            const lineItem: TLineItem = action.payload
            const { quote_id } = lineItem
            if (quote_id) {
                const lineItems = state.lineItemsByQuoteId[quote_id]
                if (lineItems) {
                    const index = lineItems.findIndex((item) => item.id === lineItem.id)
                    if (index !== -1) {
                        lineItems[index] = lineItem
                    } else {
                        lineItems.push(lineItem)
                    }
                    lineItems.sort((a, b) => {
                        const aSectionOrder =
                            state.sectionsByQuoteId[a.quote_id].find(
                                (section) => section.id === a.section_id
                            )?.order ?? 10000
                        const bSectionOrder =
                            state.sectionsByQuoteId[b.quote_id].find(
                                (section) => section.id === b.section_id
                            )?.order ?? 10000
                        return aSectionOrder - bSectionOrder || a.order - b.order
                    })
                }
            }
        },
        deleteLineItemSuccess(state, action) {
            state.isLoading = false
            const lineItem = action.payload
            const { quote_id } = lineItem
            if (quote_id) {
                state.lineItemsByQuoteId[quote_id] = state.lineItemsByQuoteId[quote_id].filter(
                    (item) => item.id !== lineItem.id
                )
            }
        },
        updateMeasurementFieldSuccess(state, action) {
            state.isLoading = false
            const field: TMeasurementField = action.payload
            const { measurement_id } = field
            if (measurement_id) {
                const fields = state.measurementFieldsByMeasurementId[measurement_id]
                if (fields) {
                    const index = fields.findIndex((item) => item.id === field.id)
                    if (index !== -1) {
                        fields[index] = field
                    } else {
                        fields.push(field)
                    }
                }
            }
        },
        deleteMeasurementSuccess(state, action) {
            state.isLoading = false
            const measurement = action.payload
            delete state.measurementFieldsByMeasurementId[measurement.id]
            const lineItemId = measurement.line_item_id
            if (lineItemId) {
                state.measurementsByLineItemId[lineItemId] = state.measurementsByLineItemId[
                    lineItemId
                ].filter((item) => item.id !== measurement.id)
            }
        },
        createMeasurementSuccess(state, action) {
            state.isLoading = false
            const measurement = action.payload
            const lineItemId = measurement.line_item_id
            if (lineItemId) {
                if (!state.measurementsByLineItemId[lineItemId]) {
                    state.measurementsByLineItemId[lineItemId] = []
                }
                state.measurementsByLineItemId[lineItemId].push(measurement)
                state.measurementsByLineItemId[lineItemId].sort((a, b) => a.id - b.id)
            }
        },
        createQuoteSectionSuccess(state, action) {
            state.isLoading = false
            const section = action.payload
            const { quote_id } = section
            if (quote_id) {
                if (!state.sectionsByQuoteId[quote_id]) {
                    state.sectionsByQuoteId[quote_id] = []
                }
                state.sectionsByQuoteId[quote_id].push(section)
                state.sectionsByQuoteId[quote_id].sort((a, b) => a.order - b.order)
            }
        },
        addFeeSuccess(state, action) {
            state.isLoading = false
            const fee = action.payload
            const { quote_id } = fee
            if (quote_id) {
                if (!state.feesByQuoteId[quote_id]) {
                    state.feesByQuoteId[quote_id] = []
                }
                state.feesByQuoteId[quote_id].push(fee)
            }
        },
        deleteFeeSuccess(state, action) {
            state.isLoading = false
            const fee = action.payload
            const { quote_id } = fee
            if (quote_id && state.feesByQuoteId[quote_id] !== undefined) {
                state.feesByQuoteId[quote_id] = state.feesByQuoteId[quote_id].filter(
                    (item) => item.id !== fee.id
                )
            }
        },
        addFinancingOptionSuccess(state, action) {
            state.isLoading = false
            const item = action.payload
            const { quote_id } = item
            if (quote_id) {
                if (!state.financingOptionsByQuoteId[quote_id]) {
                    state.financingOptionsByQuoteId[quote_id] = []
                }
                state.financingOptionsByQuoteId[quote_id].push(item)
            }
        },
        deleteFinancingOptionSuccess(state, action) {
            state.isLoading = false
            const item = action.payload
            const { quote_id } = item
            if (quote_id && state.financingOptionsByQuoteId[quote_id] !== undefined) {
                state.financingOptionsByQuoteId[quote_id] = state.financingOptionsByQuoteId[
                    quote_id
                ].filter((itm) => itm.id !== item.id)
            }
        },
        updateFinancingOptionSuccess(state, action) {
            state.isLoading = false
            const item: TFinancingOption = action.payload
            const { quote_id } = item
            if (quote_id) {
                const items = state.financingOptionsByQuoteId[quote_id]
                if (items) {
                    const index = items.findIndex((itm) => itm.id === item.id)
                    if (index !== -1) {
                        items[index] = item
                    } else {
                        items.push(item)
                    }
                }
                items?.sort((a, b) => a.order - b.order)
            }
        },
        updateQuoteSectionSuccess(state, action) {
            state.isLoading = false
            const section = action.payload
            const { quote_id } = section
            if (quote_id) {
                const sections = state.sectionsByQuoteId[quote_id]
                if (sections) {
                    const index = sections.findIndex((item) => item.id === section.id)
                    if (index !== -1) {
                        sections[index] = section
                    } else {
                        sections.push(section)
                    }
                } else {
                    state.sectionsByQuoteId[quote_id] = [section]
                }
            }
        },
        deleteQuoteSectionSuccess(state, action) {
            state.isLoading = false
            const section = action.payload
            const { quote_id } = section
            if (quote_id) {
                state.sectionsByQuoteId[quote_id] = state.sectionsByQuoteId[quote_id].filter(
                    (item) => item.id !== section.id
                )
            }
        },
        updateMaterialOptionSuccess(state, action) {
            state.isLoading = false
            const materialOption: TMaterial = action.payload
            const lineItemId = materialOption.line_item_id
            if (state.materialsByLineItemId[lineItemId] === undefined) {
                state.materialsByLineItemId[lineItemId] = []
            }
            const materials = state.materialsByLineItemId[lineItemId]
            const index = materials.findIndex((item) => item.id === materialOption.id)
            if (index !== -1) {
                materials[index] = materialOption
            } else {
                materials.push(materialOption)
            }
        },
        deleteMaterialOptionSuccess(state, action) {
            state.isLoading = false
            const materialOption: TMaterial = action.payload
            const lineItemId = materialOption.line_item_id
            if (state.materialsByLineItemId[lineItemId] !== undefined) {
                state.materialsByLineItemId[lineItemId] = state.materialsByLineItemId[
                    lineItemId
                ].filter((item) => item.id !== materialOption.id)
            }
        },
        addPackageItemSuccess(state, action) {
            state.isLoading = false
            const item: TPackageItem = action.payload
            const packageId = item.package_id
            const matchField = item.line_item_id ? "line_item_id" : "fee_id"
            if (state.packageItemsByPackageId[packageId] === undefined) {
                state.packageItemsByPackageId[packageId] = []
            }
            state.packageItemsByPackageId[packageId] = state.packageItemsByPackageId[
                packageId
            ].filter((i) => i[matchField] !== item[matchField])
            state.packageItemsByPackageId[packageId].push(item)
        },
        deletePackageItemSuccess(state, action) {
            state.isLoading = false
            const item: TPackageItem = action.payload
            const packageId = item.package_id
            const matchField = item.line_item_id ? "line_item_id" : "fee_id"
            if (state.packageItemsByPackageId[packageId] !== undefined) {
                state.packageItemsByPackageId[packageId] = state.packageItemsByPackageId[
                    packageId
                ].filter((i) => i[matchField] !== item[matchField])
            }
        },
        fetchPackageSuccess(state, action) {
            state.isLoading = false
            const pkg = action.payload
            const index = state.packagesByQuoteId[pkg.quote_id].findIndex(
                (item) => item.id === pkg.id
            )
            if (index !== -1) {
                state.packagesByQuoteId[pkg.quote_id][index] = pkg
            } else {
                state.packagesByQuoteId[pkg.quote_id].push(pkg)
            }
            state.packagesByQuoteId[pkg.quote_id].sort((a, b) => a.id - b.id)
            state.packageItemsByPackageId[pkg.id] = pkg.items
        },
        updateMaterialSuccess(state, action) {
            state.isLoading = false
            console.log("updateMaterialSuccess", action.payload)
            const material: TMaterial = action.payload
            const lineItemId = material.line_item_id
            if (lineItemId) {
                const materials = state.materialsByLineItemId[lineItemId]
                if (materials) {
                    const index = materials.findIndex((item) => item.id === material.id)
                    if (index !== -1) {
                        materials[index] = material
                    } else {
                        materials.push(material)
                    }
                }
            }
        },
        updateFeeSuccess(state, action) {
            state.isLoading = false
            const fee: TFee = action.payload
            const quoteId = fee.quote_id
            if (quoteId) {
                const fees = state.feesByQuoteId[quoteId]
                if (fees === undefined) {
                    state.feesByQuoteId[quoteId] = []
                }
                const index = fees.findIndex((item) => item.id === fee.id)
                if (index !== -1) {
                    fees[index] = fee
                } else {
                    fees.push(fee)
                }
            }
        },
        updatePackageSuccess(state, action) {
            state.isLoading = false
            const pkg: TPackage = action.payload
            const quoteId = pkg.quote_id
            if (quoteId) {
                const packages = state.packagesByQuoteId[quoteId]
                if (packages === undefined) {
                    state.packagesByQuoteId[quoteId] = []
                }
                const index = packages.findIndex((item) => item.id === pkg.id)
                if (index !== -1) {
                    packages[index] = pkg
                } else {
                    packages.push(pkg)
                }
            }
        },
        reorderLineItemsSuccess(state, action) {
            state.isLoading = false
            const quote: Quote = action.payload
            if (quote) {
                state.lineItemsByQuoteId[quote.id] = quote.line_items
                state.lineItemsByQuoteId[quote.id].sort((a, b) => {
                    const aSectionOrder =
                        state.sectionsByQuoteId[quote.id][a.section_id || 0]?.order ?? 10000
                    const bSectionOrder =
                        state.sectionsByQuoteId[quote.id][b.section_id || 0]?.order ?? 10000
                    return aSectionOrder - bSectionOrder || a.order - b.order
                })
            }
        },
        reorderFeesSuccess(state, action) {
            state.isLoading = false
            const quote: Quote = action.payload
            if (quote) {
                state.feesByQuoteId[quote.id] = quote.fees
                state.feesByQuoteId[quote.id].sort((a, b) => a.order - b.order)
            }
        },
    },
})

export function getQuotes() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchQuotes()
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuotesSuccess(response.data.results))
        }
        return response
    }
}

export function getQuote(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchQuote(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteSuccess(response.data))
        }
        return response
    }
}

export function createQuote(data: QuoteCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateQuote(data)
        if (response.status === 201) {
            dispatch(slice.actions.fetchQuoteSuccess(response.data))
        }
        return response
    }
}

export function updateQuote(id: number, data: QuoteUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateQuote(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteSuccess(response.data))
        }
        return response
    }
}

export function deleteQuote(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteQuote(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteQuoteSuccess(id))
        }
        return response
    }
}

export function createLineItem(data: TLineItemCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateLineItem(data)
        if (response.status === 201) {
            dispatch(slice.actions.createLineItemSuccess(response.data.line_item))
            processCombinedResponse(dispatch, response)
        }
        return response
    }
}

export function deleteLineItem(lineItem: TLineItem) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteLineItem(lineItem.id)
        if (response.status === 200) {
            dispatch(slice.actions.deleteLineItemSuccess(lineItem))
        }
        return response
    }
}

export function updateMeasurementField(id: number, changes: TMeasurementFieldUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateMeasurementField(id, changes)
        if (response.status === 200) {
            dispatch(slice.actions.updateMeasurementFieldSuccess(response.data.field))
            processCombinedResponse(dispatch, response)
        }
        return response
    }
}

export function deleteMeasurement(measurement: TMeasurement) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteMeasurement(measurement.id)
        if (response.status === 200) {
            dispatch(slice.actions.deleteMeasurementSuccess(measurement))
            processCombinedResponse(dispatch, response)
        }
        return response
    }
}

export function createMeasurement(lineItemId: number, measurementTypeId: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateMeasurement(lineItemId, measurementTypeId)
        if (response.status === 201) {
            dispatch(slice.actions.createMeasurementSuccess(response.data.measurement))
            processCombinedResponse(dispatch, response)
        }
        return response
    }
}

export function updateLineItem(id: number, changes: TLineItemUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateLineItem(id, changes)
        if (response.status === 200) {
            dispatch(slice.actions.updateLineItemSuccess(response.data.line_item))
            processCombinedResponse(dispatch, response)
        }
        return response
    }
}

export function createQuoteSection(data: QuoteSectionCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateQuoteSection(data)
        if (response.status === 201) {
            dispatch(slice.actions.createQuoteSectionSuccess(response.data))
        }
        return response
    }
}

export function updateQuoteSection(id: number, changes: QuoteSectionCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateQuoteSection(id, changes)
        if (response.status === 200) {
            dispatch(slice.actions.updateQuoteSectionSuccess(response.data))
        }
        return response
    }
}

export function deleteQuoteSection(section: QuoteSectionType) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteQuoteSection(section.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteQuoteSectionSuccess(section))
        }
        return response
    }
}

export function addFeeToQuote(quoteId: number, feeTypeId: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateFeeFromType({ quote_id: quoteId, type_id: feeTypeId })
        if (response.status === 201) {
            dispatch(slice.actions.addFeeSuccess(response.data.fee))
            processCombinedResponse(dispatch, response)
        }
        return response
    }
}

export function deleteFee(fee: TFee) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteFee(fee.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteFeeSuccess(fee))
        }
        return response
    }
}

export function updateMaterialOption(
    line_item_id: number,
    material_type_id: number,
    option: number
) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateLineItemMaterialOption(
            line_item_id,
            material_type_id,
            option
        )
        if (response.status === 201) {
            dispatch(slice.actions.updateMaterialOptionSuccess(response.data))
        }
        return response
    }
}

export function deleteMaterialOption(material: TMaterial) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteMaterial(material.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteMaterialOptionSuccess(material))
        }
        return response
    }
}

export function addPackageItem(payload: TPackageItemCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreatePackageItem(payload)
        if (response.status === 201) {
            dispatch(slice.actions.addPackageItemSuccess(response.data))
            dispatch(slice.actions.fetchPackageSuccess(response.data.package))
            processCombinedResponse(dispatch, response)
        }
        return response
    }
}

export function updateMaterial(id: number, payload: MaterialUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateMaterial(id, payload)
        if (response.status === 200) {
            dispatch(slice.actions.updateMaterialSuccess(response.data.material))
            processCombinedResponse(dispatch, response)
            // response.data.packages.forEach((pkg: PackageType) => {
            //     dispatch(slice.actions.fetchPackageSuccess(pkg))
            // })
        }
        return response
    }
}

export function deletePackageItem(item: TPackageItem) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeletePackageItem(item.id)
        if (response.status === 200) {
            dispatch(slice.actions.deletePackageItemSuccess(item))
            dispatch(slice.actions.fetchPackageSuccess(response.data.package))
            processCombinedResponse(dispatch, response)
        }
        return response
    }
}

export function updateFee(id: number, payload: TFeeUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateFee(id, payload)
        if (response.status === 200) {
            dispatch(slice.actions.updateFeeSuccess(response.data.fee))
            response.data.packages.forEach((pkg: TPackage) => {
                dispatch(slice.actions.fetchPackageSuccess(pkg))
            })
        }
        return response
    }
}

export function updatePackage(id: number, payload: PackageUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdatePackage(id, payload)
        if (response.status === 200) {
            dispatch(slice.actions.updatePackageSuccess(response.data))
        }
        return response
    }
}

export function reorderQuoteLineItems(id: number, order: { [key: number]: number }) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiReorderQuoteLineItems(id, order)
        if (response.status === 200) {
            dispatch(slice.actions.reorderLineItemsSuccess(response.data))
        }
        return response
    }
}

export function reorderQuoteFees(id: number, order: { [key: number]: number }) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiReorderQuoteFees(id, order)
        if (response.status === 200) {
            dispatch(slice.actions.reorderFeesSuccess(response.data))
        }
        return response
    }
}

export function addFinancingOptionToQuote(quoteId: number, feeTypeId: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateFinancingOptionFromType({
            quote_id: quoteId,
            type_id: feeTypeId,
        })
        if (response.status === 201) {
            dispatch(slice.actions.addFinancingOptionSuccess(response.data))
        }
        return response
    }
}

export function deleteFinancingOption(item: TFinancingOption) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteFinancingOption(item.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteFinancingOptionSuccess(item))
        }
        return response
    }
}

export function updateFinancingOption(id: number, changes: TFinancingOptionUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateFinancingOption(id, changes)
        if (response.status === 200) {
            dispatch(slice.actions.updateFinancingOptionSuccess(response.data))
        }
        return response
    }
}

export function setQuoteVariable(id: number, slug: string, value: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiSetQuoteVariable(id, slug, value)
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteSuccess(response.data.quote))
        }
        return response
    }
}

export default slice.reducer
