import { useNavigate } from "react-router"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useCallback, useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { TMeasurementType } from "src/@types/measurementType"
import { Button, Card, CardContent, Link, MenuItem, Stack, Typography } from "@mui/material"
import { RHFSelect, RHFTextField } from "src/components/hook-form"
import { PATHS } from "src/routes/paths"
import { Unit } from "src/@types/units"
import JsonEditorComponent from "src/components/json-editor/editorV2"
import CategorySelectTree from "../category-select"

type LineItemTypeFormData = {
    id: number | null
    name: string
    display_name: string
    description: string
    export_name: string
    export_description: string
    category_id: number | null
    data: {
        [key: string]: any
    }
    base_unit: Unit
    formula: string
    code: string
}

type Props = {
    initialData?: LineItemTypeFormData
    onSubmit: (data: LineItemTypeFormData) => void
    measurementTypes?: TMeasurementType[]
}

const generatePossiblePlaceholders = (measurementTypes: TMeasurementType[]) => {
    const placeholders: string[] = [
        "{{li.name}}",
        "{{li.display_name}}",
        "{{li.description}}",
        "{{li.location}}",
        "{{pi.material.quantity}}",
        "{{pi.material.description}}",
        "{{pi.material.display_name}}",
        "{{material.description}} (if there is only one material option)",
        "{{material.name}} (if there is only one material option)",
    ]
    measurementTypes.forEach((mt) => {
        placeholders.push(`{{m.${mt.slug}.value}}`)
        mt.m_fields.forEach((mf) => {
            placeholders.push(`{{m.${mt.slug}.${mf.slug}}}`)
        })
    })
    return placeholders
}

export default function LineItemTypeEditForm({
    onSubmit,
    initialData,
    measurementTypes = [],
}: Props) {
    const navigate = useNavigate()

    const placeholders = generatePossiblePlaceholders(measurementTypes)

    const defaultValues = {
        id: null,
        name: "",
        display_name: "",
        description: "",
        export_name: "",
        export_description: "",
        category_id: null,
        data: {
            number_of_display_material_options: 1,
            do_not_allow_change_materials: false,
            min_total: 0,
            allow_manually_below_min_total: false,
            allow_flat_fee: false,
            collapse_empty_material_options: false,
            notes: "",
        },
        base_unit: Unit.COUNT,
        code: "",
        formula: "{value}",
    }

    const LineItemTypeSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        // description: Yup.string().required("Description is required"),
        formula: Yup.string().required("Formula is required"),
        base_unit: Yup.string().oneOf(Object.values(Unit)).required("Base Unit is required"),
        // category_id: Yup.number().nullable().required('Category is required'),
    })

    const methods = useForm<LineItemTypeFormData>({
        resolver: yupResolver(LineItemTypeSchema),
        defaultValues,
    })

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData)
        }
    }, [initialData, methods])

    const handleSubmit = (data: LineItemTypeFormData) => {
        onSubmit(data)
    }

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Stack direction="column" spacing={2} flex={1}>
                            <RHFTextField name="name" label="Name" required />
                            <RHFTextField name="display_name" label="Display Name" />
                            <RHFTextField
                                name="description"
                                label="Description"
                                multiline
                                maxRows={12}
                            />
                            <RHFTextField name="export_name" label="Export Name" />
                            <RHFTextField
                                name="export_description"
                                label="Export Description"
                                multiline
                                maxRows={12}
                            />
                            <RHFTextField name="code" label="Code" />

                            <RHFTextField name="formula" label="Formula" required />
                            <RHFSelect name="base_unit" label="Base Unit" required>
                                {Object.values(Unit).map((unit) => (
                                    <MenuItem key={unit} value={unit}>
                                        {unit}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFTextField
                                name="data.notes"
                                label="Notes"
                                multiline
                                maxRows={12}
                                helperText={
                                    <>
                                        Internal notes for this service, visible only on quote edit
                                        page. Supports{" "}
                                        <Link
                                            href="https://www.markdownguide.org/cheat-sheet/"
                                            target="_blank"
                                        >
                                            Markdown
                                        </Link>
                                        .
                                    </>
                                }
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }}>
                            <CategorySelectTree
                                current={initialData?.category_id?.toString() || "none"}
                                onChange={(value) => {
                                    console.log(value)
                                    methods.setValue("category_id", value)
                                }}
                                disable={[]}
                                emptyLabel="No Category"
                            />
                        </Stack>
                    </Stack>
                    <Stack sx={{ mt: 2 }}>
                        <Typography variant="subtitle1">Available Variables</Typography>
                        {placeholders.length > 0 && (
                            <Typography variant="subtitle2" color="text.secondary">
                                {placeholders.join(" ")}
                            </Typography>
                        )}
                    </Stack>
                    <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }} mt={1}>
                        <JsonEditorComponent
                            value={methods.watch("data")}
                            // @ts-ignore
                            onChange={useCallback(
                                // @ts-ignore
                                (value: any) => methods.setValue("data", value),
                                [methods]
                            )}
                            // @ts-ignore
                            // onChange={(value) => methods.setValue("data", value)}
                        />
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.lineItemTypes.list())}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={methods.handleSubmit(handleSubmit)}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </Button>
            </Stack>
        </FormProvider>
    )
}
