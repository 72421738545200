import { MenuItem, Select, Stack } from "@mui/material"
import { useState } from "react"
import { CustomField, CustomFieldValue } from "src/@types/custom-field"

interface CustomFieldProps {
    handleSave: (values: CustomFieldValue[]) => void
    field: CustomField
}

const DropdownSingleCustomField = ({ handleSave, field }: CustomFieldProps) => {
    const [isSending, setIsSending] = useState<boolean>(false)

    const value = field.values[0]?.value || ""

    const handleChange = async (v: string) => {
        const item = field.options.find((option) => option.value === v)
        if (!item) return
        setIsSending(true)
        await handleSave([item])
        setIsSending(false)
    }

    return (
        <Stack direction="column">
            {field.header}
            <Select
                value={value}
                // @ts-ignore
                onChange={(event) => handleChange(event.target.value)}
                disabled={isSending}
            >
                {field.options.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option.value}
                        // onClick={() => handleChange(option)}
                        // sx={getOptionColors(option)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Stack>
    )
}

export default DropdownSingleCustomField
