import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import AddIcon from "@mui/icons-material/Add"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { deleteFeeType, getFeeTypes } from "src/redux/slices/fee-types"
import { TFeeType } from "src/@types/fee-type"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"

// const getCategoriesPath = (id: number, categoriesById: { [key: number]: Category }): string[] => {
//     if (!id || !categoriesById[id]) {
//         return []
//     }
//     const category = categoriesById[id]
//     if (category.parent_id === null) {
//         return [category.name]
//     }
//     return [...getCategoriesPath(category.parent_id, categoriesById), category.name]
// }

export default function FeeTypesListPage() {
    const dispatch = useDispatch()
    const { feeTypes } = useSelector((state) => state.feeTypes)
    const feeTypesSorted = [...feeTypes]
    feeTypesSorted.sort((a, b) => a.name.localeCompare(b.name))

    const { hasPermission } = useAuthContext()

    // const {
    //     categories: { byId: categoriesById },
    // } = useSelector((state) => state.categories)

    useEffect(() => {
        dispatch(getFeeTypes())
        // dispatch(getCategories())
    }, [dispatch])

    const onDelete = async (feeType: TFeeType) => {
        if (!window.confirm("Are you sure you want to delete this fee type?")) return
        const response = await dispatch(deleteFeeType(feeType.id))
        if (response.status === 204) {
            console.log(`Fee type ${feeType.name} deleted successfully`)
        }
    }

    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>Fee Types</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Fee Types"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Fee Types",
                            href: PATHS.quotes.feeTypes.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.quotes.feeTypes.create())}
                            disabled={!hasPermission(Permissions.FEE_TYPE_CREATE)}
                        >
                            Create Fee Type
                        </Button>
                    }
                />
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>ID</TableCell> */}
                                    <TableCell>Name</TableCell>
                                    <TableCell>Display Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feeTypesSorted.map((feeType) => (
                                    <TableRow key={feeType.id} hover>
                                        {/* <TableCell>{material.id}</TableCell> */}
                                        <TableCell>{feeType.name}</TableCell>
                                        <TableCell>{feeType.display_name}</TableCell>
                                        <TableCell>{feeType.description}</TableCell>
                                        <TableCell>{feeType.value}</TableCell>
                                        {/* <TableCell>
                                            {material.category_id &&
                                                getCategoriesPath(
                                                    material.category_id,
                                                    categoriesById
                                                ).join(" > ")}
                                        </TableCell> */}
                                        {/* <TableCell>{material.base_unit}</TableCell> */}
                                        {/* <TableCell>{material.price_unit}</TableCell> */}
                                        {/* <TableCell>{material.prices.length}</TableCell> */}
                                        <TableCell>
                                            <ButtonGroup variant="outlined" size="small">
                                                <Button
                                                    onClick={() =>
                                                        navigate(
                                                            PATHS.quotes.feeTypes.details(
                                                                feeType.id
                                                            )
                                                        )
                                                    }
                                                    disabled={
                                                        !hasPermission(Permissions.FEE_TYPE_UPDATE)
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    color="error"
                                                    onClick={() => onDelete(feeType)}
                                                    disabled={
                                                        !hasPermission(Permissions.FEE_TYPE_DELETE)
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
