import { CircularProgress, Container } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { useSnackbar } from "notistack"
import { TGroupUpdate } from "src/@types/security"
import { getPackageType, updatePackageType } from "src/redux/slices/package-types"
import PackageTypeEditForm from "src/forms/package-type-edit"

export default function PackageTypeDetailsPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const fetchPackageTypeAsync = async () => {
            if (idAsNumber) {
                await dispatch(getPackageType(idAsNumber))
                setIsLoading(false)
            }
        }
        fetchPackageTypeAsync()
    }, [dispatch, idAsNumber])

    const packageType = useSelector((state) =>
        state.packageTypes.packageTypes.find((item) => item.id === idAsNumber)
    )

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (packageType === undefined) {
        return <div>Not found</div>
    }

    const onSubmit = async (data: TGroupUpdate) => {
        try {
            console.log(data)
            const response = await dispatch(updatePackageType(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Package type updated successfully", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Group {packageType.name}</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading={`Package Type ${packageType.name}`}
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Types",
                        },
                        {
                            name: "Packages",
                            href: PATHS.quotes.packageTypes.list(),
                        },
                        {
                            name: packageType.name,
                        },
                    ]}
                />
                <PackageTypeEditForm initialData={packageType} onSubmit={onSubmit} />
            </Container>
        </>
    )
}
