import { TQuoteTemplateRelationshipCreate } from "src/@types/quote-template"
import axios from "../utils/axios"

const apiFetchQuoteTemplateRelationships = () =>
    axios.get("/api/v1/quotes/quote-template-relationships/")

const apiFetchQuoteTemplateRelationship = (id: number) =>
    axios.get(`/api/v1/quotes/quote-template-relationships/${id}/`)

const apiUpdateQuoteTemplateRelationship = (id: number, changes: { data: object }) =>
    axios.patch(`/api/v1/quotes/quote-template-relationships/${id}/`, changes)

const apiCreateQuoteTemplateRelationship = (payload: TQuoteTemplateRelationshipCreate) =>
    axios.post(`/api/v1/quotes/quote-template-relationships/`, payload)

const apiDeleteQuoteTemplateRelationship = (id: number) =>
    axios.delete(`/api/v1/quotes/quote-template-relationships/${id}/`)

export {
    apiFetchQuoteTemplateRelationships,
    apiFetchQuoteTemplateRelationship,
    apiUpdateQuoteTemplateRelationship,
    apiCreateQuoteTemplateRelationship,
    apiDeleteQuoteTemplateRelationship,
}
