import {
    Autocomplete,
    Button,
    ButtonGroup,
    Card,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getCategories } from "src/redux/slices/categories"
import {
    copyLineItemType,
    deleteLineItemType,
    getLineItemTypes,
} from "src/redux/slices/lineItemTypes"
import { useDispatch, useSelector } from "src/redux/store"
import AddIcon from "@mui/icons-material/Add"
import { PATHS } from "src/routes/paths"
import { Category } from "src/@types/category"
import { useSnackbar } from "notistack"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"
import { TLineItemType } from "src/@types/lineItemType"
import ExportCSVButton from "src/components/export-to-csv-button"
import { getMaterialTypes } from "src/redux/slices/material-types"
import { TMaterialType } from "src/@types/material-type"

const convertLineItemTypesToCSV = (
    lineItemTypes: TLineItemType[],
    categoriesById: { [key: number]: Category },
    materialTypes: TMaterialType[]
): (string | number)[][] => {
    const materialTypesByCategoryId = materialTypes.reduce(
        (acc, mt) => {
            if (mt.category_id === null) return acc
            if (!acc[mt.category_id]) {
                acc[mt.category_id] = []
            }
            acc[mt.category_id].push(mt)
            return acc
        },
        {} as { [key: number]: TMaterialType[] }
    )
    const rows: (string | number)[][] = [
        [
            "ID",
            "Name",
            "Display Name",
            "Description",
            "Export Name",
            "Export Description",
            "Code",
            "Formula",
            "Base Unit",
            "Category ID",
            "Category",
            "Possible Measurements",
            "Default Measurements",
            "Min Price",
            "Allow Flat Fee",
            "All Possible Materials",
            "Possible Materials",
            "Possible Materials Category",
            "Default Material 1",
            "Default Material 2",
            "Default Material 3",
            "Do Not Allow Changing Materials",
            "# of Displayed Materials",
        ],
    ]
    lineItemTypes.forEach((li) => {
        const {
            id,
            name,
            display_name,
            description,
            export_name,
            export_description,
            code,
            formula,
            base_unit,
            category_id,
            measurements_possible,
            measurements_default,
            materials_possible,
            materials_possible_categories,
            material_default_op_1,
            material_default_op_2,
            material_default_op_3,
            data,
        } = li
        let categoriesPath = ""
        if (category_id) {
            categoriesPath = getCategoriesPath(category_id, categoriesById).join(" > ")
        }
        const possibleMeasurements = measurements_possible
            .map((m) => `${m.name} (${m.id})`)
            .join("\n")
        const defaultMeasurements = measurements_default
            .map((m) => `${m.name} (${m.id})`)
            .join("\n")
        const possibleMaterials = materials_possible.map((m) => `${m.name} (${m.id})`).join("\n")
        let defaultMaterial1 = ""
        if (material_default_op_1) {
            defaultMaterial1 = `${material_default_op_1.name} (${material_default_op_1.id})`
        }
        let defaultMaterial2 = ""
        if (material_default_op_2) {
            defaultMaterial2 = `${material_default_op_2.name} (${material_default_op_2.id})`
        }
        let defaultMaterial3 = ""
        if (material_default_op_3) {
            defaultMaterial3 = `${material_default_op_3.name} (${material_default_op_3.id})`
        }
        const allPossibleMaterialsIds = materials_possible.map((m) => m.id)
        const allPossibleMaterials: TMaterialType[] = [...materials_possible]
        if (materials_possible_categories.length > 0) {
            materials_possible_categories.forEach((category) => {
                if (materialTypesByCategoryId[category.id]) {
                    materialTypesByCategoryId[category.id].forEach((mt) => {
                        if (!allPossibleMaterialsIds.includes(mt.id)) {
                            allPossibleMaterials.push(mt)
                            allPossibleMaterialsIds.push(mt.id)
                        }
                    })
                }
            })
        }
        ;[material_default_op_1, material_default_op_2, material_default_op_3].forEach((mt) => {
            if (mt && !allPossibleMaterialsIds.includes(mt.id)) {
                allPossibleMaterials.push(mt)
                allPossibleMaterialsIds.push(mt.id)
            }
        })
        const allPossibleMaterialsCell = allPossibleMaterials
            .map((m) => `${m.name} (${m.id})`)
            .join("\n")

        const possibleMaterialsCategory = materials_possible_categories
            .map((m) => `${m.name} (${m.id})`)
            .join("\n")
        rows.push([
            id,
            name,
            display_name,
            description,
            export_name,
            export_description,
            code,
            formula,
            base_unit,
            category_id || "",
            categoriesPath,
            possibleMeasurements,
            defaultMeasurements,
            data.min_total || 0,
            li.data.allow_flat_fee ? "TRUE" : "FALSE",
            allPossibleMaterialsCell,
            possibleMaterials,
            possibleMaterialsCategory,
            defaultMaterial1,
            defaultMaterial2,
            defaultMaterial3,
            data.do_not_allow_change_materials ? "TRUE" : "FALSE",
            data.number_of_display_material_options || "",
        ])
    })
    return rows
}

export const getCategoriesPath = (
    id: number,
    categoriesById: { [key: number]: Category }
): string[] => {
    const category = categoriesById[id]
    if (category.parent_id === null) {
        return [category.name]
    }
    return [...getCategoriesPath(category.parent_id, categoriesById), category.name]
}

const getCategoriesOptions = (
    categoriesById: { [key: number]: Category },
    categoriesByParentId: { [key: number]: number[] },
    parentId: number,
    level: number
) => {
    const categories: Category[] = []
    categoriesByParentId[parentId]?.forEach((id) => {
        const category = JSON.parse(JSON.stringify(categoriesById[id]))
        // Copy the category object to avoid mutating the original object
        category.name = `${"═".repeat(level)} ${category.name}`
        categories.push(category)
        categories.push(
            ...getCategoriesOptions(categoriesById, categoriesByParentId, category.id, level + 1)
        )
    })
    return categories
}

export default function LineItemTypesListPage() {
    const navigate = useNavigate()
    const [selectedCategoryId, setSelectedCategory] = useState<number | null>(null)
    const [search, setSearch] = useState("")

    const { enqueueSnackbar } = useSnackbar()

    const { hasPermission } = useAuthContext()

    const dispatch = useDispatch()
    const { lineItemTypes } = useSelector((state) => state.lineItemTypes)
    const {
        categories: {
            byId: categoriesById,
            byParentId: categoriesByParentId,
            byParentIdNested: categoriesByParentIdNested,
        },
    } = useSelector((state) => state.categories)

    const categories = getCategoriesOptions(categoriesById, categoriesByParentId, 0, 0)

    const lineItemTypesFiltered = lineItemTypes.filter(
        (li) =>
            selectedCategoryId === null ||
            li.category_id === selectedCategoryId ||
            categoriesByParentIdNested[selectedCategoryId]?.includes(li.category_id || 0)
    )
    const lineItemTypesSorted = [...lineItemTypesFiltered]
    lineItemTypesSorted.sort((a, b) => a.name.localeCompare(b.name))
    const lineItemTypesFilteredAndSorted = lineItemTypesSorted.filter(
        (li) => search.length < 3 || li.name.toLowerCase().includes(search.toLowerCase())
        // || li.description.toLowerCase().includes(search.toLowerCase())
    )
    const materialTypes = useSelector((state) => state.materialTypes.materialTypes)

    useEffect(() => {
        dispatch(getMaterialTypes())
        dispatch(getCategories())
        dispatch(getLineItemTypes())
    }, [dispatch])

    const onDelete = async (id: number) => {
        if (window.confirm("Are you sure you want to delete this service?")) {
            const response = await dispatch(deleteLineItemType(id))
            if (response.status === 204) {
                enqueueSnackbar("Service deleted successfully", { variant: "success" })
            } else {
                enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
            }
        }
    }

    const onCopyLineItemType = async (lineItemType: TLineItemType) => {
        if (window.confirm(`Are you sure you want to copy the service ${lineItemType.name}?`)) {
            const response = await dispatch(copyLineItemType(lineItemType.id))
            if (response.status === 201) {
                navigate(PATHS.quotes.lineItemTypes.details(response.data.id))
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Services</title>
            </Helmet>
            <Container maxWidth={false}>
                <CustomBreadcrumbs
                    heading="Services"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Services",
                            href: PATHS.quotes.lineItemTypes.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <ButtonGroup size="small">
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={() => navigate(PATHS.quotes.lineItemTypes.create())}
                                disabled={!hasPermission(Permissions.LINE_ITEM_TYPE_CREATE)}
                            >
                                Create
                            </Button>
                            <ExportCSVButton
                                data={convertLineItemTypesToCSV(
                                    lineItemTypes,
                                    categoriesById,
                                    materialTypes
                                )}
                                filename="services.csv"
                            />
                        </ButtonGroup>
                    }
                />
                <Card>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{ px: 1.5, py: 1.5 }}
                    >
                        <TextField
                            label="Search"
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                            value={search}
                            size="small"
                        />
                        <Autocomplete
                            id="category"
                            getOptionLabel={(option) => option.name}
                            getOptionKey={(option) => option.id}
                            value={
                                categories.find((category) => category.id === selectedCategoryId) ||
                                null
                            }
                            onChange={(e, value) => setSelectedCategory(value?.id || null)}
                            sx={{ width: 300 }}
                            options={categories}
                            renderInput={(params) => <TextField {...params} label="Category" />}
                            size="small"
                        />
                    </Stack>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Display Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Export Name</TableCell>
                                    <TableCell>Export Description</TableCell>
                                    <TableCell>Base Unit</TableCell>
                                    {/* <TableCell>Material Base Unit</TableCell> */}
                                    {/* <TableCell>Category</TableCell> */}
                                    <TableCell>Materials</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lineItemTypesFilteredAndSorted.map((lineItemType) => {
                                    const { id, name, description, category_id } = lineItemType
                                    let categoriesPath: string[] = []
                                    if (category_id && categoriesById[category_id]) {
                                        // const category = categoriesById[category_id]
                                        categoriesPath = getCategoriesPath(
                                            category_id,
                                            categoriesById
                                        )
                                    }
                                    return (
                                        <TableRow key={id} hover>
                                            <TableCell>
                                                <Typography variant="body2">{name}</Typography>
                                                <Typography variant="caption" color="gray">
                                                    {categoriesPath.join(" > ")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 10 }}>
                                                {lineItemType.display_name}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 10 }}>
                                                {description}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 10 }}>
                                                {lineItemType.export_name}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 10 }}>
                                                {lineItemType.export_description}
                                            </TableCell>
                                            <TableCell>{lineItemType.base_unit}</TableCell>
                                            {/* <TableCell>{lineItemType.base_material_unit}</TableCell> */}
                                            {/* <TableCell>{categoriesPath.join(" > ")}</TableCell> */}
                                            <TableCell sx={{ fontSize: 10 }}>
                                                <b>Possible:</b>{" "}
                                                {lineItemType.materials_possible
                                                    .map((m) => m.name)
                                                    .join(", ") || "-"}
                                                <br />
                                                <b>Category:</b>{" "}
                                                {lineItemType.materials_possible_categories[0]
                                                    ? getCategoriesPath(
                                                          lineItemType
                                                              .materials_possible_categories[0].id,
                                                          categoriesById
                                                      ).join(" > ")
                                                    : "-"}
                                                <br />
                                                <b>Option 1:</b>{" "}
                                                {lineItemType.material_default_op_1?.name || "-"}
                                                <br />
                                                <b>Option 2:</b>{" "}
                                                {lineItemType.material_default_op_2?.name || "-"}
                                                <br />
                                                <b>Option 3:</b>{" "}
                                                {lineItemType.material_default_op_3?.name || "-"}
                                            </TableCell>
                                            <TableCell>
                                                <ButtonGroup size="small" variant="outlined">
                                                    <Button
                                                        href={PATHS.quotes.lineItemTypes.details(
                                                            id
                                                        )}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            navigate(
                                                                PATHS.quotes.lineItemTypes.details(
                                                                    id
                                                                )
                                                            )
                                                        }}
                                                        disabled={
                                                            !hasPermission(
                                                                Permissions.LINE_ITEM_TYPE_UPDATE
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            onCopyLineItemType(lineItemType)
                                                        }
                                                        color="info"
                                                    >
                                                        Copy
                                                    </Button>
                                                    <Button
                                                        onClick={() => onDelete(id)}
                                                        color="error"
                                                        disabled={
                                                            !hasPermission(
                                                                Permissions.LINE_ITEM_TYPE_DELETE
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
