import { CircularProgress, Container } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { useSnackbar } from "notistack"
import { getUser, updateUser } from "src/redux/slices/groups"
import { TUserUpdate } from "src/@types/user"
import UserEditForm from "src/forms/user-edit"

export default function UserDetailsPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const fetchUserAsync = async () => {
            if (idAsNumber) {
                await dispatch(getUser(idAsNumber))
                setIsLoading(false)
            }
        }
        fetchUserAsync()
    }, [dispatch, idAsNumber])

    // useEffect(() => {
    // dispatch(getUsers())
    // dispatch(getPermissions())
    // }, [dispatch])

    const user = useSelector((state) => state.groups.users.find((item) => item.id === idAsNumber))

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (user === undefined) {
        return <div>Not found</div>
    }

    const onSubmit = async (data: TUserUpdate) => {
        try {
            console.log(data)
            const response = await dispatch(updateUser(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("User updated successfully", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>User {`${user.first_name} ${user.last_name}`}</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading={`User ${user.first_name} ${user.last_name}`}
                    links={[
                        {
                            name: "Users",
                            href: PATHS.auth.users.list(),
                        },
                        {
                            name: `${user.first_name} ${user.last_name}`,
                        },
                    ]}
                />
                <UserEditForm initialData={user} onSubmit={onSubmit} />
            </Container>
        </>
    )
}
