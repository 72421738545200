import { useEffect, useState } from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    IconButton,
    InputAdornment,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

type ValueProps = {
    value: number | null
    onChange: (value: number) => void
    name: string
}

type Props = {
    state: number | null
    name: string
    values: ValueProps[]
    colSpan?: number
    cellStyle?: React.CSSProperties
    formatState?: (value: number | null) => string | number | null
    disabled?: boolean
}

function ValueField({ value, onChange, name }: ValueProps) {
    // useEffect(() => {
    //     setValue(state || 0)
    // }, [state])

    const adjustValue = (amount: number) => {
        if ((value || 0) + amount < 0) return
        const newValue = (value || 0) + amount
        onChange(newValue)
    }

    return (
        <TextField
            autoFocus
            sx={{ mt: 1 }}
            fullWidth
            label={name}
            value={value}
            type="number"
            inputMode="numeric"
            onChange={(e) => onChange(parseInt(e.target.value, 10))}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton onClick={() => adjustValue(-5)}>
                            <RemoveIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => adjustValue(5)}>
                            <AddIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            inputProps={{
                min: 0,
                step: 5,
                pattern: "[0-9]*",
                style: { textAlign: "center" },
            }}
        />
    )
}

export default function EditNumbersCell({
    state,
    colSpan,
    name,
    cellStyle = {},
    formatState = (value) => value,
    disabled = false,
    values,
}: Props) {
    const [open, setOpen] = useState(false)
    const startingValues: any = {}
    values.forEach((value) => {
        startingValues[value.name] = value.value
    })
    // console.log(values)
    // console.log(startingValues)
    const [valuesObj, setValuesObj] = useState<any>(startingValues)

    const handleOpenDialog = (event: any) => {
        if (disabled) return
        setOpen(true)
        // setValue(state || 0)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }

    const handleSave = () => {
        values.forEach((value) => {
            value.onChange(valuesObj[value.name])
        })
    }

    useEffect(() => {
        const newValues: any = {}
        values.forEach((value) => {
            newValues[value.name] = value.value
        })
        setValuesObj(newValues)
    }, [values])

    return (
        <>
            <td
                style={{
                    padding: "0px",
                    position: "relative",
                    // fontSize: "10px",
                    ...cellStyle,
                }}
                colSpan={colSpan}
                className="text-regular"
            >
                <div
                    role="button"
                    tabIndex={0}
                    onClick={handleOpenDialog}
                    onKeyDown={() => {}} // For accessibility
                    style={{
                        cursor: disabled ? "unset" : "pointer",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        // minHeight: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        outline: "none",
                    }}
                >
                    {disabled ? "-" : (formatState(state) || 0).toLocaleString()}
                </div>
            </td>
            <Dialog open={open} onClose={handleCloseDialog} maxWidth="xs">
                <DialogTitle>Edit</DialogTitle>
                <DialogContent>
                    <DialogContentText>{name}</DialogContentText>
                    {values.map((value) => (
                        <ValueField
                            key={value.name}
                            value={valuesObj[value.name]}
                            onChange={(newValue) =>
                                setValuesObj({ ...valuesObj, [value.name]: newValue })
                            }
                            name={value.name}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="error">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleSave()
                            handleCloseDialog()
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
