import { createSlice, Dispatch } from "@reduxjs/toolkit"
import { PackageTypeCreate, PackageTypesState, PackageTypeUpdate } from "src/@types/package-type"

import {
    apiFetchPackageTypes,
    apiCreatePackageType,
    apiDeletePackageType,
    apiUpdatePackageType,
    apiFetchPackageType,
} from "src/api/package-types"

const initialState: PackageTypesState = {
    packageTypes: [],
    isLoading: false,
}

const slice = createSlice({
    name: "packageTypes",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        fetchPackageTypesSuccess: (state, action) => {
            state.isLoading = false
            state.packageTypes = action.payload
        },
        fetchPackageTypeSuccess: (state, action) => {
            state.isLoading = false
            const packageType = action.payload
            const index = state.packageTypes.findIndex((item) => item.id === packageType.id)
            if (index !== -1) {
                state.packageTypes[index] = packageType
            } else {
                state.packageTypes.push(packageType)
            }
        },
        createPackageTypeSuccess: (state, action) => {
            state.isLoading = false
            state.packageTypes.push(action.payload)
        },
        deletePackageTypeSuccess: (state, action) => {
            state.isLoading = false
            state.packageTypes = state.packageTypes.filter((item) => item.id !== action.payload)
        },
    },
})

export function getPackageTypes() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchPackageTypes()
        if (response.status === 200) {
            dispatch(slice.actions.fetchPackageTypesSuccess(response.data.results))
        }
        return response
    }
}

export function getPackageType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchPackageType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPackageTypeSuccess(response.data))
        }
        return response
    }
}

export function createPackageType(data: PackageTypeCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreatePackageType(data)
        if (response.status === 201) {
            dispatch(slice.actions.createPackageTypeSuccess(response.data))
        }
        return response
    }
}

export function updatePackageType(id: number, data: PackageTypeUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdatePackageType(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPackageTypeSuccess(response.data))
        }
        return response
    }
}

export function deletePackageType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeletePackageType(id)
        if (response.status === 204) {
            dispatch(slice.actions.deletePackageTypeSuccess(id))
        }
        return response
    }
}

export default slice.reducer
