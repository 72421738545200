import { createSlice, Dispatch } from "@reduxjs/toolkit"
import {
    apiCreateCategory,
    apiFetchCategories,
    apiFetchCategory,
    apiUpdateCategory,
    apiDeleteCategory,
} from "src/api/categories"
import { CategoriesState, Category, CategoryCreate, CategoryUpdate } from "src/@types/category"

const initialState: CategoriesState = {
    isLoading: false,
    categories: {
        byId: {},
        byParentId: {},
        byParentIdNested: {},
    },
}

const listNestedChildren = (categories: { [key: number]: number[] }, id: number): number[] => {
    const children = categories[id] || []
    return children.reduce(
        (acc, child) => [...acc, ...listNestedChildren(categories, child)],
        children
    )
}

const generateByParentId = (categoriesById: { [key: number]: Category }) => {
    const byParentId: { [key: number]: number[] } = {}
    Object.values(categoriesById).forEach((category) => {
        if (!byParentId[category.parent_id || 0]) {
            byParentId[category.parent_id || 0] = []
        }
        byParentId[category.parent_id || 0].push(category.id)
    })
    return byParentId
}

const generateByParentIdNested = (byParentId: { [key: number]: number[] }) => {
    const byParentIdNested: { [key: number]: number[] } = {}
    Object.keys(byParentId).forEach((key) => {
        byParentIdNested[parseInt(key, 10)] = listNestedChildren(byParentId, parseInt(key, 10))
    })
    return byParentIdNested
}

const slice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchCategoriesSuccess(state, action) {
            state.isLoading = false
            action.payload.forEach((category: any) => {
                state.categories.byId[category.id] = category
            })

            state.categories.byParentId = generateByParentId(state.categories.byId)
            state.categories.byParentIdNested = generateByParentIdNested(
                state.categories.byParentId
            )
        },
        deleteCategorySuccess(state, action) {
            state.isLoading = false
            delete state.categories.byId[action.payload.id]
            state.categories.byParentId = generateByParentId(state.categories.byId)
            state.categories.byParentIdNested = generateByParentIdNested(
                state.categories.byParentId
            )
        },
    },
})

export function getCategories() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchCategories()
        if (response.status === 200) {
            dispatch(slice.actions.fetchCategoriesSuccess(response.data.results))
        }
    }
}

export function getCategory(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchCategory(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchCategoriesSuccess([response.data]))
        }
        return response
    }
}

export function createCategory(data: CategoryCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateCategory(data)
        if (response.status === 201) {
            dispatch(slice.actions.fetchCategoriesSuccess([response.data]))
        }
        return response
    }
}

export function updateCategory(id: number, data: CategoryUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateCategory(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchCategoriesSuccess([response.data]))
        }
        return response
    }
}

export function deleteCategory(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteCategory(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteCategorySuccess(id))
        }
        return response
    }
}

export default slice.reducer
