import { createSlice, Dispatch } from "@reduxjs/toolkit"
import {
    apiFetchPayTypes,
    apiFetchPayType,
    apiUpdatePayType,
    apiCreatePayType,
} from "src/api/payTypes"
import { PayTypeCreate, PayTypesState, PayTypeUpdate } from "src/@types/payType"
import { TContainerUpdate } from "src/@types/containers"
import { apiCreateContainer, apiUpdateContainer } from "src/api/containers"

const initialState: PayTypesState = {
    isLoading: false,
    payTypes: [],
}

const slice = createSlice({
    name: "payTypes",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchPayTypesSuccess(state, action) {
            state.isLoading = false
            state.payTypes = action.payload
        },
        fetchPayTypeSuccess(state, action) {
            state.isLoading = false
            const index = state.payTypes.findIndex((item) => item.id === action.payload.id)
            if (index !== -1) {
                state.payTypes[index] = action.payload
            } else {
                state.payTypes.push(action.payload)
            }
        },
    },
})

export function getPayTypes() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchPayTypes()
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayTypesSuccess(response.data.results))
        }
    }
}

export function getPayType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchPayType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayTypeSuccess(response.data))
        }
    }
}

export function createPayType(update: PayTypeCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreatePayType(update)
        if (response.status === 201) {
            dispatch(slice.actions.fetchPayTypeSuccess(response.data))
        }
        return response
    }
}

export function updatePayType(id: number, changes: PayTypeUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdatePayType(id, changes)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayTypeSuccess(response.data))
        }
    }
}

export function updatePayTypeRule(id: number, containerId: number, changes: TContainerUpdate) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        await apiUpdateContainer(containerId, changes)
        const response = await apiFetchPayType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayTypeSuccess(response.data))
        }
    }
}

export function createRuleContainerForPayType(id: number) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        await apiCreateContainer({
            name: "New Rule",
            value: 0,
            status: "active",
            type: "payroll.rules",
            data: {},
            effective_from: null,
            effective_to: null,
            related_entity: {
                id,
                type: "pay_type",
            },
        })
        const response = await apiFetchPayType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayTypeSuccess(response.data))
        }
    }
}

export default slice.reducer
