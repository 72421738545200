import { createSlice, Dispatch } from "@reduxjs/toolkit"
import { apiFetchEmployees } from "src/api/data"
import { EmployeesState } from "src/@types/employees"

const initialState: EmployeesState = {
    isLoading: false,
    employees: {
        byId: {},
    },
}

const slice = createSlice({
    name: "employees",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchEmployeesSuccess(state, action) {
            state.isLoading = false
            action.payload.forEach((employee: any) => {
                state.employees.byId[employee.id] = employee
            })
        },
    },
})


export function getEmployees() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchEmployees()
        if (response.status === 200) {
            dispatch(slice.actions.fetchEmployeesSuccess(response.data.results))
        }
    }
}



export default slice.reducer