import { MaterialTypePriceCreate, MaterialTypePriceUpdate } from "src/@types/material-type"
import axios from "../utils/axios"

const apiFetchMaterialTypePrices = () => axios.get("/api/v1/quotes/material-type-prices/")

const apiFetchMaterialTypePrice = (id: number) =>
    axios.get(`/api/v1/quotes/material-type-prices/${id}/`)

const apiUpdateMaterialTypePrice = (id: number, changes: MaterialTypePriceUpdate) =>
    axios.patch(`/api/v1/quotes/material-type-prices/${id}/`, changes)

const apiCreateMaterialTypePrice = (payload: MaterialTypePriceCreate) =>
    axios.post(`/api/v1/quotes/material-type-prices/`, payload)

const apiDeleteMaterialTypePrice = (id: number) =>
    axios.delete(`/api/v1/quotes/material-type-prices/${id}/`)

export {
    apiUpdateMaterialTypePrice,
    apiFetchMaterialTypePrice,
    apiFetchMaterialTypePrices,
    apiCreateMaterialTypePrice,
    apiDeleteMaterialTypePrice,
}
