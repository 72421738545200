import { createSlice, Dispatch } from "@reduxjs/toolkit"
import {
    MeasurementTypeCreate,
    MeasurementTypesState,
    TMeasurementType,
    MeasurementTypeUpdate,
} from "src/@types/measurementType"
import {
    apiCreateMeasurementType,
    apiFetchMeasurementType,
    apiFetchMeasurementTypes,
    apiUpdateMeasurementType,
} from "src/api/measurement-types"
import {
    apiCreateMeasurementTypeField,
    apiDeleteMeasurementTypeField,
    apiUpdateMeasurementTypeField,
} from "src/api/measurement-type-fields"
import {
    TMeasurementTypeFieldCreate,
    TMeasurementTypeField,
    TMeasurementTypeFieldUpdate,
} from "src/@types/measurement-type-fields"

const initialState: MeasurementTypesState = {
    isLoading: false,
    measurementTypes: [],
    fieldsByMeasurementTypeId: {},
}

const processMeasurementType = (mt: TMeasurementType, state: MeasurementTypesState) => {
    state.fieldsByMeasurementTypeId[mt.id] = mt.m_fields.sort((a, b) => a.order - b.order)
}

const slice = createSlice({
    name: "measurementTypes",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchMeasurementTypesSuccess(state, action) {
            state.isLoading = false
            state.measurementTypes = action.payload
            state.measurementTypes.forEach((mt) => {
                processMeasurementType(mt, state)
            })
        },
        fetchMeasurementTypeSuccess(state, action) {
            state.isLoading = false
            const index = state.measurementTypes.findIndex((item) => item.id === action.payload.id)
            if (index !== -1) {
                state.measurementTypes[index] = action.payload
            } else {
                state.measurementTypes.push(action.payload)
            }
            processMeasurementType(action.payload, state)
        },
        createMeasurementTypeSuccess(state, action) {
            state.isLoading = false
            state.measurementTypes.push(action.payload)
        },
        createMeasurementTypeFieldSuccess(state, action) {
            state.isLoading = false
            const { type_id } = action.payload
            if (state.fieldsByMeasurementTypeId[type_id] === undefined) {
                state.fieldsByMeasurementTypeId[type_id] = []
            }
            state.fieldsByMeasurementTypeId[type_id].push(action.payload)
            state.fieldsByMeasurementTypeId[type_id].sort((a, b) => a.order - b.order)
        },
        updateMeasurementTypeFieldSuccess(state, action) {
            state.isLoading = false
            const { type_id } = action.payload
            if (state.fieldsByMeasurementTypeId[type_id] === undefined) {
                state.fieldsByMeasurementTypeId[type_id] = []
            }
            state.fieldsByMeasurementTypeId[type_id] = state.fieldsByMeasurementTypeId[type_id].map(
                (f) => {
                    if (f.id === action.payload.id) {
                        return action.payload
                    }
                    return f
                }
            )
            state.fieldsByMeasurementTypeId[type_id].sort((a, b) => a.order - b.order)
        },
        deleteMeasurementTypeFieldSuccess(state, action) {
            state.isLoading = false
            const { type_id } = action.payload
            if (state.fieldsByMeasurementTypeId[type_id] === undefined) {
                state.fieldsByMeasurementTypeId[type_id] = []
            }
            state.fieldsByMeasurementTypeId[type_id] = state.fieldsByMeasurementTypeId[
                type_id
            ].filter((f) => f.id !== action.payload.id)
            state.fieldsByMeasurementTypeId[type_id].sort((a, b) => a.order - b.order)
        },
    },
})

export function getMeasurementTypes() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchMeasurementTypes()
        if (response.status === 200) {
            dispatch(slice.actions.fetchMeasurementTypesSuccess(response.data.results))
        }
        return response
    }
}

export function getMeasurementType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchMeasurementType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchMeasurementTypeSuccess(response.data))
        }
        return response
    }
}

export function createMeasurementType(data: MeasurementTypeCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateMeasurementType(data)
        if (response.status === 201) {
            dispatch(slice.actions.createMeasurementTypeSuccess(response.data))
        }
        return response
    }
}

export function updateMeasurementType(id: number, data: MeasurementTypeUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateMeasurementType(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchMeasurementTypeSuccess(response.data))
        }
        return response
    }
}

export function createMeasurementTypeField(data: TMeasurementTypeFieldCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateMeasurementTypeField(data)
        if (response.status === 201) {
            dispatch(slice.actions.createMeasurementTypeFieldSuccess(response.data))
        }
        return response
    }
}

export function updateMeasurementTypeField(id: number, data: TMeasurementTypeFieldUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateMeasurementTypeField(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.updateMeasurementTypeFieldSuccess(response.data))
        }
        return response
    }
}

export function deleteMeasurementTypeField(field: TMeasurementTypeField) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteMeasurementTypeField(field.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteMeasurementTypeFieldSuccess(field))
        }
        return response
    }
}

export default slice.reducer
