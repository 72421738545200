import { TFinancingOptionCreate, TFinancingOptionUpdate } from "src/@types/financing-option"
import axios from "../utils/axios"

const apiCreateFinancingOptionFromType = (payload: TFinancingOptionCreate) =>
    axios.post(`/api/v1/quotes/financing-options/`, payload)

const apiDeleteFinancingOption = (id: number) =>
    axios.delete(`/api/v1/quotes/financing-options/${id}/`)

const apiUpdateFinancingOption = (id: number, payload: TFinancingOptionUpdate) =>
    axios.patch(`/api/v1/quotes/financing-options/${id}/`, payload)

export { apiCreateFinancingOptionFromType, apiDeleteFinancingOption, apiUpdateFinancingOption }
