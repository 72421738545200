import { Button, InputAdornment, TextField } from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { TVariable } from "src/@types/variable"
import { setQuoteVariable } from "src/redux/slices/quotes"
import { useDispatch } from "src/redux/store"

type Props = {
    variable: TVariable
    quoteId: number
}

const VariableField = ({ quoteId, variable }: Props) => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const [isSending, setIsSending] = useState<boolean>(false)

    const [value, setValue] = useState<string>(String(variable.value || ""))

    const handleSave = async () => {
        setIsSending(true)
        if (
            window.confirm(
                `Are you sure you want to set ${variable.name} to ${value}?  It will reset all related measurements.`
            )
        ) {
            const response = await dispatch(
                setQuoteVariable(quoteId, variable.slug, Number(value || 0))
            )
            if (response.status === 200) {
                enqueueSnackbar(
                    `${variable.name} updated successfully; updated ${
                        response.data?.updated_measurement_fields || 0
                    } measurements`,
                    { variant: "success" }
                )
            } else {
                enqueueSnackbar(`Failed to update ${variable.name}`, { variant: "error" })
            }
        }
        setIsSending(false)
    }

    useEffect(() => {
        setValue(String(variable.value || ""))
    }, [variable.value])

    return (
        <TextField
            label={variable.name}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ ml: 1 }}
                            onClick={() => handleSave()}
                            disabled={isSending}
                        >
                            Save
                        </Button>
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default VariableField
