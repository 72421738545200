import axios from "../utils/axios"

export type FetchEstimateFilters = {
    search?: string
    orderBy?: string
}

const apiFetchEstimates = async (filters: FetchEstimateFilters) => {
    const params: any = {}
    if (filters.search && filters.search?.length > 0) {
        params.search = filters.search
    }
    if (filters.orderBy && filters.orderBy?.length > 0) {
        params.order_by = filters.orderBy
    }

    const response = await axios.get("/api/v1/data/estimates", { params })
    return response
}

export { apiFetchEstimates }
