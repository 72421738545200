import { Category } from "src/@types/category"
import {
    Button,
    ButtonGroup,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useNavigate } from "react-router"
import { PATHS } from "src/routes/paths"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"
import { useDispatch } from "src/redux/store"
import { deleteCategory } from "src/redux/slices/categories"
import { useSnackbar } from "notistack"

type CategoriesTableProps = {
    categories: Category[]
    allCategories: Category[]
}

const countChildren = (category: Category, allCategories: Category[]) => {
    const children = allCategories.filter((item) => item.parent_id === category.id)
    return children.length
}

export default function CategoriesTable({ categories, allCategories }: CategoriesTableProps) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { hasPermission } = useAuthContext()

    const handleDelete = async (id: number) => {
        if (window.confirm("Are you sure you want to delete this category?")) {
            const response = await dispatch(deleteCategory(id))
            if (response.status === 204) {
                enqueueSnackbar("Category deleted successfully", { variant: "success" })
            } else {
                enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
            }
        }
    }

    return (
        <Card>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Children</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category) => (
                            <TableRow
                                key={category.id}
                                hover
                                onClick={() =>
                                    navigate(PATHS.quotes.categories.details(category.id))
                                }
                                sx={{ cursor: "pointer" }}
                            >
                                <TableCell>{category.id}</TableCell>
                                <TableCell>{category.name}</TableCell>
                                <TableCell>{category.description}</TableCell>
                                <TableCell>
                                    {countChildren(category, allCategories) || null}
                                </TableCell>
                                <TableCell>
                                    <ButtonGroup variant="outlined" size="small">
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    PATHS.quotes.categories.details(category.id)
                                                )
                                            }
                                            disabled={!hasPermission(Permissions.CATEGORY_UPDATE)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            color="error"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleDelete(category.id)
                                            }}
                                            disabled={
                                                !hasPermission(Permissions.CATEGORY_DELETE) ||
                                                countChildren(category, allCategories) > 0
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}
