import {
    TQuoteTemplateCreate,
    TQuoteTemplateItemCreate,
    TQuoteTemplateUpdate,
} from "src/@types/quote-template"
import axios from "../utils/axios"

const apiFetchQuoteTemplates = () => axios.get("/api/v1/quotes/quote-templates/")

const apiFetchQuoteTemplate = (id: number) => axios.get(`/api/v1/quotes/quote-templates/${id}/`)

const apiUpdateQuoteTemplate = (id: number, changes: TQuoteTemplateUpdate) =>
    axios.patch(`/api/v1/quotes/quote-templates/${id}/`, changes)

const apiCreateQuoteTemplate = (payload: TQuoteTemplateCreate) =>
    axios.post(`/api/v1/quotes/quote-templates/`, payload)

const apiDeleteQuoteTemplate = (id: number) => axios.delete(`/api/v1/quotes/quote-templates/${id}/`)

const apiCreateQuoteTemplateItem = (data: TQuoteTemplateItemCreate) =>
    axios.post(`/api/v1/quotes/quote-template-items/`, data)

const apiDeleteQuoteTemplateItem = (id: number) =>
    axios.delete(`/api/v1/quotes/quote-template-items/${id}/`)

const apiCreateQuoteFromTemplate = (
    id: number,
    data: {
        related_quote_id?: number
        estimate_id?: number | null
    } = {}
) => axios.post(`/api/v1/quotes/quote-templates/${id}/create-quote/`, data)

const apiReorderQuoteTemplateItems = (
    id: number,
    order: { [key: number]: { order: number; section_id: number | null } }
) => axios.patch(`/api/v1/quotes/quote-templates/${id}/reorder/`, order)

export {
    apiFetchQuoteTemplates,
    apiFetchQuoteTemplate,
    apiUpdateQuoteTemplate,
    apiCreateQuoteTemplate,
    apiDeleteQuoteTemplate,
    apiCreateQuoteTemplateItem,
    apiDeleteQuoteTemplateItem,
    apiCreateQuoteFromTemplate,
    apiReorderQuoteTemplateItems,
}
