import axios from '../utils/axios'


const apiFetchPayrollRuns = () =>
    axios.get(`/api/v1/payroll/runs/`)


const apiFetchPayrollRun = (id: number) =>
    axios.get(`/api/v1/payroll/runs/${id}/`)


const apiCreatePayrollRun = (dateFrom: Date, dateTo: Date) => {
    const dateFromStr = dateFrom.toISOString().split('T')[0]
    const dateToStr = dateTo.toISOString().split('T')[0]
    return axios.post(`/api/v1/payroll/runs/`, {
        date_from: dateFromStr,
        date_to: dateToStr,
        employee_ids: [],
        job_ids: [],
        pay_type_ids: [],
    })
}


const apiFetchActiveEmployees = async (dateFrom: string, dateTo: string) => 
    axios.get(`/api/v1/payroll/employees/active/`, {
        params: {
            date_from: dateFrom,
            date_to: dateTo,
        }
    })




type PayrollRunUpdate = {
    dateFrom?: Date | null;
    dateTo?: Date | null;
    employeeIds?: number[];
    jobIds?: number[];
    payTypeIds?: number[];
}


const apiUpdatePayrollRun = (id: number, changes: PayrollRunUpdate) => {
    const dateFromStr = changes.dateFrom?.toISOString().split('T')[0]
    const dateToStr = changes.dateTo?.toISOString().split('T')[0]
    const update: any = {}
    if (dateFromStr) {
        update.date_from = dateFromStr
    }
    if (dateToStr) {
        update.date_to = dateToStr
    }
    if (changes.employeeIds) {
        update.employee_ids = changes.employeeIds
    }
    if (changes.jobIds) {
        update.job_ids = changes.jobIds
    }
    if (changes.payTypeIds) {
        update.pay_type_ids = changes.payTypeIds
    }
    return axios.patch(`/api/v1/payroll/runs/${id}/`, update)
}


const apiAddEmployeesToPayrollRun = (id: number, employeesIds: number[]) =>
    axios.put(`/api/v1/payroll/runs/${id}/employees/`, {
        employee_ids: employeesIds,
    })

const apiRemoveEmployeesFromPayrollRun = (id: number, employeesIds: number[]) =>
    axios.delete(`/api/v1/payroll/runs/${id}/employees/`, {
        data: {
            employee_ids: employeesIds,
        }
    })


const apiAddPayTypesToPayrollRun = (id: number, payTypeIds: number[]) =>
    axios.put(`/api/v1/payroll/runs/${id}/pay-types/`, {
        pay_type_ids: payTypeIds,
    })


const apiRemovePayTypesFromPayrollRun = (id: number, payTypeIds: number[]) =>
    axios.delete(`/api/v1/payroll/runs/${id}/pay-types/`, {
        data: {
            pay_type_ids: payTypeIds,
        }
    })

const apiAddJobsToPayrollRun = (id: number, jobIds: number[]) =>
    axios.put(`/api/v1/payroll/runs/${id}/jobs/`, {
        job_ids: jobIds,
    })

const apiRemoveJobsFromPayrollRun = (id: number, jobIds: number[]) =>
    axios.delete(`/api/v1/payroll/runs/${id}/jobs/`, {
        data: {
            job_ids: jobIds,
        }
    })


const apiDeletePaymentRecordsFromPayrollRun = (payrollId: number, paymentRecordIds: number[]) =>
    axios.delete(`/api/v1/payroll/runs/${payrollId}/payments/`, {
        data: {
            payment_record_ids: paymentRecordIds,
        }
    })

const apiDeletePayrollRun = (id: number) =>
    axios.delete(`/api/v1/payroll/runs/${id}/`)


const apiGeneratePayments = (id: number) =>
    axios.put(`/api/v1/payroll/runs/${id}/payments/generate/`)


export { 
    apiFetchPayrollRuns, 
    apiCreatePayrollRun, 
    apiDeletePayrollRun, 
    apiUpdatePayrollRun, 
    apiAddJobsToPayrollRun,
    apiRemoveJobsFromPayrollRun,
    apiFetchPayrollRun,
    apiFetchActiveEmployees,
    apiAddEmployeesToPayrollRun,
    apiRemoveEmployeesFromPayrollRun,
    apiAddPayTypesToPayrollRun,
    apiRemovePayTypesFromPayrollRun,
    apiGeneratePayments,
    apiDeletePaymentRecordsFromPayrollRun,
}