import { Container } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import { QuoteCreate } from "src/@types/quote"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import QuoteEditForm from "src/forms/quote-edit"
import { createQuote } from "src/redux/slices/quotes"
import { useDispatch } from "src/redux/store"
import { PATHS } from "src/routes/paths"

export default function QuoteCreatePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = async (data: QuoteCreate) => {
        try {
            const response = await dispatch(createQuote(data))
            if (response.status === 201) {
                navigate(PATHS.quotes.quotes.details(response.data.id))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create a Quote</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Create a Quote"
                    links={[
                        // {
                        //     name: "Quotes",
                        // },
                        {
                            name: "Quotes",
                            href: PATHS.quotes.quotes.list(),
                        },
                        {
                            name: "Create",
                        },
                    ]}
                />
                <QuoteEditForm onSubmit={onSubmit} />
            </Container>
        </>
    )
}
