import {
    TQuoteTemplateSectionUpdate,
    TQuoteTemplateSectionCreate,
} from "src/@types/quote-template-section"
import axios from "../utils/axios"

const apiFetchQuoteTemplateSections = () => axios.get("/api/v1/quotes/quote-template-sections/")

const apiFetchQuoteTemplateSection = (id: number) =>
    axios.get(`/api/v1/quotes/quote-template-sections/${id}/`)

const apiUpdateQuoteTemplateSection = (id: number, changes: TQuoteTemplateSectionUpdate) =>
    axios.patch(`/api/v1/quotes/quote-template-sections/${id}/`, changes)

const apiCreateQuoteTemplateSection = async (payload: TQuoteTemplateSectionCreate) => {
    try {
        return await axios.post(`/api/v1/quotes/quote-template-sections/`, payload)
    } catch (error) {
        console.log("Error:", error)
        return error.response
    }
}

const apiDeleteQuoteTemplateSection = (id: number) =>
    axios.delete(`/api/v1/quotes/quote-template-sections/${id}/`)

export {
    apiUpdateQuoteTemplateSection,
    apiFetchQuoteTemplateSection,
    apiFetchQuoteTemplateSections,
    apiCreateQuoteTemplateSection,
    apiDeleteQuoteTemplateSection,
}
