import {
    Autocomplete,
    Button,
    ButtonGroup,
    Card,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import AddIcon from "@mui/icons-material/Add"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import {
    copyMaterialType,
    deleteMaterialType,
    getMaterialTypes,
} from "src/redux/slices/material-types"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { Category } from "src/@types/category"
import { getCategories } from "src/redux/slices/categories"
import { TMaterialType } from "src/@types/material-type"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"
import ExportCSV from "src/components/export-to-csv-button"

const convertMaterialTypesToCSV = (
    materials: TMaterialType[],
    categoriesById: { [key: number]: Category }
): (string | number)[][] => {
    const rows: (string | number)[][] = [
        [
            "ID",
            "Name",
            "Description",
            "Display Name",
            "Code",
            "Category ID",
            "Category",
            "Formula",
            "Base Unit",
            "Price Unit",
            "Flat Rate",
            "Lower Bound",
            "Price Per Unit",
        ],
    ]
    materials.forEach((material) => {
        const {
            id,
            name,
            description,
            display_name,
            code,
            base_unit,
            price_unit,
            category_id,
            formula,
        } = material
        let categoriesPath = ""
        if (category_id) {
            categoriesPath = getCategoriesPath(category_id, categoriesById).join(" > ")
        }
        rows.push([
            id,
            name,
            description,
            display_name,
            code,
            category_id || "",
            categoriesPath,
            formula,
            base_unit,
            price_unit,
            material.data.flat_fee ? "TRUE" : "FALSE",
            material.prices[0]?.range_lower_bound,
            material.prices[0]?.price_per_unit,
        ])
        material.prices.slice(1).forEach((price) => {
            rows.push([
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                price.range_lower_bound,
                price.price_per_unit,
            ])
        })
    })
    return rows
}

const getCategoriesPath = (id: number, categoriesById: { [key: number]: Category }): string[] => {
    if (!id || !categoriesById[id]) {
        return []
    }
    const category = categoriesById[id]
    if (category.parent_id === null) {
        return [category.name]
    }
    return [...getCategoriesPath(category.parent_id, categoriesById), category.name]
}

const getCategoriesOptions = (
    categoriesById: { [key: number]: Category },
    categoriesByParentId: { [key: number]: number[] },
    parentId: number,
    level: number
) => {
    const categories: Category[] = []
    categoriesByParentId[parentId]?.forEach((id) => {
        const category = JSON.parse(JSON.stringify(categoriesById[id]))
        // Copy the category object to avoid mutating the original object
        category.name = `${"═".repeat(level)} ${category.name}`
        categories.push(category)
        categories.push(
            ...getCategoriesOptions(categoriesById, categoriesByParentId, category.id, level + 1)
        )
    })
    return categories
}

export default function MaterialTypesList() {
    const dispatch = useDispatch()
    const [selectedCategoryId, setSelectedCategory] = useState<number | null>(null)
    const [search, setSearch] = useState("")

    const { materialTypes: materials } = useSelector((state) => state.materialTypes)
    const materialsSorted = [...materials]
    materialsSorted.sort((a, b) => a.name.localeCompare(b.name))

    const {
        categories: {
            byId: categoriesById,
            byParentId: categoriesByParentId,
            byParentIdNested: categoriesByParentIdNested,
        },
    } = useSelector((state) => state.categories)

    const categories = getCategoriesOptions(categoriesById, categoriesByParentId, 0, 0)

    const materialsFiltered = materialsSorted.filter(
        (material) =>
            selectedCategoryId === null ||
            material.category_id === selectedCategoryId ||
            categoriesByParentIdNested[selectedCategoryId]?.includes(material.category_id || 0)
    )

    const materialsFilteredAndSearched = materialsFiltered.filter(
        (material) =>
            search.length < 3 || material.name.toLowerCase().includes(search.toLowerCase())
    )

    console.log({ categories })

    const { hasPermission } = useAuthContext()

    useEffect(() => {
        dispatch(getMaterialTypes())
        dispatch(getCategories())
    }, [dispatch])

    const onDelete = async (material: TMaterialType) => {
        if (!window.confirm("Are you sure you want to delete this Material?")) return
        const response = await dispatch(deleteMaterialType(material.id))
        if (response.status === 204) {
            console.log(`Material ${material.name} deleted successfully`)
        }
    }

    const copyMaterialTypeHandler = async (material: TMaterialType) => {
        if (window.confirm(`Are you sure you want to copy the material ${material.name}?`)) {
            const response = await dispatch(copyMaterialType(material.id))
            if (response.status === 201) {
                navigate(PATHS.quotes.materialTypes.details(response.data.id))
            }
        }
    }

    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>Materials</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Materials"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Materials",
                            href: PATHS.quotes.materialTypes.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <ButtonGroup size="small">
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={() => navigate(PATHS.quotes.materialTypes.create())}
                                disabled={!hasPermission(Permissions.MATERIAL_TYPE_CREATE)}
                            >
                                Create
                            </Button>
                            <ExportCSV
                                filename="materials.csv"
                                data={convertMaterialTypesToCSV(materials, categoriesById)}
                            />
                        </ButtonGroup>
                    }
                />
                <Card>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{ px: 1.5, py: 1.5 }}
                    >
                        <TextField
                            label="Search"
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                            value={search}
                            size="small"
                        />
                        <Autocomplete
                            id="category"
                            getOptionLabel={(option) => option.name}
                            getOptionKey={(option) => option.id}
                            value={
                                categories.find((category) => category.id === selectedCategoryId) ||
                                null
                            }
                            onChange={(e, value) => setSelectedCategory(value?.id || null)}
                            sx={{ width: 300 }}
                            options={categories}
                            renderInput={(params) => <TextField {...params} label="Category" />}
                            size="small"
                        />
                    </Stack>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>ID</TableCell> */}
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Display Name</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Base Unit</TableCell>
                                    <TableCell>Price Unit</TableCell>
                                    <TableCell>Prices #</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {materialsFilteredAndSearched.map((material) => (
                                    <TableRow key={material.id} hover>
                                        {/* <TableCell>{material.id}</TableCell> */}
                                        <TableCell>{material.name}</TableCell>
                                        <TableCell>{material.description}</TableCell>
                                        <TableCell>{material.display_name}</TableCell>
                                        <TableCell>
                                            {material.category_id &&
                                                getCategoriesPath(
                                                    material.category_id,
                                                    categoriesById
                                                ).join(" > ")}
                                        </TableCell>
                                        <TableCell>{material.base_unit}</TableCell>
                                        <TableCell>{material.price_unit}</TableCell>
                                        <TableCell>{material.prices.length}</TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="outlined" size="small">
                                                <Button
                                                    href={PATHS.quotes.materialTypes.details(
                                                        material.id
                                                    )}
                                                    // onClick={() =>
                                                    //     navigate(
                                                    //         PATHS.quotes.materialTypes.details(
                                                    //             material.id
                                                    //         )
                                                    //     )
                                                    // }
                                                    disabled={
                                                        !hasPermission(
                                                            Permissions.MATERIAL_TYPE_UPDATE
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        copyMaterialTypeHandler(material)
                                                    }
                                                    color="info"
                                                >
                                                    Copy
                                                </Button>
                                                <Button
                                                    color="error"
                                                    onClick={() => onDelete(material)}
                                                    disabled={
                                                        !hasPermission(
                                                            Permissions.MATERIAL_TYPE_DELETE
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
