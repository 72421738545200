import { Button, Container } from "@mui/material"
import { Helmet } from "react-helmet-async"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import AddIcon from "@mui/icons-material/Add"
import { useDispatch, useSelector } from "src/redux/store"
import { useEffect } from "react"
import { getCategories } from "src/redux/slices/categories"
import { useNavigate } from "react-router"
import { PATHS } from "src/routes/paths"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"
import CategoriesTable from "./CategoriesTable"

export default function CategoriesList() {
    const dispatch = useDispatch()
    const {
        categories: { byId: categoriesMap },
    } = useSelector((state) => state.categories)
    useEffect(() => {
        dispatch(getCategories())
    }, [dispatch])

    const navigate = useNavigate()

    const { hasPermission } = useAuthContext()

    const allCategories = Object.values(categoriesMap)
    const categories = allCategories.filter((item) => item.parent_id === null)

    return (
        <>
            <Helmet>
                <title>Categories</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Categories"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Categories",
                            href: PATHS.quotes.categories.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.quotes.categories.create())}
                            disabled={!hasPermission(Permissions.CATEGORY_CREATE)}
                        >
                            Create Category
                        </Button>
                    }
                />
                <CategoriesTable categories={categories} allCategories={allCategories} />
            </Container>
        </>
    )
}
