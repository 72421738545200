import { Button, CircularProgress, Menu, MenuItem } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { useNavigate } from "react-router"
import { useDispatch, useSelector } from "src/redux/store"
import { useEffect, useState } from "react"
import { getQuoteTemplates } from "src/redux/slices/quote-templates"
import { PATHS } from "src/routes/paths"
import { apiCreateQuoteFromTemplate } from "src/api/quote-templates"
import { useSnackbar } from "notistack"

export default function CreateQuoteButton() {
    const navigate = useNavigate()

    const { enqueueSnackbar } = useSnackbar()

    const [isLoading, setIsLoading] = useState(true)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const dispatch = useDispatch()

    useEffect(() => {
        const getQuoteTemplatesAsync = async () => {
            await dispatch(getQuoteTemplates())
            setIsLoading(false)
        }
        getQuoteTemplatesAsync()
    }, [dispatch])

    const templates = useSelector((state) => state.quoteTemplates.quoteTemplates).filter(
        (item) => item.status === "active"
    )

    const onCreateQuote = async (template_id: number) => {
        setIsLoading(true)
        const response = await apiCreateQuoteFromTemplate(template_id)
        if (response.status === 201) {
            enqueueSnackbar("Quote created successfully", { variant: "success" })
            navigate(PATHS.quotes.quotes.details(response.data.id))
        } else {
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
        setIsLoading(false)
    }

    if (isLoading)
        return (
            <Button
                variant="contained"
                size="small"
                // startIcon={<AddIcon />}
                disabled
            >
                <CircularProgress size={16} />
            </Button>
        )

    if (templates.length === 0)
        return (
            <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => navigate(PATHS.quotes.quoteTemplates.create())}
                data-test="create-quote-button"
            >
                New Quote
            </Button>
        )

    return (
        <>
            <Button
                variant="contained"
                size="small"
                // startIcon={<AddIcon />}
                onClick={(e) => handleOpenMenu(e)}
                endIcon={<ArrowDropDownIcon />}
                data-test="create-quote-button"
            >
                New Quote
            </Button>
            <Menu
                id="create-quote-from-template-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
            >
                {templates.map((item) => (
                    <MenuItem
                        key={item.id}
                        onClick={(e) => {
                            onCreateQuote(item.id)
                            handleCloseMenu()
                        }}
                        dense
                        data-test="create-quote-from-template-menu-item"
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
