import { combineReducers } from "redux"
import payrollRunReducer from "./slices/payrollRuns"
import categoriesReducer from "./slices/categories"
import jobsReducer from "./slices/jobs"
import employeesReducer from "./slices/employees"
import payTypesReducer from "./slices/payTypes"
import materialTypesReducer from "./slices/material-types"
import measurementTypesReducer from "./slices/measurementTypes"
import lineItemTypesReducer from "./slices/lineItemTypes"
import quotesReducer from "./slices/quotes"
import quoteTemplatesReducer from "./slices/quote-templates"
import feeTypesReducer from "./slices/fee-types"
import containersReducer from "./slices/containers"
import groupReducer from "./slices/groups"
import packageTypeReducer from "./slices/package-types"
import financingOptionTypeReducer from "./slices/financing-option-types"

const rootReducer = combineReducers({
    payrollRuns: payrollRunReducer,
    jobs: jobsReducer,
    employees: employeesReducer,
    payTypes: payTypesReducer,
    categories: categoriesReducer,
    materialTypes: materialTypesReducer,
    measurementTypes: measurementTypesReducer,
    lineItemTypes: lineItemTypesReducer,
    quotes: quotesReducer,
    quoteTemplates: quoteTemplatesReducer,
    feeTypes: feeTypesReducer,
    containers: containersReducer,
    groups: groupReducer,
    packageTypes: packageTypeReducer,
    financingOptionTypes: financingOptionTypeReducer,
})

export default rootReducer
