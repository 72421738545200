import { Container } from "@mui/material"
import { useSnackbar } from "notistack"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import PackageTypeEditForm from "src/forms/package-type-edit"
import { createPackageType } from "src/redux/slices/package-types"
import { useDispatch } from "src/redux/store"
import { PATHS } from "src/routes/paths"

type FormValues = {
    name: string
    description: string
    discount_percent: number
    order: number
}

export default function PackageTypeCreatePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = async (data: FormValues) => {
        try {
            const response = await dispatch(createPackageType(data))
            if (response.status === 201) {
                enqueueSnackbar("Package type created successfully", { variant: "success" })
                navigate(PATHS.quotes.packageTypes.details(response.data.id))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create Package Type</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Create a Group"
                    links={[
                        {
                            name: "Types",
                        },
                        {
                            name: "Packages",
                            href: PATHS.quotes.packageTypes.list(),
                        },
                        {
                            name: "Create",
                        },
                    ]}
                />
                <PackageTypeEditForm onSubmit={onSubmit} />
            </Container>
        </>
    )
}
