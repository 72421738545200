import { TreeItem, TreeItemProps, TreeView, treeItemClasses } from "@mui/lab"
import { CircularProgress, alpha, styled } from "@mui/material"
import { useEffect, useState } from "react"
import { Category } from "src/@types/category"
import { getCategories } from "src/redux/slices/categories"
import { useDispatch, useSelector } from "src/redux/store"
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

type CategorySelectTreeProps = {
    current?: string
    onChange: (value: number | null) => void
    disable?: string[]
    emptyLabel: string
}

type TreeItemRecursiveProps = {
    item: Category
    children: Category[]
    allCategories: Category[]
    disable: string[]
}

const StyledTreeView = styled(TreeView)({
    // height: 240,
    padding: "10px 0 10px 0",
    flexGrow: 1,
    // maxWidth: 400,
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "8px",
})

const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    [`& .${treeItemClasses.content}`]: {
        [`&.${treeItemClasses.selected}, &.${treeItemClasses.focused}, &.${treeItemClasses.focused}.${treeItemClasses.selected}`]:
            {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                },
            },
    },
    [`&:hover`]: {
        backgroundColor: alpha(theme.palette.primary.main, 0.3),
    },
}))

const TreeItemRecursive = ({ item, children, allCategories, disable }: TreeItemRecursiveProps) => (
    <StyledTreeItem
        nodeId={item.id.toString()}
        label={item.name}
        disabled={disable.includes(item.id.toString())}
    >
        {children.map((child) => (
            <TreeItemRecursive
                key={child.id}
                item={child}
                allCategories={allCategories}
                children={allCategories.filter((category) => child.id === category.parent_id)}
                disable={disable}
            />
        ))}
    </StyledTreeItem>
)

export default function CategorySelectTree({
    current = "none",
    onChange,
    disable = [],
    emptyLabel,
}: CategorySelectTreeProps) {
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [selected, setSelected] = useState<string>(current)

    useEffect(() => {
        const getCategoriesAsync = async () => {
            await dispatch(getCategories())
            setIsLoading(false)
        }
        getCategoriesAsync()
    }, [dispatch])

    useEffect(() => {
        setSelected(current)
    }, [current])

    const {
        categories: { byId: categoriesMap },
    } = useSelector((state) => state.categories)
    const allCategories = Object.values(categoriesMap)
    const rootCategories = allCategories.filter((item) => item.parent_id === null)
    const childCategories = allCategories.filter((item) => item.parent_id !== null)

    // console.log(rootCategories)

    if (isLoading) return <CircularProgress />

    return (
        <StyledTreeView
            onNodeSelect={(event: any, value: string) => {
                setSelected(value)
                onChange(Number(value) || null)
            }}
            selected={selected}
            defaultCollapseIcon={null}
            defaultExpandIcon={null}
            defaultEndIcon={null}
            expanded={allCategories.map((item) => item.id.toString())}
        >
            <StyledTreeItem nodeId="none" label={emptyLabel} />
            {rootCategories.map((item) => (
                <TreeItemRecursive
                    key={item.id}
                    item={item}
                    children={childCategories.filter((child) => child.parent_id === item.id)}
                    allCategories={childCategories}
                    disable={disable}
                />
            ))}
        </StyledTreeView>
    )
}
