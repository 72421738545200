import { useNavigate } from "react-router"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, Stack } from "@mui/material"
import { RHFCheckbox, RHFTextField } from "src/components/hook-form"
import { LoadingButton } from "@mui/lab"
import { PATHS } from "src/routes/paths"

type FormData = {
    id: number | null
    first_name: string
    last_name: string
    email: string
    username: string
    is_active: boolean
}

type Props = {
    initialData?: FormData
    onSubmit: (data: FormData) => void
}

export default function UserEditForm({ initialData, onSubmit }: Props) {
    const navigate = useNavigate()

    const defaultValues = {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        is_active: false,
    }

    const schema = Yup.object().shape({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email: Yup.string().email().required("Email is required"),
        username: Yup.string().required("Username is required"),
        is_active: Yup.boolean().required("Status is required"),
    })

    const methods = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues,
    })

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData)
        }
    }, [initialData, methods])

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack spacing={2}>
                        <RHFTextField
                            name="first_name"
                            label="First Name"
                            required
                            autoFocus
                            fullWidth
                        />
                        <RHFTextField name="last_name" label="Last Name" required fullWidth />
                        <RHFTextField name="email" label="Email" required fullWidth />
                        <RHFTextField name="username" label="Username" required fullWidth />
                        <RHFCheckbox name="is_active" label="Is Active" />
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.auth.users.list())}
                >
                    Cancel
                </Button>
                <LoadingButton
                    size="small"
                    variant="contained"
                    loading={false}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </LoadingButton>
            </Stack>
        </FormProvider>
    )
}
