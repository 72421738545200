import {
    Button,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import AddIcon from "@mui/icons-material/Add"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getMeasurementTypes } from "src/redux/slices/measurementTypes"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"

export default function MeasurementTypesListPage() {
    const dispatch = useDispatch()
    const { measurementTypes } = useSelector((state) => state.measurementTypes)

    const navigate = useNavigate()

    const { hasPermission } = useAuthContext()

    useEffect(() => {
        dispatch(getMeasurementTypes())
    }, [dispatch])

    console.log(measurementTypes)

    return (
        <>
            <Helmet>
                <title>Measurement Types</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Measurement Types"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Measurement Types",
                            href: PATHS.quotes.measurementTypes.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.quotes.measurementTypes.create())}
                            disabled={!hasPermission(Permissions.MEASUREMENT_TYPE_UPDATE)}
                        >
                            Create Measurement Type
                        </Button>
                    }
                />
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Slug</TableCell>
                                    <TableCell>Base Unit</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {measurementTypes.map((mt) => (
                                    <TableRow key={mt.id} hover>
                                        <TableCell>{mt.id}</TableCell>
                                        <TableCell>{mt.name}</TableCell>
                                        <TableCell>{mt.description}</TableCell>
                                        <TableCell>{mt.slug}</TableCell>
                                        <TableCell>{mt.base_unit}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() =>
                                                    navigate(
                                                        PATHS.quotes.measurementTypes.details(mt.id)
                                                    )
                                                }
                                                disabled={
                                                    !hasPermission(
                                                        Permissions.MEASUREMENT_TYPE_UPDATE
                                                    )
                                                }
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
