import { CategoryCreate, CategoryUpdate } from "src/@types/category"
import axios from "../utils/axios"

const apiFetchCategories = () => axios.get("/api/v1/quotes/categories/")

const apiFetchCategory = (id: number) => axios.get(`/api/v1/quotes/categories/${id}/`)

const apiUpdateCategory = (id: number, changes: CategoryUpdate) =>
    axios.patch(`/api/v1/quotes/categories/${id}/`, changes)

const apiCreateCategory = (payload: CategoryCreate) =>
    axios.post(`/api/v1/quotes/categories/`, payload)

const apiDeleteCategory = async (id: number) => {
    try {
        return await axios.delete(`/api/v1/quotes/categories/${id}/`)
    } catch (error) {
        console.log("Error:", error)
        return error.response
    }
}

export {
    apiUpdateCategory,
    apiFetchCategory,
    apiFetchCategories,
    apiCreateCategory,
    apiDeleteCategory,
}
