import {
    Button,
    ButtonGroup,
    Card,
    CircularProgress,
    Container,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router"
import { TFee } from "src/@types/fee"
import { TLineItem } from "src/@types/lineItem"
import { TMaterial } from "src/@types/material"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getQuote } from "src/redux/slices/quotes"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import "./styles.css"
import { calculatePackageTotal } from "src/services/package-service"
import { formatLineItemDescription } from "src/services/line-item-service"
// import { TFinancingOption } from "src/@types/financing-option"
// import { formatPackagePrice } from "../quote-view"

// const getFinancingOptionDescription = (option: TFinancingOption | null, total: number) => {
//     if (!option) return ""
//     const { apr, term } = option
//     const monthlyPayment = formatPackagePrice(total, option, "financing")
//     return `${monthlyPayment} - ${apr}% APR - ${term} months`
// }

type Props = {
    text: string
} & React.ComponentProps<typeof TableCell>

const TableCellCopyable = ({ text, ...rest }: Props) => {
    const [isCopied, setIsCopied] = useState(false)

    return (
        <TableCell
            onClick={() => {
                navigator.clipboard.writeText(text)
                setIsCopied(true)
                setTimeout(() => {
                    setIsCopied(false)
                }, 1000)
            }}
            {...rest}
        >
            <Tooltip open={isCopied} title="copied">
                <div>{text}</div>
            </Tooltip>
        </TableCell>
    )
}

export default function QuoteExportPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        const getQuoteAndLineItemsAsync = async () => {
            if (idAsNumber) {
                await dispatch(getQuote(idAsNumber))
                setIsLoading(false)
            }
        }
        getQuoteAndLineItemsAsync()
    }, [dispatch, idAsNumber])

    const quote = useSelector((state) => state.quotes.quotes.find((item) => item.id === idAsNumber))
    const lineItems = useSelector((state) => state.quotes.lineItemsByQuoteId[idAsNumber]) || []
    // const lineItemsOrdered = lineItems.filter((lineItem) => lineItem.value)
    const fees = useSelector((state) => state.quotes.feesByQuoteId[idAsNumber]) || []
    const packages = useSelector((state) => state.quotes.packagesByQuoteId[idAsNumber]) || []
    const packagesMap = {
        null: packages[0],
        [packages[1]?.id]: packages[1],
        [packages[2]?.id]: packages[2],
        [packages[3]?.id]: packages[3],
    }
    const packageItemsByPackageId = useSelector((state) => state.quotes.packageItemsByPackageId)

    const materialsByLineItemId = useSelector((state) => state.quotes.materialsByLineItemId)

    const lineItemsOrderById: {
        [key: number]: number
    } = {}

    // Store sequential order of line items to sort
    // package items in the same order later
    const lineItemsById: {
        [key: number]: TLineItem
    } = {}
    lineItems.forEach((lineItem, index) => {
        lineItemsById[lineItem.id] = lineItem
        lineItemsOrderById[lineItem.id] = index
    })

    const materialsById: {
        [key: number]: TMaterial
    } = {}
    lineItems.forEach((lineItem) => {
        materialsByLineItemId[lineItem.id].forEach((material) => {
            materialsById[material.id] = material
        })
    })

    const feesById: {
        [key: number]: TFee
    } = {}
    fees.forEach((fee) => {
        feesById[fee.id] = fee
    })

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (quote === undefined) {
        return <div>Not found</div>
    }

    const chosenPackage = packagesMap[quote.chosen_package_id || "null"]
    const packageItems = packageItemsByPackageId[chosenPackage.id] || []
    // const packageItemsSorted = [...packageItems].sort((a, b) => {
    //     const itemA = lineItemsById[a.line_item_id] || feesById[a.fee_id]
    //     const itemB = lineItemsById[b.line_item_id] || feesById[b.fee_id]
    //     return itemA.order - itemB.order
    // })
    console.log({ packageItems, chosenPackage })
    const lineItemsPackageItems = packageItems
        .filter((item) => item.line_item_id)
        .filter(
            (item) =>
                lineItemsById[item.line_item_id].value || lineItemsById[item.line_item_id].flat_fee
        )
    lineItemsPackageItems.sort((a, b) => {
        const itemA = lineItemsById[a.line_item_id]
        const itemB = lineItemsById[b.line_item_id]
        return itemA.order - itemB.order
    })
    lineItemsPackageItems.sort(
        (a, b) => lineItemsOrderById[a.line_item_id] - lineItemsOrderById[b.line_item_id]
    )
    const feePackageItems = packageItems.filter((item) => item.fee_id)
    feePackageItems.sort((a, b) => {
        const itemA = feesById[a.fee_id]
        const itemB = feesById[b.fee_id]
        return itemA.order - itemB.order
    })

    return (
        <>
            <Helmet>
                <title>Quote Export</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Export Quote"
                    links={[
                        {
                            name: "Quotes",
                            href: PATHS.quotes.quotes.list(),
                        },
                        {
                            name: "Export",
                        },
                    ]}
                    action={
                        <ButtonGroup variant="contained" size="small">
                            <Button
                                onClick={() => {
                                    navigate(PATHS.quotes.quotes.details(idAsNumber))
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate(PATHS.quotes.quotes.view(idAsNumber))
                                }}
                            >
                                View
                            </Button>
                        </ButtonGroup>
                    }
                />
                <Stack direction="column" spacing={2}>
                    <Typography variant="h5">Quote Export</Typography>
                    <Typography>
                        Customer: {quote.sf_estimate?.customer.name} (
                        <Link
                            href={`https://admin.servicefusion.com/estimate/estimateView?id=${quote.sf_estimate?.url_id}`}
                            target="_blank"
                            rel="noopener"
                        >
                            estimate in ServiceFusion
                        </Link>
                        ). Click on a cell to copy its contents.
                    </Typography>
                    <Card sx={{ mt: 2 }}>
                        <TableContainer>
                            <Table className="table-export">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Service</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Rate</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lineItemsPackageItems.map((packageItem) => {
                                        const item = lineItemsById[packageItem.line_item_id]
                                        const material = materialsById[packageItem.material_id]
                                        return (
                                            <TableRow key={packageItem.id}>
                                                <TableCellCopyable
                                                    text={formatLineItemDescription({
                                                        text:
                                                            item.export_name ||
                                                            item.display_name ||
                                                            item.name,
                                                        lineItem: item,
                                                        packageItem,
                                                        materialsById,
                                                    })}
                                                />
                                                <TableCellCopyable
                                                    text={formatLineItemDescription({
                                                        text:
                                                            item.export_description ||
                                                            item.description,
                                                        lineItem: item,
                                                        packageItem,
                                                        materialsById,
                                                    })}
                                                />
                                                <TableCellCopyable
                                                    text={String(
                                                        (material?.total || 0) + item.flat_fee ||
                                                            item.value
                                                    )}
                                                    align="center"
                                                />
                                            </TableRow>
                                        )
                                    })}
                                    {feePackageItems.map((packageItem) => {
                                        const item = feesById[packageItem.fee_id]
                                        return (
                                            <TableRow key={packageItem.id}>
                                                <TableCellCopyable
                                                    text={item.display_name || item.name}
                                                />
                                                <TableCellCopyable text={item.description} />
                                                <TableCellCopyable
                                                    text={String(item.value)}
                                                    align="center"
                                                />
                                            </TableRow>
                                        )
                                    })}
                                    {chosenPackage.discount_value > 0 ? (
                                        <TableRow>
                                            <TableCellCopyable
                                                text={`${chosenPackage.name} Package`}
                                            />
                                            <TableCell />

                                            <TableCellCopyable
                                                text={String(-chosenPackage.discount_value)}
                                                align="center"
                                            />
                                        </TableRow>
                                    ) : null}
                                    {(quote.data.export_page?.extra_items || []).map(
                                        (item, index) => (
                                            <TableRow key={index}>
                                                <TableCellCopyable text={item} />
                                                <TableCell />
                                                <TableCell />
                                            </TableRow>
                                        )
                                    )}
                                    {quote.chosen_financing_option_id ? (
                                        <TableRow>
                                            <TableCellCopyable text="WiseTack Consumer Financing" />
                                            <TableCell />
                                            <TableCell />
                                        </TableRow>
                                    ) : null}
                                    <TableRow>
                                        <TableCell colSpan={2} align="right">
                                            <strong>Total</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>
                                                {calculatePackageTotal(
                                                    packageItems,
                                                    lineItemsById,
                                                    materialsById,
                                                    feesById
                                                ) - chosenPackage.discount_value}
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Stack>
            </Container>
        </>
    )
}
