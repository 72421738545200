import { createSlice, Dispatch } from "@reduxjs/toolkit"
import {
    TFinancingOptionTypesState,
    TFinancingOptionTypeCreate,
    TFinancingOptionTypeUpdate,
} from "src/@types/financing-option-type"
import {
    apiCreateFinancingOptionType,
    apiDeleteFinancingOptionType,
    apiFetchFinancingOptionType,
    apiUpdateFinancingOptionType,
    apiFetchFinancingOptionTypes,
} from "src/api/financing-option-types"

const initialState: TFinancingOptionTypesState = {
    financingOptionTypes: [],
    isLoading: false,
    error: null,
}

const slice = createSlice({
    name: "financingOptionTypes",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        fetchFinancingOptionTypesSuccess: (state, action) => {
            state.isLoading = false
            state.financingOptionTypes = action.payload
        },
        fetchFinancingOptionTypeSuccess: (state, action) => {
            state.isLoading = false
            const item = action.payload
            const index = state.financingOptionTypes.findIndex((itm) => itm.id === item.id)
            if (index !== -1) {
                state.financingOptionTypes[index] = item
            } else {
                state.financingOptionTypes.push(item)
            }
        },
        createFinancingOptionTypeSuccess: (state, action) => {
            state.isLoading = false
            state.financingOptionTypes.push(action.payload)
        },
        deleteFinancingOptionTypeSuccess: (state, action) => {
            state.isLoading = false
            state.financingOptionTypes = state.financingOptionTypes.filter(
                (item) => item.id !== action.payload
            )
        },
    },
})

export function getFinancingOptionTypes() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchFinancingOptionTypes()
        if (response.status === 200) {
            dispatch(slice.actions.fetchFinancingOptionTypesSuccess(response.data.results))
        }
        return response
    }
}

export function getFinancingOptionType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchFinancingOptionType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchFinancingOptionTypeSuccess(response.data))
        }
        return response
    }
}

export function createFinancingOptionType(data: TFinancingOptionTypeCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateFinancingOptionType(data)
        if (response.status === 201) {
            dispatch(slice.actions.createFinancingOptionTypeSuccess(response.data))
        }
        return response
    }
}

export function updateFinancingOptionType(id: number, data: TFinancingOptionTypeUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateFinancingOptionType(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchFinancingOptionTypeSuccess(response.data))
        }
        return response
    }
}

export function deleteFinancingOptionType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteFinancingOptionType(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteFinancingOptionTypeSuccess(id))
        }
        return response
    }
}

export default slice.reducer
