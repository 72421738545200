import { useState, useRef } from "react"
import { Autocomplete, TextField, CircularProgress, Link } from "@mui/material"
import { apiFetchEstimates } from "src/api/sf-estimates"
import { SFEstimate } from "src/@types/sf-estimate"

type Props = {
    onSelect: (estimate: SFEstimate | null) => void
    value?: SFEstimate | null
}

export default function EstimateSearchComponent({ onSelect, value }: Props) {
    // const [query, setQuery] = useState<string>('');
    const [v, setV] = useState<SFEstimate | null>(value || null)
    const [results, setResults] = useState<SFEstimate[]>([])
    const [loading, setLoading] = useState(false)
    const searchTimerRef = useRef<NodeJS.Timeout | null>(null)
    const minQueryLength = 5 // Minimum length of search string

    const handleSearch = async (q: string) => {
        if (q.length < minQueryLength) return

        setLoading(true)
        try {
            const response = await apiFetchEstimates({ search: q, orderBy: "-start_date" })
            setResults(response.data.results)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    return (
        <>
            <Autocomplete
                options={results}
                popupIcon={null}
                value={v}
                getOptionLabel={(option) =>
                    `${option.start_date || "no start date"} - ${option.number} - ${
                        option.customer.name
                    }`
                }
                loading={loading}
                onInputChange={(e, newValue) => {
                    // setQuery(newValue);
                    setResults([])

                    // Clear previous timer if exists
                    if (searchTimerRef.current) clearTimeout(searchTimerRef.current)

                    searchTimerRef.current = setTimeout(() => {
                        handleSearch(newValue)
                    }, 1500)
                }}
                onChange={(e, newValue) => {
                    // setQuery("")
                    setResults([])
                    // console.log(newValue)
                    onSelect(newValue)
                    setV(newValue)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Estimates by Number or Customer Name"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            {v && (
                <Link
                    href={`https://admin.servicefusion.com/estimate/estimateView?id=${v.url_id}`}
                    target="_blank"
                    rel="noopener"
                >
                    Open in ServiceFusion
                </Link>
            )}
        </>
    )
}
