import { InputAdornment, TextField } from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { TMeasurementField } from "src/@types/measurement"
import { updateMeasurementField } from "src/redux/slices/quotes"
import { useDispatch } from "src/redux/store"

type Props = {
    field: TMeasurementField
    // onUpdate: (value: number | null) => void
}

export default function MeasurementField({ field }: Props) {
    const [value, setValue] = useState<string | number | null>(field.value)
    const [isChanged, setIsChanged] = useState(false)

    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        setValue(field.value)
    }, [field.value])

    const onUpdate = async (v: number | null) => {
        if (!isChanged) return
        const response = await dispatch(updateMeasurementField(field.id, { value: v }))
        if (response.status === 200) {
            enqueueSnackbar("Measurement updated", { variant: "success" })
        } else {
            enqueueSnackbar(`Error`, { variant: "error" })
        }
        setIsChanged(false)
    }

    return (
        <TextField
            name={field.name}
            type="number"
            onBlur={(e) =>
                onUpdate([null, ""].includes(e.target.value) ? null : Number(e.target.value))
            }
            inputMode="numeric"
            autoComplete="off"
            value={value ?? ""}
            onChange={(e) => {
                setValue(e.target.value)
                setIsChanged(true)
            }}
            label={field.name}
            inputProps={{ step: 1, pattern: "[0-9]*", min: 0 }}
            InputProps={{
                endAdornment: <InputAdornment position="end">{field.unit}</InputAdornment>,
            }}
        />
    )
}
