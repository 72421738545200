import { MaterialTypeCreate, MaterialTypeUpdate } from "src/@types/material-type"
import axios from "../utils/axios"

const apiFetchMaterialTypes = () => axios.get("/api/v1/quotes/material-types/")

const apiFetchMaterialType = (id: number) => axios.get(`/api/v1/quotes/material-types/${id}/`)

const apiUpdateMaterialType = (id: number, changes: MaterialTypeUpdate) =>
    axios.patch(`/api/v1/quotes/material-types/${id}/`, changes)

const apiCreateMaterialType = (payload: MaterialTypeCreate) =>
    axios.post(`/api/v1/quotes/material-types/`, payload)

const apiCopyMaterialType = (id: number) => axios.post(`/api/v1/quotes/material-types/${id}/copy/`)

const apiDeleteMaterialType = (id: number) => axios.delete(`/api/v1/quotes/material-types/${id}/`)

export {
    apiUpdateMaterialType,
    apiFetchMaterialType,
    apiFetchMaterialTypes,
    apiCreateMaterialType,
    apiDeleteMaterialType,
    apiCopyMaterialType,
}
