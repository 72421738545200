// i18n
import './locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import { LocalizationProvider } from '@mui/x-date-pickers'
import us from 'date-fns/locale/en-US'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { CssBaseline } from '@mui/material';
import { Provider as ReduxProvider } from 'react-redux'
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';
import { AuthProvider } from './auth/JwtContext';
import { store } from './redux/store';

const queryClient = new QueryClient()

// ----------------------------------------------------------------------

export default function App() {
  if (us.options) us.options.weekStartsOn = 1

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
      <HelmetProvider>
        <SettingsProvider>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={us}>
            <ScrollToTop />
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <ThemeLocalization>
                    <CssBaseline />
                    <SnackbarProvider>
                      <Router />
                    </SnackbarProvider>
                  </ThemeLocalization>
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
            </LocalizationProvider>
          </BrowserRouter>
        </SettingsProvider>
      </HelmetProvider>
      </ReduxProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}
