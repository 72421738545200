import { TMeasurementFieldUpdate } from "src/@types/measurement"
import axios from "../utils/axios"

const apiUpdateMeasurementField = async (id: number, changes: TMeasurementFieldUpdate) => {
    try {
        return await axios.patch(`/api/v1/quotes/measurement-fields/${id}/`, changes)
    } catch (error) {
        return error.response
    }
}

export { apiUpdateMeasurementField }
