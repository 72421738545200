import { PackageTypeCreate, PackageTypeUpdate } from "src/@types/package-type"
import axios from "../utils/axios"

const apiCreatePackageType = (data: PackageTypeCreate) =>
    axios.post(`/api/v1/quotes/package-types/`, data)

const apiFetchPackageTypes = () => axios.get(`/api/v1/quotes/package-types/`)

const apiFetchPackageType = (id: number) => axios.get(`/api/v1/quotes/package-types/${id}/`)

const apiUpdatePackageType = (id: number, data: PackageTypeUpdate) =>
    axios.patch(`/api/v1/quotes/package-types/${id}/`, data)

const apiDeletePackageType = (id: number) => axios.delete(`/api/v1/quotes/package-types/${id}/`)

export {
    apiCreatePackageType,
    apiFetchPackageTypes,
    apiFetchPackageType,
    apiUpdatePackageType,
    apiDeletePackageType,
}
