import { useNavigate } from "react-router"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useCallback, useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, Stack } from "@mui/material"
import { RHFTextField } from "src/components/hook-form"
import { PATHS } from "src/routes/paths"
import { TQuoteTemplateCreate, TQuoteTemplate } from "src/@types/quote-template"
import JsonEditorComponent from "src/components/json-editor/editorV2"
import CategorySelectTree from "../category-select"

type QuoteTemplateFormData = {
    id: number | null
    name: string
    description: string
    notes: string
    category_id: number | null
    header_top: string
    header_order_details: string
    header_service: string
    header_option_1: string
    header_option_2: string
    header_option_3: string
    header_consultant_signature: string
    text_under_package_options: string
    text_under_customer_signature: string
    top_right_logo_url: string
    bottom_left_logo_url: string
    bottom_left_logo_2_url: string
    displayOptions: (1 | 2 | 3)[]
    max_display_options: number
    export_page_extra_items: string
    variables: {
        name: string
        description: string
        slug: string
        value: string | number | boolean | null
    }[]
}

type Props = {
    initialData?: TQuoteTemplate
    onSubmit: (data: TQuoteTemplateCreate) => void
}

export default function QuoteTemplateEditForm({ onSubmit, initialData }: Props) {
    const navigate = useNavigate()

    const defaultValues = {
        id: null,
        name: "",
        description: "",
        notes: "",
        category_id: null,
        header_top: "",
        header_order_details: "",
        header_service: "",
        header_option_1: "",
        header_option_2: "",
        header_option_3: "",
        header_consultant_signature: "",
        text_under_package_options: "",
        text_under_customer_signature: "",
        top_right_logo_url: "",
        bottom_left_logo_url: "",
        bottom_left_logo_2_url: "",
        displayOptions: [],
        max_display_options: 1,
        export_page_extra_items: "",
        variables: [{ name: "", description: "", slug: "", value: "" }],
    }

    const schema = Yup.object().shape({
        // description: Yup.string().required("Description is required"),
        name: Yup.string().required("Name is required"),
        category_id: Yup.number().required("Category is required"),
    })

    const methods = useForm<QuoteTemplateFormData>({
        resolver: yupResolver(schema),
        defaultValues,
    })

    useEffect(() => {
        // console.log("initialData", initialData)
        if (initialData) {
            const data = {
                id: initialData.id,
                name: initialData.name,
                description: initialData.description,
                notes: initialData.notes,
                category_id: initialData.category_id,
                header_top: initialData.data.print_template?.fields?.header_top || "",
                header_order_details:
                    initialData.data.print_template?.fields?.header_order_details || "",
                header_service: initialData.data.print_template?.fields?.header_service || "",
                header_option_1: initialData.data.print_template?.fields?.header_option_1 || "",
                header_option_2: initialData.data.print_template?.fields?.header_option_2 || "",
                header_option_3: initialData.data.print_template?.fields?.header_option_3 || "",
                header_consultant_signature:
                    initialData.data.print_template?.fields?.header_consultant_signature || "",
                text_under_package_options:
                    initialData.data.print_template?.fields?.text_under_package_options || "",
                text_under_customer_signature:
                    initialData.data.print_template?.fields?.text_under_customer_signature || "",
                top_right_logo_url:
                    initialData.data.print_template?.fields?.top_right_logo_url || "",
                bottom_left_logo_url:
                    initialData.data.print_template?.fields?.bottom_left_logo_url || "",
                bottom_left_logo_2_url:
                    initialData.data.print_template?.fields?.bottom_left_logo_2_url || "",
                displayOptions: initialData.data.print_template?.display_options || [],
                max_display_options: initialData.data.print_template?.max_display_options || 1,
                export_page_extra_items: initialData.data.export_page?.extra_items?.join(",") || "",
                variables: [
                    ...(initialData.data.variables || []),
                    { name: "", description: "", slug: "", value: "" },
                ],
            }
            methods.reset(data)
        }
    }, [initialData, methods])

    const handleSubmit = (data: QuoteTemplateFormData) => {
        if (data.category_id === null) return

        // const displayOptions = []
        // if (data.header_option_1 && data.header_option_1 !== "-") displayOptions.push(1)
        // if (data.header_option_2 && data.header_option_2 !== "-") displayOptions.push(2)
        // if (data.header_option_3 && data.header_option_3 !== "-") displayOptions.push(3)

        const payload = {
            id: data.id,
            name: data.name,
            notes: data.notes,
            description: data.description,
            category_id: data.category_id,
            data: {
                print_template: {
                    fields: {
                        header_top: data.header_top,
                        header_order_details: data.header_order_details,
                        header_service: data.header_service,
                        header_option_1: data.header_option_1,
                        header_option_2: data.header_option_2,
                        header_option_3: data.header_option_3,
                        header_consultant_signature: data.header_consultant_signature,
                        text_under_package_options: data.text_under_package_options,
                        text_under_customer_signature: data.text_under_customer_signature,
                        top_right_logo_url: data.top_right_logo_url,
                        bottom_left_logo_url: data.bottom_left_logo_url,
                        bottom_left_logo_2_url: data.bottom_left_logo_2_url,
                    },
                    display_options: data.displayOptions,
                    max_display_options: data.max_display_options,
                },
                export_page: {
                    extra_items: data.export_page_extra_items
                        ? data.export_page_extra_items.split(",").map((item) => item.trim())
                        : [],
                },
                variables: data.variables.filter((item) => item.name),
            },
        }
        onSubmit(payload)
    }

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Stack direction="column" spacing={2} flex={1}>
                            <RHFTextField name="name" label="Name" />
                            <RHFTextField
                                name="description"
                                label="Description"
                                helperText="NOT copied to a new quote"
                            />
                            <RHFTextField
                                name="notes"
                                label="Notes"
                                helperText="Copied to a new quote"
                                multiline
                                rows={4}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }}>
                            {/* {initialData ? (
                                <Typography variant="body1">
                                    <b>Category:</b> {initialData.category?.name || "No Category"}
                                </Typography>
                            ) : ( */}
                            <CategorySelectTree
                                current={initialData?.category_id?.toString() || "none"}
                                onChange={(value) => {
                                    console.log(value)
                                    methods.setValue("category_id", value)
                                }}
                                disable={[]}
                                emptyLabel="No Category"
                            />
                            {/* )} */}
                        </Stack>
                    </Stack>
                    <Stack spacing={2} direction="column" justifyContent="flex-end" sx={{ mt: 2 }}>
                        <RHFTextField name="header_top" label="Header Top" />
                        <RHFTextField name="header_order_details" label="Header Order Details" />
                        <RHFTextField name="header_service" label="Header Service" />
                        <RHFTextField name="header_option_1" label="Header Option 1" />
                        <RHFTextField
                            name="header_option_2"
                            label="Header Option 2"
                            helperText="Enter '-' to disable this column"
                        />
                        <RHFTextField
                            name="header_option_3"
                            label="Header Option 3"
                            helperText="Enter '-' to disable this column"
                        />
                        <RHFTextField
                            name="header_consultant_signature"
                            label="Header Consultant Signature"
                        />
                        <RHFTextField
                            name="text_under_package_options"
                            label="Text Under Package Options"
                            multiline
                            rows={4}
                        />
                        <RHFTextField
                            name="text_under_customer_signature"
                            label="Text Under Customer Signature"
                            multiline
                            rows={4}
                        />
                        <RHFTextField name="top_right_logo_url" label="Top Right Logo URL" />
                        <RHFTextField name="bottom_left_logo_url" label="Bottom Left Logo URL" />
                        <RHFTextField
                            name="bottom_left_logo_2_url"
                            label="Bottom Left Logo 2 URL"
                            helperText="Enter '-' to hide"
                        />
                        <RHFTextField
                            name="export_page_extra_items"
                            label="Export Page Extra Items"
                            helperText="Additional items on the Export page; comma separated; e.g. Lease Details for HL jobs"
                        />
                        {/* <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }} mt={1}> */}
                        <JsonEditorComponent
                            value={methods.watch()}
                            // @ts-ignore
                            onChange={useCallback((value) => methods.reset(value), [methods])}
                            // @ts-ignore
                            // onChange={(value) => methods.setValue("data", value)}
                        />
                        {/* </Stack> */}
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.quoteTemplates.list())}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={methods.handleSubmit(handleSubmit)}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </Button>
            </Stack>
        </FormProvider>
    )
}
