import { TFee } from "src/@types/fee"
import { TLineItem } from "src/@types/lineItem"
import { TMaterial } from "src/@types/material"
import { TPackageItem } from "src/@types/package"

const calculatePackageTotal = (
    packageItems: TPackageItem[],
    lineItemsById: { [key: number]: TLineItem },
    materialsById: { [key: number]: TMaterial },
    feesById: { [key: number]: TFee },
    modifier: number = 0
) => {
    let total = 0
    let lineItemsCount = 0
    packageItems.forEach((packageItem) => {
        if (packageItem.line_item_id) {
            const lineItem = lineItemsById[packageItem.line_item_id]
            if (lineItem && lineItem.data.allow_flat_fee && lineItem.flat_fee) {
                total += lineItem.flat_fee
                lineItemsCount += 1
            }
        }
        if (packageItem.material_id) {
            total += materialsById[packageItem.material_id]?.total || 0
            lineItemsCount += 1
        }
        if (packageItem.fee_id) {
            total += feesById[packageItem.fee_id]?.value || 0
        }
    })
    // Return 0 if there are no line items regardless of fees
    if (lineItemsCount === 0) return 0
    return total + modifier
}

export { calculatePackageTotal }
