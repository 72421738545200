// form
import { useFormContext, Controller } from "react-hook-form"
// @mui
import { TextField, TextFieldProps } from "@mui/material"

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
    name: string
}

export default function RHFTextField({ name, helperText, ...other }: Props) {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    autoComplete="off"
                    value={field.value}
                    // value={typeof field.value === "number" && field.value === 0 ? "" : field.value}
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    {...other}
                />
            )}
        />
    )
}
