// routes
import SchemaIcon from "@mui/icons-material/Schema"
import FenceIcon from "@mui/icons-material/Fence"
import StraightenIcon from "@mui/icons-material/Straighten"
import OtherHousesIcon from "@mui/icons-material/OtherHouses"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import RequestQuoteIcon from "@mui/icons-material/RequestQuote"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import PercentIcon from "@mui/icons-material/Percent"
import GroupsIcon from "@mui/icons-material/Groups"
import ChecklistIcon from "@mui/icons-material/Checklist"
import PersonIcon from "@mui/icons-material/Person"
import InventoryIcon from "@mui/icons-material/Inventory"
import { Permissions } from "src/@types/security"
import { PATHS } from "../../../routes/paths"
// components

// ----------------------------------------------------------------------

// const icon = (name: string) => (
//     <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
// )

// const ICONS = {
//     user: icon("ic_user"),
//     ecommerce: icon("ic_ecommerce"),
//     analytics: icon("ic_analytics"),
//     dashboard: icon("ic_dashboard"),
// }

const navConfig = [
    // Quotes
    // ----------------------------------------------------------------------
    {
        subheader: "Main Menu",
        items: [{ title: "Quotes", path: PATHS.quotes.quotes.list(), icon: <RequestQuoteIcon /> }],
    },
    {
        subheader: "Types",
        items: [
            {
                title: "Measurements",
                path: PATHS.quotes.measurementTypes.list(),
                icon: <StraightenIcon />,
                permissionRequired: Permissions.MEASUREMENT_TYPE_UPDATE,
            },
            {
                title: "Materials",
                path: PATHS.quotes.materialTypes.list(),
                icon: <FenceIcon />,
                // UPDATE as VIEW is required for most users for other endpoints
                permissionRequired: Permissions.MATERIAL_TYPE_UPDATE,
            },
            {
                title: "Services",
                path: PATHS.quotes.lineItemTypes.list(),
                icon: <OtherHousesIcon />,
                // UPDATE as VIEW is required for most users for other endpoints
                permissionRequired: Permissions.LINE_ITEM_TYPE_UPDATE,
            },
            {
                title: "Fees",
                path: PATHS.quotes.feeTypes.list(),
                icon: <AttachMoneyIcon />,
                permissionRequired: Permissions.FEE_TYPE_UPDATE,
            },
            {
                title: "Packages",
                path: PATHS.quotes.packageTypes.list(),
                icon: <ChecklistIcon />,
                permissionRequired: Permissions.PACKAGE_TYPE_VIEW,
            },
        ],
    },
    {
        subheader: "Settings",
        items: [
            {
                title: "Categories",
                path: PATHS.quotes.categories.list(),
                icon: <SchemaIcon />,
                permissionRequired: Permissions.CATEGORY_UPDATE,
            },
            {
                title: "Quote Templates",
                path: PATHS.quotes.quoteTemplates.list(),
                icon: <ContentCopyIcon />,
                permissionRequired: Permissions.QUOTE_TEMPLATE_UPDATE,
            },
            {
                title: "Containers",
                path: PATHS.quotes.containers.list(),
                icon: <InventoryIcon />,
                permissionRequired: Permissions.CONTAINER_UPDATE,
            },
            {
                title: "Coefficients",
                path: PATHS.quotes.coefficients.list(),
                icon: <PercentIcon />,
                permissionRequired: Permissions.CONTAINER_UPDATE,
            },
            {
                title: "Users",
                path: PATHS.auth.users.list(),
                icon: <PersonIcon />,
                permissionRequired: Permissions.USER_UPDATE,
            },
            {
                title: "Groups",
                path: PATHS.auth.groups.list(),
                icon: <GroupsIcon />,
                permissionRequired: Permissions.GROUP_VIEW,
            },
        ],
    },
    // GENERAL
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'Data',
    //   items: [
    //     { title: 'Jobs', path: PATHS.data.jobs.list },
    //     // { title: 'Two', path: PATH_DASHBOARD.two, icon: ICONS.ecommerce },
    //     // { title: 'Three', path: PATH_DASHBOARD.three, icon: ICONS.analytics },
    //   ],
    // },
    // {
    //   subheader: 'Payroll',
    //   items: [
    //     { title: 'Payroll Runs', path: PATHS.payroll.runs.list },
    //     { title: 'Pay Types', path: PATHS.payroll.payTypes.list() },
    //     // { title: 'Two', path: PATH_DASHBOARD.two, icon: ICONS.ecommerce },
    //     // { title: 'Three', path: PATH_DASHBOARD.three, icon: ICONS.analytics },
    //   ],
    // },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'management',
    //   items: [
    //     {
    //       title: 'user',
    //       path: PATH_DASHBOARD.user.root,
    //       icon: ICONS.user,
    //       children: [
    //         { title: 'Four', path: PATH_DASHBOARD.user.four },
    //         { title: 'Five', path: PATH_DASHBOARD.user.five },
    //         { title: 'Six', path: PATH_DASHBOARD.user.six },
    //       ],
    //     },
    //   ],
    // },
]

export default navConfig
