import { Container } from "@mui/material"
import { useSnackbar } from "notistack"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import UserEditForm from "src/forms/user-edit"
import { createUser } from "src/redux/slices/groups"
import { useDispatch } from "src/redux/store"
import { PATHS } from "src/routes/paths"

type FormValues = {
    first_name: string
    last_name: string
    email: string
    username: string
    is_active: boolean
}

export default function UserCreatePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = async (data: FormValues) => {
        try {
            const response = await dispatch(createUser(data))
            if (response.status === 201) {
                enqueueSnackbar("User created successfully", { variant: "success" })
                navigate(PATHS.auth.users.details(response.data.id))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create User</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Create a User"
                    links={[
                        {
                            name: "Security",
                        },
                        {
                            name: "Users",
                            href: PATHS.auth.users.list(),
                        },
                        {
                            name: "Create",
                        },
                    ]}
                />
                <UserEditForm onSubmit={onSubmit} />
            </Container>
        </>
    )
}
