import { Unit } from "src/@types/units"
import * as Yup from "yup"
import { useNavigate } from "react-router"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, MenuItem, Stack } from "@mui/material"
import { RHFSelect, RHFTextField } from "src/components/hook-form"
import { PATHS } from "src/routes/paths"
import JsonEditorComponent from "src/components/json-editor"

type MeasurementTypeData = {
    id: number | null
    name: string
    description: string
    slug: string
    base_unit: Unit
    data: {
        [key: string]: any
    }
    formula: string
}

type MeasurementTypeFormProps = {
    initialData?: MeasurementTypeData
    onSubmit: (data: MeasurementTypeData) => void
}

export default function MeasurementTypeForm({ initialData, onSubmit }: MeasurementTypeFormProps) {
    const navigate = useNavigate()

    const [editorData, setEditorData] = useState(initialData?.data || {})

    const defaultValues = {
        id: null,
        name: "",
        description: "",
        base_unit: Unit.FT,
        data: {},
        formula: "",
        slug: "",
    }

    const MeasurementTypeSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        slug: Yup.string().required("Slug is required"),
        base_unit: Yup.string().oneOf(Object.values(Unit)).required("Base Unit is required"),
    })

    const methods = useForm<MeasurementTypeData>({
        resolver: yupResolver(MeasurementTypeSchema),
        defaultValues,
    })

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData)
            setEditorData(initialData.data)
        }
    }, [initialData, methods])

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        <Stack direction="column" spacing={2} flex={1}>
                            <RHFTextField name="name" label="Name" required />
                            <RHFTextField name="description" label="Description" />
                            <RHFTextField name="slug" label="Slug" required />
                            <RHFTextField name="formula" label="Formula" required />
                            <RHFSelect name="base_unit" label="Base Unit" required>
                                {Object.values(Unit).map((unit) => (
                                    <MenuItem key={unit} value={unit}>
                                        {unit}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing={2}
                            // justifyContent="space-between"
                            sx={{ mt: 2 }}
                            flex={1}
                        >
                            <JsonEditorComponent
                                value={editorData}
                                onChange={(value) => methods.setValue("data", value)}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.measurementTypes.list())}
                >
                    Cancel
                </Button>
                <Button size="small" variant="contained" onClick={methods.handleSubmit(onSubmit)}>
                    {initialData === undefined ? "Create" : "Save"}
                </Button>
            </Stack>
        </FormProvider>
    )
}
