import { Container } from "@mui/material"
import { useSnackbar } from "notistack"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import FeeTypeForm from "src/forms/fee-type-edit"
import { createFeeType } from "src/redux/slices/fee-types"
import { useDispatch } from "src/redux/store"
import { PATHS } from "src/routes/paths"

type FormValues = {
    name: string
    display_name: string
    description: string
    value: number | null
    data: {
        [key: string]: any
    }
}

export default function FeeTypeCreatePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = async (data: FormValues) => {
        try {
            const response = await dispatch(createFeeType(data))
            if (response.status === 201) {
                enqueueSnackbar("Fee type created successfully", { variant: "success" })
                navigate(PATHS.quotes.feeTypes.list())
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create Fee Type</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Create a Fee Type"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Fee Types",
                            href: PATHS.quotes.feeTypes.list(),
                        },
                        {
                            name: "Create",
                        },
                    ]}
                />
                <FeeTypeForm onSubmit={onSubmit} />
            </Container>
        </>
    )
}
