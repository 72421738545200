// import { Checkbox } from "@mui/material"
// import CheckIcon from '@mui/icons-material/Check'

type Props = {
    state: boolean
    bgColor?: string
    onChange: (value: boolean) => void
    disabled?: boolean
}

export default function CheckboxCell({ state, bgColor = "white", onChange, disabled }: Props) {
    return (
        <td
            style={{
                backgroundColor: bgColor,
                // width: "48x",
                // padding: "0px",
                // position: "relative",
                textAlign: "center",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
            }}
        >
            {/* <Checkbox
                    checked={state}
                    disableRipple
                    onClick={(e) => onChange(!state)}
                    // onChange={(e) => {console.log(e); onChange(e.target.checked)}}
                /> */}
            <input
                type="checkbox"
                checked={state}
                onChange={(e) => {
                    if (disabled) return
                    onChange(e.target.checked)
                }}
                style={{
                    cursor: disabled ? "not-allowed" : "pointer",
                }}
                // disabled={disabled}
            />
        </td>
    )
}
