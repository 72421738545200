import { TLineItem } from "src/@types/lineItem"
import { TMaterial } from "src/@types/material"
import { TPackageItem, TPackage } from "src/@types/package"
import { formatLineItemDescription, getLineItemName } from "src/services/line-item-service"
import { useDispatch } from "src/redux/store"
import {
    addPackageItem,
    deletePackageItem,
    updateLineItem,
    updateMaterial,
} from "src/redux/slices/quotes"
// import ChooseProductCell from "./choose-option-cell"
// import EditNumberCell from "./edit-number-cell"
import EditTextCell from "./edit-text-cell"
import CheckboxCell from "./checkbox-cell"
import EditNumbersCell from "./edit-numbers-cell"

type Props = {
    lineItem: TLineItem
    packages: TPackage[]
    packageItems: TPackageItem[]
    materials: TMaterial[]
    displayOptions: (1 | 2 | 3)[]
}

export default function LineItemOneOptionRow({
    lineItem,
    packages,
    packageItems,
    materials,
    displayOptions,
}: Props) {
    const dispatch = useDispatch()

    const material = materials[0]

    const packageAlaCarte = packages[0]
    const package1 = packages[1]
    const package2 = packages[2]
    const package3 = packages[3]

    const packageAlaCarteItem =
        packageItems.filter((packageItem) => packageItem.package_id === packageAlaCarte.id)[0] ||
        null
    const package1Item =
        packageItems.filter((packageItem) => packageItem.package_id === package1.id)[0] || null
    const package2Item =
        packageItems.filter((packageItem) => packageItem.package_id === package2.id)[0] || null
    const package3Item =
        packageItems.filter((packageItem) => packageItem.package_id === package3?.id)[0] || null

    const onChangeOption = async (packageId: number, state: boolean) => {
        if (!state) {
            const packageItem = packageItems.filter((pi) => pi.package_id === packageId)[0]
            if (packageItem) {
                dispatch(deletePackageItem(packageItem))
            }
            return
        }

        const response = dispatch(
            addPackageItem({
                package_id: packageId,
                line_item_id: lineItem.id,
                material_id: material?.id,
            })
        )
        console.log(response)
    }

    const onUpdateMaterialTotal = (materialId: number | null, total: number) => {
        if (!materialId) return
        const response = dispatch(updateMaterial(materialId, { total }))
        console.log(response)
    }

    const lineItemValues: any = []
    if (lineItem.data.allow_flat_fee) {
        lineItemValues.push({
            value: lineItem.flat_fee,
            onChange: (value: number) => {
                dispatch(updateLineItem(lineItem.id, { flat_fee: value }))
            },
            name: "Flat Fee",
        })
    }
    if (material?.id) {
        lineItemValues.push({
            value: material.total,
            onChange: (value: number) => {
                onUpdateMaterialTotal(material.id, value)
            },
            name: material.display_name || material.name,
        })
    }

    // console.log("lineItemValues", lineItemValues)
    // console.log("material", material)

    return (
        <tr key={lineItem.id}>
            <CheckboxCell
                state={Boolean(package1Item)}
                onChange={(value) => {
                    onChangeOption(package1.id, value)
                }}
                bgColor="orange"
                // disabled={material.data.packages?.restrict_include_change?.includes(1)}
            />
            <CheckboxCell
                state={Boolean(package2Item)}
                onChange={(value) => {
                    onChangeOption(package2.id, value)
                }}
                bgColor="yellow"
                // disabled={mate.data.packages?.restrict_include_change?.includes(2)}
            />
            <CheckboxCell
                state={Boolean(package3Item)}
                onChange={(value) => {
                    onChangeOption(package3.id, value)
                }}
                bgColor="LawnGreen"
                // disabled={fee.data.packages?.restrict_include_change?.includes(3)}
            />
            <EditTextCell
                state={getLineItemName(lineItem)}
                // formatter={(state) => formatLineItemDescription({ text: state, lineItem })}
                onChange={(value) => {
                    dispatch(updateLineItem(lineItem.id, { display_name: value }))
                }}
                name="Service Name"
                textStyles={{ fontWeight: 700 }}
            />
            {/* <td className="text-regular">{getLineItemName(lineItem)}</td> */}
            <EditTextCell
                state={lineItem.description}
                formatter={(state) =>
                    formatLineItemDescription({ text: state, lineItem, material })
                }
                onChange={(value) => {
                    dispatch(updateLineItem(lineItem.id, { description: value }))
                }}
                name={getLineItemName(lineItem)}
                // textStyles={{ fontSize: 8 }}
            />
            <EditNumbersCell
                state={(material?.total || 0) + (lineItem.flat_fee || 0) || null}
                values={lineItemValues}
                colSpan={displayOptions.length}
                name={getLineItemName(lineItem)}
                cellStyle={{ fontWeight: 700 }}
            />
            <CheckboxCell
                state={Boolean(packageAlaCarteItem)}
                onChange={(value) => {
                    onChangeOption(packageAlaCarte.id, value)
                }}
                // disabled={mate.data.packages?.restrict_include_change?.includes(0)}
            />
        </tr>
    )
}
