import { CircularProgress, Container } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
// import AddIcon from "@mui/icons-material/Add"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { useSnackbar } from "notistack"
import ContainerForm from "src/forms/container-edit"
import { getContainer, updateContainer } from "src/redux/slices/containers"
import { TContainerUpdate } from "src/@types/containers"

export default function CoefficientDetailsPage() {
    const { id } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()

    useEffect(() => {
        const getContainerAsync = async () => {
            if (idAsNumber) {
                await dispatch(getContainer(idAsNumber))
                setIsLoading(false)
            }
        }
        getContainerAsync()
    }, [dispatch, idAsNumber])

    const container = useSelector((state) =>
        state.containers.containers.find(
            (item) => item.id === idAsNumber && item.type === "coefficient"
        )
    )

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (container === undefined) {
        return <div>Not found</div>
    }

    const onSubmit = async (data: TContainerUpdate) => {
        try {
            console.log(data)
            await dispatch(updateContainer(idAsNumber, data))
            enqueueSnackbar("Container updated successfully", {
                variant: "success",
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Coefficient {container.name || ""}</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading={`Coefficient ${container.name}`}
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Coefficients",
                            href: PATHS.quotes.coefficients.list(),
                        },
                        {
                            name: container.name,
                        },
                    ]}
                />
                <ContainerForm initialData={container} onSubmit={onSubmit} type="coefficient" />
            </Container>
        </>
    )
}
