import { createSlice, Dispatch } from "@reduxjs/toolkit"
import {
    MaterialTypeCreate,
    MaterialTypePriceCreate,
    MaterialTypePriceUpdate,
    MaterialsTypeState,
    MaterialTypeUpdate,
} from "src/@types/material-type"
import {
    apiCreateMaterialTypePrice,
    apiDeleteMaterialTypePrice,
    apiUpdateMaterialTypePrice,
} from "src/api/material-type-prices"
import {
    apiCreateMaterialType,
    apiFetchMaterialType,
    apiFetchMaterialTypes,
    apiUpdateMaterialType,
    apiDeleteMaterialType,
    apiCopyMaterialType,
} from "src/api/material-types"

const initialState: MaterialsTypeState = {
    isLoading: false,
    materialTypes: [],
    pricesByMaterialTypeId: {},
}

const slice = createSlice({
    name: "materialTypes",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchMaterialTypesSuccess(state, action) {
            state.isLoading = false
            state.materialTypes = action.payload
            state.materialTypes.forEach((materialType) => {
                state.pricesByMaterialTypeId[materialType.id] = materialType.prices
            })
        },
        fetchMaterialTypeSuccess(state, action) {
            state.isLoading = false
            const index = state.materialTypes.findIndex((item) => item.id === action.payload.id)
            if (index !== -1) {
                state.materialTypes[index] = action.payload
            } else {
                state.materialTypes.push(action.payload)
            }
            state.pricesByMaterialTypeId[action.payload.id] = action.payload.prices
        },
        createMaterialTypePriceSuccess(state, action) {
            state.isLoading = false
            state.pricesByMaterialTypeId[action.payload.type_id].push(action.payload)
        },
        deleteMaterialPriceTypeSuccess(state, action) {
            state.isLoading = false
            state.pricesByMaterialTypeId[action.payload.materialId] = state.pricesByMaterialTypeId[
                action.payload.materialId
            ].filter((item) => item.id !== action.payload.id)
        },
        updateMaterialPriceTypeSuccess(state, action) {
            state.isLoading = false
            const index = state.pricesByMaterialTypeId[action.payload.type_id].findIndex(
                (item) => item.id === action.payload.id
            )
            if (index !== -1) {
                state.pricesByMaterialTypeId[action.payload.type_id][index] = action.payload
            }
        },
        deleteMaterialSuccess(state, action) {
            state.isLoading = false
            state.materialTypes = state.materialTypes.filter((item) => item.id !== action.payload)
        },
    },
})

export function getMaterialTypes() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchMaterialTypes()
        if (response.status === 200) {
            dispatch(slice.actions.fetchMaterialTypesSuccess(response.data.results))
        }
        return response
    }
}

export function getMaterialType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchMaterialType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchMaterialTypeSuccess(response.data))
        }
        return response
    }
}

export function createMaterialType(data: MaterialTypeCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateMaterialType(data)
        if (response.status === 201) {
            dispatch(slice.actions.fetchMaterialTypesSuccess([response.data]))
        }
        return response
    }
}

export function copyMaterialType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCopyMaterialType(id)
        if (response.status === 201) {
            dispatch(slice.actions.fetchMaterialTypesSuccess([response.data]))
        }
        return response
    }
}

export function updateMaterialType(id: number, data: MaterialTypeUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateMaterialType(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchMaterialTypesSuccess([response.data]))
        }
        return response
    }
}

export function createMaterialTypePrice(data: MaterialTypePriceCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateMaterialTypePrice(data)
        if (response.status === 201) {
            dispatch(slice.actions.createMaterialTypePriceSuccess(response.data))
        }
        return response
    }
}

export function deleteMaterialTypePrice(id: number, materialId: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteMaterialTypePrice(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteMaterialPriceTypeSuccess({ id, materialId }))
        }
        return response
    }
}

export function updateMaterialTypePrice(id: number, data: MaterialTypePriceUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateMaterialTypePrice(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.updateMaterialPriceTypeSuccess(response.data))
        }
        return response
    }
}

export function deleteMaterialType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteMaterialType(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteMaterialSuccess(id))
        }
        return response
    }
}

export default slice.reducer
