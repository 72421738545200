import { CustomField, CustomFieldValue } from "src/@types/custom-field"
import MultiSelectChipsCustomField from "./chips-multiselect"
import DropdownSingleCustomField from "./dropdown-single"

interface CustomFieldProps {
    handleSave: (values: CustomFieldValue[]) => void
    field: CustomField
}

const CustomFieldComponent = ({ handleSave, field }: CustomFieldProps) => {
    if (field.type === "multiselect") return MultiSelectChipsCustomField({ handleSave, field })
    if (field.type === "dropdown-single") return DropdownSingleCustomField({ handleSave, field })

    return null
}

export default CustomFieldComponent
