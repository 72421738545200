// @mui
import { Stack, Box } from "@mui/material"
// config
import { NAV } from "../../../config-global"
// utils
import { hideScrollbarX } from "../../../utils/cssStyles"
// components
// import Logo from "../../../components/logo"
import { NavSectionMini } from "../../../components/nav-section"
//
import navConfig from "./config-navigation"
import NavToggleButton from "./NavToggleButton"
// import AccountPopover from "../header/AccountPopover"

// ----------------------------------------------------------------------

export default function NavMini() {
    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_DASHBOARD_MINI },
            }}
        >
            <NavToggleButton
                sx={{
                    top: 22,
                    left: NAV.W_DASHBOARD_MINI - 12,
                }}
            />

            <Stack
                sx={{
                    pb: 2,
                    pt: 1,
                    height: 1,
                    position: "fixed",
                    width: NAV.W_DASHBOARD_MINI,
                    borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
                    ...hideScrollbarX,
                }}
                justifyContent="space-between"
                alignItems="center"
            >
                {/* <Logo sx={{ mx: 'auto', my: 2 }} /> */}

                <NavSectionMini data={navConfig} />
                {/* <AccountPopover /> */}
            </Stack>
        </Box>
    )
}
