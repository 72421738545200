import { TUserCreate, TUserUpdate } from "src/@types/user"
import axios from "../utils/axios"

const apiFetchUsers = () => axios.get("/api/v1/auth/users/")

const apiFetchUser = (id: number) => axios.get(`/api/v1/auth/users/${id}/`)

const apiUpdateUser = (id: number, data: TUserUpdate) =>
    axios.put(`/api/v1/auth/users/${id}/`, data)

const apiCreateUser = (data: TUserCreate) => axios.post(`/api/v1/auth/users/`, data)

export { apiFetchUsers, apiUpdateUser, apiCreateUser, apiFetchUser }
