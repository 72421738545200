import {
    TFinancingOptionTypeUpdate,
    TFinancingOptionTypeCreate,
} from "src/@types/financing-option-type"
import axios from "../utils/axios"

const apiFetchFinancingOptionTypes = () => axios.get("/api/v1/quotes/financing-option-types/")

const apiFetchFinancingOptionType = (id: number) =>
    axios.get(`/api/v1/quotes/financing-option-types/${id}/`)

const apiCreateFinancingOptionType = (data: TFinancingOptionTypeCreate) =>
    axios.post("/api/v1/quotes/financing-option-types/", data)

const apiUpdateFinancingOptionType = (id: number, data: TFinancingOptionTypeUpdate) =>
    axios.put(`/api/v1/quotes/financing-option-types/${id}/`, data)

const apiDeleteFinancingOptionType = (id: number) =>
    axios.delete(`/api/v1/quotes/financing-option-types/${id}/`)

export {
    apiFetchFinancingOptionTypes,
    apiFetchFinancingOptionType,
    apiCreateFinancingOptionType,
    apiUpdateFinancingOptionType,
    apiDeleteFinancingOptionType,
}
