import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from "@mui/material"
import { useEffect, useState } from "react"
import { format, isThisYear } from "date-fns"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getCategories } from "src/redux/slices/categories"
// import { getLineItemTypes } from "src/redux/slices/lineItemTypes"
import { useDispatch, useSelector } from "src/redux/store"
// import AddIcon from "@mui/icons-material/Add"
import { PATHS } from "src/routes/paths"
import { Category } from "src/@types/category"
import { deleteQuote } from "src/redux/slices/quotes"
import { useSnackbar } from "notistack"
import { Quote } from "src/@types/quote"
import { apiFetchQuotes } from "src/api/quotes"
import "./styles.css"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"

const getCategoriesPath = (id: number, categoriesById: { [key: number]: Category }): string[] => {
    const category = categoriesById[id]
    if (category.parent_id === null) {
        return [category.name]
    }
    return [...getCategoriesPath(category.parent_id, categoriesById), category.name]
}

function formatDate(date: Date, timeFormat: string = ", h:mm a") {
    if (isThisYear(date)) {
        return format(date, `M/d${timeFormat}`)
    }
    return format(date, `M/d/yyyy${timeFormat}`)
}

export default function QuotesListPage() {
    const params = new URLSearchParams(window.location.search)
    const initialPage = params.get("page") ? Number(params.get("page")) : 1
    const initialOrderBy = params.get("orderBy") || "updated_at"
    const initialOrderDirection = params.get("orderDirection") === "" ? "" : "-"

    const { hasPermission } = useAuthContext()

    const [page, setPage] = useState<number>(initialPage)
    const [orderBy, setOrderBy] = useState<string>(initialOrderBy)
    const [orderByDirection, setOrderByDirection] = useState<"" | "-">(initialOrderDirection)
    const [forceUpdate, setForceUpdate] = useState<Date>(new Date())

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [quotes, setQuotes] = useState<Quote[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    // const [page, setPage] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    // const [orderBy, setOrderBy] = useState<string>("updated_at")
    // const [orderByDirection, setOrderByDirection] = useState<"" | "-">("-")
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        // let url =
        navigate(`?page=${page}&orderBy=${orderBy}&orderDirection=${orderByDirection}`, {
            replace: true,
        })
    }, [page, orderBy, orderByDirection, navigate])

    // const { quotes } = useSelector((state) => state.quotes)
    const {
        categories: { byId: categoriesById },
    } = useSelector((state) => state.categories)

    useEffect(() => {
        setIsLoading(true)
        const getQuotesAsync = async () => {
            const response = await apiFetchQuotes({
                page,
                order_by: `${orderByDirection}${orderBy}`,
            })
            if (response.status === 200) {
                setQuotes(response.data.results)
                setTotalCount(response.data.count)
                setIsLoading(false)
            }
        }
        getQuotesAsync()
    }, [page, orderBy, orderByDirection, forceUpdate])

    useEffect(() => {
        // dispatch(getQuotes())
        dispatch(getCategories())
    }, [dispatch])

    // console.log({ quotes, categoriesById })

    const onDelete = async (id: number) => {
        if (window.confirm("Are you sure you want to delete this quote?")) {
            const response = await dispatch(deleteQuote(id))
            if (response.status === 204) {
                enqueueSnackbar(`Quote ${id} deleted successfully`, { variant: "success" })
                setForceUpdate(new Date())
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Quotes</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Quotes"
                    links={[
                        // {
                        //     name: "Quotes",
                        // },
                        {
                            name: "Quotes",
                            href: PATHS.quotes.quotes.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    // action={
                    //     <Button
                    //         variant="contained"
                    //         size="small"
                    //         startIcon={<AddIcon />}
                    //         onClick={() => navigate(PATHS.quotes.quotes.create())}
                    //     >
                    //         Create a Quote
                    //     </Button>
                    // }
                />
                <Card>
                    <TableContainer>
                        <Table size="small" className={isLoading ? "blinking" : ""}>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>Name</TableCell> */}
                                    {/* <TableCell>Estimate Number</TableCell> */}
                                    <TableCell>
                                        {/* <TableSortLabel

                                        /> */}
                                        Customer
                                    </TableCell>
                                    {/* <TableCell>Description</TableCell> */}
                                    <TableCell>Category</TableCell>
                                    <TableCell>Consultant</TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === "created_at"}
                                            direction={orderByDirection === "-" ? "desc" : "asc"}
                                            onClick={() => {
                                                if (orderBy === "created_at") {
                                                    setOrderByDirection(
                                                        orderByDirection === "-" ? "" : "-"
                                                    )
                                                } else {
                                                    setOrderBy("created_at")
                                                    setOrderByDirection("-")
                                                }
                                                setPage(1)
                                            }}
                                        >
                                            Created
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === "updated_at"}
                                            direction={orderByDirection === "-" ? "desc" : "asc"}
                                            onClick={() => {
                                                if (orderBy === "updated_at") {
                                                    setOrderByDirection(
                                                        orderByDirection === "-" ? "" : "-"
                                                    )
                                                } else {
                                                    setOrderBy("updated_at")
                                                    setOrderByDirection("-")
                                                }
                                                setPage(1)
                                            }}
                                        >
                                            Updated
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quotes.map((quote) => {
                                    const { id, category_id, created_by, sf_estimate } = quote
                                    let categoriesPath: string[] = []
                                    if (category_id && categoriesById[category_id]) {
                                        // const category = categoriesById[category_id]
                                        categoriesPath = getCategoriesPath(
                                            category_id,
                                            categoriesById
                                        )
                                    }
                                    return (
                                        <TableRow key={id} hover>
                                            {/* <TableCell>{name}</TableCell> */}
                                            {/* <TableCell>{sf_estimate?.number}</TableCell> */}
                                            <TableCell>
                                                {sf_estimate && (
                                                    <Link
                                                        href={`https://admin.servicefusion.com/estimate/estimateView?id=${sf_estimate?.url_id}`}
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        {sf_estimate?.customer.name}
                                                    </Link>
                                                )}
                                            </TableCell>
                                            {/* <TableCell>{description}</TableCell> */}
                                            <TableCell>{categoriesPath.join(" > ")}</TableCell>
                                            <TableCell>
                                                {created_by
                                                    ? `${created_by.first_name} ${created_by.last_name}`
                                                    : null}
                                            </TableCell>
                                            <TableCell>
                                                {formatDate(new Date(quote.created_at), "")}
                                            </TableCell>
                                            <TableCell>
                                                {formatDate(new Date(quote.updated_at))}
                                            </TableCell>
                                            <TableCell>
                                                <ButtonGroup variant="contained" size="small">
                                                    <Button
                                                        onClick={() =>
                                                            navigate(
                                                                PATHS.quotes.quotes.details(id)
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            navigate(PATHS.quotes.quotes.view(id))
                                                        }
                                                    >
                                                        View
                                                    </Button>
                                                    {hasPermission(Permissions.QUOTE_EXPORT) ? (
                                                        <Button
                                                            onClick={() =>
                                                                navigate(
                                                                    PATHS.quotes.quotes.export(id)
                                                                )
                                                            }
                                                        >
                                                            Export
                                                        </Button>
                                                    ) : null}
                                                    {hasPermission(Permissions.QUOTE_DELETE) ? (
                                                        <Button
                                                            onClick={() => onDelete(id)}
                                                            color="error"
                                                            variant="outlined"
                                                        >
                                                            Delete
                                                        </Button>
                                                    ) : null}
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={totalCount}
                        rowsPerPageOptions={[]}
                        rowsPerPage={10}
                        page={page - 1}
                        onPageChange={(event, p) => setPage(p + 1)}
                    />
                </Card>
            </Container>
        </>
    )
}
