import { createSlice, Dispatch } from "@reduxjs/toolkit"
import {
    TLineItemTypeCreate,
    TLineItemTypesRelationship,
    TLineItemTypesRelationshipCreate,
    TLineItemTypesState,
    TLineItemTypeUpdate,
} from "src/@types/lineItemType"
import {
    apiCreateLineItemType,
    apiFetchLineItemType,
    apiFetchLineItemTypes,
    apiUpdateLineItemType,
    apiDeleteLineItemType,
    apiCreateLineItemTypesRelationship,
    apiDeleteLineItemTypesRelationship,
    apiCopyLineItemType,
} from "src/api/line-item-types"

const initialState: TLineItemTypesState = {
    isLoading: false,
    lineItemTypes: [],
}

const slice = createSlice({
    name: "lineItemTypes",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchLineItemTypesSuccess(state, action) {
            state.isLoading = false
            state.lineItemTypes = action.payload
        },
        fetchLineItemTypeSuccess(state, action) {
            state.isLoading = false
            const index = state.lineItemTypes.findIndex((item) => item.id === action.payload.id)
            if (index !== -1) {
                state.lineItemTypes[index] = action.payload
            } else {
                state.lineItemTypes.push(action.payload)
            }
        },
        deleteLineItemTypeSuccess(state, action) {
            state.isLoading = false
            const index = state.lineItemTypes.findIndex((item) => item.id === action.payload)
            if (index !== -1) {
                state.lineItemTypes.splice(index, 1)
            }
        },
        createLineItemTypesRelationshipSuccess(state, action) {
            state.isLoading = false
            const relationship = action.payload
            const parentIndex = state.lineItemTypes.findIndex(
                (item) => item.id === relationship.parent.id
            )
            if (parentIndex !== -1) {
                state.lineItemTypes[parentIndex].children.push(relationship)
            }
        },
        deleteLineItemTypesRelationshipSuccess(state, action) {
            state.isLoading = false
            const relationship = action.payload
            const parentIndex = state.lineItemTypes.findIndex(
                (item) => item.id === relationship.parent.id
            )
            if (parentIndex !== -1) {
                const childIndex = state.lineItemTypes[parentIndex].children.findIndex(
                    (item) => item.id === relationship.id
                )
                if (childIndex !== -1) {
                    state.lineItemTypes[parentIndex].children.splice(childIndex, 1)
                }
            }
        },
    },
})

export function getLineItemTypes() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchLineItemTypes()
        if (response.status === 200) {
            dispatch(slice.actions.fetchLineItemTypesSuccess(response.data.results))
        }
        return response
    }
}

export function getLineItemType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchLineItemType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchLineItemTypeSuccess(response.data))
        }
        return response
    }
}

export function createLineItemType(data: TLineItemTypeCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateLineItemType(data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchLineItemTypeSuccess(response.data))
        }
        return response
    }
}

export function updateLineItemType(id: number, data: TLineItemTypeUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateLineItemType(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchLineItemTypeSuccess(response.data))
        }
        return response
    }
}

export function deleteLineItemType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteLineItemType(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteLineItemTypeSuccess(id))
        }
        return response
    }
}

export function createLineItemTypesRelationship(data: TLineItemTypesRelationshipCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateLineItemTypesRelationship(data)
        if (response.status === 201) {
            dispatch(slice.actions.createLineItemTypesRelationshipSuccess(response.data))
        }
        return response
    }
}

export function deleteLineItemTypesRelationship(relationship: TLineItemTypesRelationship) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteLineItemTypesRelationship(relationship.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteLineItemTypesRelationshipSuccess(relationship))
        }
        return response
    }
}

export function copyLineItemType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCopyLineItemType(id)
        if (response.status === 201) {
            dispatch(slice.actions.fetchLineItemTypesSuccess([response.data]))
        }
        return response
    }
}

export default slice.reducer
