import { MeasurementTypeCreate, MeasurementTypeUpdate } from "src/@types/measurementType"
import axios from "../utils/axios"


const apiFetchMeasurementTypes = () => axios.get('/api/v1/quotes/measurement-types/')

const apiFetchMeasurementType = (id: number) => axios.get(`/api/v1/quotes/measurement-types/${id}/`)

const apiUpdateMeasurementType = (id: number, changes: MeasurementTypeUpdate) =>
    axios.patch(`/api/v1/quotes/measurement-types/${id}/`, changes)

const apiCreateMeasurementType = (payload: MeasurementTypeCreate) =>
    axios.post(`/api/v1/quotes/measurement-types/`, payload)

const apiDeleteMeasurementType = (id: number) =>
    axios.delete(`/api/v1/quotes/measurement-types/${id}/`)


export {
    apiUpdateMeasurementType,
    apiFetchMeasurementType,
    apiFetchMeasurementTypes,
    apiCreateMeasurementType,
    apiDeleteMeasurementType,
}