import { CircularProgress, Container, IconButton, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import AddIcon from "@mui/icons-material/Add"
import { MeasurementTypeUpdate } from "src/@types/measurementType"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import MeasurementTypeForm from "src/forms/measurement-type-edit"
import {
    createMeasurementTypeField,
    getMeasurementType,
    updateMeasurementType,
} from "src/redux/slices/measurementTypes"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { useSnackbar } from "notistack"
import { Unit } from "src/@types/units"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"
import MeasurementTypeFieldForm from "./field-form"

export default function MeasurementTypeDetailsPage() {
    const { id } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const { hasPermission } = useAuthContext()

    const dispatch = useDispatch()

    useEffect(() => {
        const getMeasurementTypeAsync = async () => {
            if (idAsNumber) {
                await dispatch(getMeasurementType(idAsNumber))
                setIsLoading(false)
            }
        }
        getMeasurementTypeAsync()
    }, [dispatch, idAsNumber])

    const measurementType = useSelector((state) =>
        state.measurementTypes.measurementTypes.find((item) => item.id === idAsNumber)
    )
    const fields = useSelector(
        (state) => state.measurementTypes.fieldsByMeasurementTypeId[measurementType?.id || 0] || []
    )

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (measurementType === undefined) {
        return <div>Not found</div>
    }

    const onSubmit = async (data: MeasurementTypeUpdate) => {
        try {
            console.log(data)
            await dispatch(updateMeasurementType(idAsNumber, data))
            enqueueSnackbar("Measurement Type updated successfully", {
                variant: "success",
            })
        } catch (error) {
            console.log(error)
        }
    }

    const onCreateField = async () => {
        try {
            const fieldData = {
                name: "New Field",
                type_id: measurementType.id,
                type: "number",
                description: "New Field Description",
                slug: "new_field",
                unit: Unit.COUNT,
                order: measurementType.m_fields.length + 1,
                value: 0,
                data: {
                    variable: "",
                },
            }
            const response = await dispatch(createMeasurementTypeField(fieldData))
            if (response.status === 201) {
                enqueueSnackbar("Field created successfully", {
                    variant: "success",
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // const onDragEnd = (result: any) => {
    //     console.log(result)
    // }

    return (
        <>
            <Helmet>
                <title>Measurement Type {measurementType.name}</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading={`Measurement Type ${measurementType.name}`}
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Measurement Types",
                            href: PATHS.quotes.measurementTypes.list(),
                        },
                        {
                            name: measurementType.name,
                        },
                    ]}
                />
                <MeasurementTypeForm initialData={measurementType} onSubmit={onSubmit} />
                <Stack spacing={2} direction="column">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="subtitle1">Fields</Typography>
                        <IconButton
                            size="small"
                            color="success"
                            onClick={onCreateField}
                            disabled={!hasPermission(Permissions.MEASUREMENT_TYPE_FIELD_CREATE)}
                        >
                            <AddIcon />
                        </IconButton>
                    </Stack>
                    {hasPermission(Permissions.MEASUREMENT_TYPE_FIELD_VIEW)
                        ? fields.map((field) => (
                              <MeasurementTypeFieldForm key={field.id} field={field} />
                          ))
                        : "You do not have permission to view fields"}
                </Stack>
            </Container>
        </>
    )
}
