import { CircularProgress, Container, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { PATHS } from "src/routes/paths"
import { useDispatch, useSelector } from "src/redux/store"
import { getCategories, updateCategory } from "src/redux/slices/categories"
import CategoryForm from "src/forms/category-edit"
import { useParams } from "react-router"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { CategoryUpdate } from "src/@types/category"
import CategoriesTable from "../categories-list/CategoriesTable"

export default function CategoryDetailsPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    useEffect(() => {
        const getCategoriesAsync = async () => {
            if (idAsNumber) {
                await dispatch(getCategories())
                setIsLoading(false)
            }
        }
        getCategoriesAsync()
    }, [dispatch, idAsNumber])

    const { categories } = useSelector((state) => state.categories)
    const category = categories.byId[idAsNumber]
    const allCategories = Object.values(categories.byId)
    const children = allCategories.filter((item) => item.parent_id === idAsNumber)

    const onSubmit = async (data: CategoryUpdate) => {
        try {
            const response = await dispatch(updateCategory(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Category updated successfully", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (category === undefined) {
        return <div>Not found</div>
    }

    const parentsBreadcrumbs = [category]
    let parent = category
    while (parent.parent_id !== null) {
        parent = categories.byId[parent.parent_id]
        parentsBreadcrumbs.unshift(parent)
    }

    return (
        <>
            <Helmet>
                <title>Category {category.name}</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading={`Category ${category.name}`}
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Categories",
                            href: PATHS.quotes.categories.list(),
                        },
                        ...parentsBreadcrumbs.map((item) => ({
                            name: item.name,
                            href: PATHS.quotes.categories.details(item.id),
                        })),
                        // {
                        //     name: category.name,
                        //     href: PATHS.quotes.categories.details(category.id)
                        // },
                    ]}
                />
                <CategoryForm initialData={category} onSubmit={onSubmit} />
                {children.length > 0 && (
                    <>
                        <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                            Subcategories
                        </Typography>
                        <CategoriesTable categories={children} allCategories={allCategories} />
                    </>
                )}
            </Container>
        </>
    )
}
