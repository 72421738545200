import { SettingsValueProps } from "./types"

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
    themeMode: "light",
    themeDirection: "ltr",
    themeContrast: "bold",
    // @ts-ignore
    themeLayout: localStorage.getItem("menu_type") || "vertical",
    themeColorPresets: "cyan",
    themeStretch: true,
}
