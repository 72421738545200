import { Button, ButtonGroup, TableCell, TableRow, TextField, styled } from "@mui/material"
import SaveIcon from "@mui/icons-material/Save"
import { TMaterialType, MaterialTypePrice } from "src/@types/material-type"
import { useState } from "react"
import { useDispatch } from "src/redux/store"
import {
    createMaterialTypePrice,
    updateMaterialTypePrice,
    deleteMaterialTypePrice,
} from "src/redux/slices/material-types"
import { useSnackbar } from "notistack"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ClearIcon from "@mui/icons-material/Clear"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"

type MaterialPriceRowProps = {
    material: TMaterialType
    price: MaterialTypePrice | null
}

type MaterialPriceDisplayRowProps = {
    toggleEditMode: () => void
    price: MaterialTypePrice
}

type MaterialPriceEditRowProps = MaterialPriceRowProps & {
    toggleEditMode: () => void
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },
}))

// const validateRangeMin = (rangeMin: number, existingRanges: number[]): string | null => {
//     if (existingRanges.includes(rangeMin)) {
//         return `The price for range starting from ${rangeMin} already exists`
//     }

//     console.log(rangeMin, existingRanges)

//     if (!existingRanges.includes(0) && rangeMin !== 0)
//         return "The first range min must be 0"

//     return null
// }

export default function MaterialPriceRow({ material, price }: MaterialPriceRowProps) {
    const [isEditMode, setIsEditMode] = useState(false)
    console.log(isEditMode, price)

    const toggleEditMode = () => {
        setIsEditMode((state) => !state)
    }

    if (price === null) {
        return (
            <TableRow sx={{ borderTop: "1px dotted #DDD" }}>
                <MaterialPriceEditRow
                    material={material}
                    price={price}
                    toggleEditMode={toggleEditMode}
                />
            </TableRow>
        )
    }

    if (isEditMode) {
        return (
            <StyledTableRow key={price.id}>
                <MaterialPriceEditRow
                    material={material}
                    price={price}
                    toggleEditMode={toggleEditMode}
                />
            </StyledTableRow>
        )
    }

    return <MaterialPriceDisplayRow price={price} toggleEditMode={toggleEditMode} />
}

function MaterialPriceDisplayRow({ price, toggleEditMode }: MaterialPriceDisplayRowProps) {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { hasPermission } = useAuthContext()

    const handleDelete = async () => {
        if (!price) return
        if (!window.confirm("Are you sure you want to delete this price?")) return
        try {
            const response = await dispatch(deleteMaterialTypePrice(price.id, price.type_id))
            if (response.status === 204) {
                enqueueSnackbar("Price deleted", { variant: "success" })
            }
        } catch (e) {
            enqueueSnackbar("Failed to delete price", { variant: "error" })
        }
    }

    return (
        <StyledTableRow key={price.id}>
            <TableCell align="right">{price.range_lower_bound.toLocaleString()}</TableCell>
            <TableCell align="right">
                {price.price_per_unit.toLocaleString("en-us", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 5,
                })}
            </TableCell>
            <TableCell padding="none" align="center">
                <ButtonGroup size="small" variant="text">
                    <Button disabled={!hasPermission(Permissions.MATERIAL_TYPE_PRICE_UPDATE)}>
                        <EditIcon fontSize="small" onClick={toggleEditMode} />
                    </Button>
                    <Button
                        color="error"
                        disabled={!hasPermission(Permissions.MATERIAL_TYPE_PRICE_DELETE)}
                    >
                        <DeleteIcon fontSize="small" onClick={handleDelete} />
                    </Button>
                </ButtonGroup>
            </TableCell>
        </StyledTableRow>
    )
}

function MaterialPriceEditRow({ material, toggleEditMode, price }: MaterialPriceEditRowProps) {
    const [rangeMin, setRangeMin] = useState<number | string>(
        price?.range_lower_bound === undefined ? "" : price.range_lower_bound
    )
    const [pricePerUnit, setPricePerUnit] = useState<number | string>(price?.price_per_unit || "")
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const handleRangeMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rangeM = parseInt(e.target.value, 10)
        setRangeMin(rangeM)
    }

    const handlePricePerUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const ppu = parseFloat(e.target.value)
        setPricePerUnit(ppu)
    }

    const handleSave = async () => {
        if (rangeMin === null || pricePerUnit === null) {
            alert("Both range from and price per unit are required")
            return
        }

        // const existingRanges = material.prices.filter(p => price === null || price || p.id !== price.id).map((p) => p.range_lower_bound)

        // const error = validateRangeMin(rangeMin, existingRanges)

        // if (error) {
        //     alert(error)
        //     return
        // }

        try {
            if (price === null) {
                const data = {
                    range_lower_bound: Number(rangeMin),
                    price_per_unit: Number(pricePerUnit),
                    type_id: material.id,
                }
                const response = await dispatch(createMaterialTypePrice(data))
                console.log(response)
                if (response.status === 201) {
                    enqueueSnackbar("Price added", { variant: "success" })
                    setRangeMin("")
                    setPricePerUnit("")
                }
            } else {
                const data = {
                    range_lower_bound: Number(rangeMin),
                    price_per_unit: Number(pricePerUnit),
                }
                const response = await dispatch(updateMaterialTypePrice(price.id, data))
                console.log(response)
                if (response.status === 200) {
                    enqueueSnackbar("Price updated", { variant: "success" })
                    setRangeMin("")
                    setPricePerUnit("")
                }
            }
            setRangeMin("")
            setPricePerUnit("")
            toggleEditMode()
        } catch (e) {
            console.log(e)
            enqueueSnackbar("Error", { variant: "error" })
        }
    }

    return (
        <>
            <TableCell>
                <TextField
                    size="small"
                    variant="standard"
                    // name="range_lower_bound"
                    fullWidth
                    type="number"
                    onChange={handleRangeMinChange}
                    value={rangeMin}
                    inputProps={{
                        min: 0,
                        step: 0.01,
                        style: { textAlign: "right", fontSize: "14px", padding: 0 },
                    }}
                />
            </TableCell>
            <TableCell>
                <TextField
                    size="small"
                    // name="price_per_unit"
                    fullWidth
                    type="number"
                    variant="standard"
                    onChange={handlePricePerUnitChange}
                    value={pricePerUnit}
                    inputProps={{
                        min: 0,
                        step: 0.01,
                        style: { textAlign: "right", fontSize: "14px", padding: 0 },
                    }}
                />
            </TableCell>
            <TableCell align="center" padding="none">
                <ButtonGroup size="small" variant="text">
                    <Button color="success" onClick={handleSave}>
                        <SaveIcon fontSize="small" />
                    </Button>
                    {price !== null && (
                        <Button color="error" onClick={toggleEditMode}>
                            <ClearIcon fontSize="small" />
                        </Button>
                    )}
                </ButtonGroup>
            </TableCell>
        </>
    )
}
