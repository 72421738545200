export enum TContainerType {
    COEFFICIENT = "coefficient",
    TERMS_AND_CONDITIONS = "terms_and_conditions",
    JHA_ITEM = "jha_item",
}

export type TContainer = {
    id: number
    name: string
    status: string
    value: number | null
    created_at: string
    updated_at: string
    object_id: number
    data: object
    effective_from: string | null
    effective_to: string | null
    type: string
}

export type TContainerUpdate = {
    name?: string
    status?: string
    data?: any
    value?: number | null
    effective_from?: string | null
    effective_to?: string | null
    type?: string
}

export type TContainerCreate = {
    name: string
    status: string
    value: number | null
    data: any
    effective_from: string | null
    effective_to: string | null
    type: string
    related_entity?: {
        id: number
        type: string
    }
}

export type TContainersState = {
    containers: TContainer[]
    isLoading: boolean
}

export type TContainerTermsAndConditions = TContainer & {
    type: TContainerType.TERMS_AND_CONDITIONS
    data: {
        title: string
        sections: {
            title: string
            text: string
        }[]
    }
}

export type TContainerJHAItem = TContainer & {
    type: TContainerType.JHA_ITEM
    data: {
        sequence_of_work: string
        potential_hazard: string
        method: string
    }
}
