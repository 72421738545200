import { Container } from "@mui/material"
import { Helmet } from "react-helmet-async"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { PATHS } from "src/routes/paths"
import { dispatch } from "src/redux/store"
import { createCategory } from "src/redux/slices/categories"
import CategoryForm from "src/forms/category-edit"
import { useNavigate } from "react-router"

type FormValues = {
    name: string
    description: string
    parent_id?: number | null
}

export default function CategoryCreate() {
    const navigate = useNavigate()

    const onSubmit = async (data: FormValues) => {
        try {
            console.log(data)
            const response = await dispatch(createCategory(data))
            if (response.status === 201) {
                navigate(PATHS.quotes.categories.details(response.data.id))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create Category</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Create a Category"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Categories",
                            href: PATHS.quotes.categories.list(),
                        },
                        {
                            name: "Create",
                        },
                    ]}
                />
                <CategoryForm onSubmit={onSubmit} />
            </Container>
        </>
    )
}
