import { useEffect } from "react"
import { useLocation } from "react-router-dom"
// @mui
import { Box, Drawer } from "@mui/material"
// hooks
import { useAuthContext } from "src/auth/useAuthContext"
import useResponsive from "../../../hooks/useResponsive"
// config
import { NAV } from "../../../config-global"
// components
// import Logo from '../../../components/logo';
import Scrollbar from "../../../components/scrollbar"
import { NavSectionVertical } from "../../../components/nav-section"
//
import navConfig from "./config-navigation"
// import NavAccount from './NavAccount';
// import NavDocs from './NavDocs';
import NavToggleButton from "./NavToggleButton"
// import AccountPopover from '../header/AccountPopover';

// ----------------------------------------------------------------------

type Props = {
    openNav: boolean
    onCloseNav: VoidFunction
}

export default function NavVertical({ openNav, onCloseNav }: Props) {
    const { pathname } = useLocation()

    const isDesktop = useResponsive("up", "lg")

    const { user } = useAuthContext()

    console.log("NAV", user)
    // TODO: Remove this hack
    if (!["Stan", "Wesley"].includes(user?.first_name || "")) {
        navConfig.splice(1, 2)
    }

    useEffect(() => {
        if (openNav) {
            onCloseNav()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const renderContent = (
        <Scrollbar
            sx={{
                justifyContent: "space-between",
                height: 1,
                "& .simplebar-content": {
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            {/* <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      > */}
            {/* <Logo /> */}

            {/* <NavAccount /> */}
            {/* </Stack> */}

            {/* <Stack direction="column" justifyContent="space-between" alignItems="flex-start"> */}
            <NavSectionVertical data={navConfig} />
            {/* <AccountPopover /> */}
            {/* </Stack> */}

            {/* <Box sx={{ flexGrow: 1 }} /> */}

            {/* <NavDocs /> */}
        </Scrollbar>
    )

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_DASHBOARD },
            }}
            className="non-printable"
        >
            <NavToggleButton
                sx={{
                    top: 22,
                    // left: NAV.W_DASHBOARD_MINI - 12,
                }}
            />

            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            zIndex: 0,
                            width: NAV.W_DASHBOARD,
                            bgcolor: "transparent",
                            borderRightStyle: "solid",
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: {
                            width: NAV.W_DASHBOARD,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    )
}
