import * as Yup from "yup"
// form
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
// @mui
import { Stack, Alert } from "@mui/material"
import { LoadingButton } from "@mui/lab"
// auth
import { useAuthContext } from "../../auth/useAuthContext"
// components
import FormProvider, { RHFTextField } from "../../components/hook-form"

// ----------------------------------------------------------------------

type FormValuesProps = {
    email: string
    password: string
    afterSubmit?: string
}

export default function AuthLoginForm() {
    const { login } = useAuthContext()

    // const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required")
            .email("Email must be a valid email address"),
        password: Yup.string().required("Password is required"),
    })

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        // defaultValues,
    })

    const {
        setError,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = methods

    const onSubmit = async (data: FormValuesProps) => {
        try {
            await login(data.email, data.password)
        } catch (error) {
            console.error(error)

            // reset();

            setError("afterSubmit", {
                ...error,
                message: error.message || error,
            })
        }
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                {!!errors.afterSubmit && (
                    <Alert severity="error" data-test="login-error-alert">
                        {errors.afterSubmit.message}
                    </Alert>
                )}

                <RHFTextField name="email" label="Email address" data-test="email-field" />

                <RHFTextField
                    name="password"
                    label="Password"
                    type="password"
                    data-test="password-field"
                    // type={showPassword ? 'text' : 'password'}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    //         <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                />
            </Stack>

            <Stack alignItems="flex-end" sx={{ my: 2 }}>
                {/* <Link variant="body2" color="inherit" underline="always">
          Forgot password?
        </Link> */}
            </Stack>

            <LoadingButton
                data-test="submit-button"
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitSuccessful || isSubmitting}
                sx={{
                    bgcolor: "text.primary",
                    color: (theme) =>
                        theme.palette.mode === "light" ? "common.white" : "grey.800",
                    "&:hover": {
                        bgcolor: "text.primary",
                        color: (theme) =>
                            theme.palette.mode === "light" ? "common.white" : "grey.800",
                    },
                }}
            >
                Login
            </LoadingButton>
        </FormProvider>
    )
}
