import { TextField } from "@mui/material"
import { useState } from "react"

type Props = {
    initialValue: string | number
    onSave: (value: string | number) => void
}

export default function TextFieldWithSave({ initialValue, onSave }: Props) {
    const [isChanged, setIsChanged] = useState(false)
    const [value, setValue] = useState(String(initialValue))
    return (
        <TextField
            size="small"
            value={value}
            type="number"
            inputMode="numeric"
            inputProps={{
                min: 0,
                step: 1,
                pattern: "[0-9]*",
                style: { textAlign: "right" },
            }}
            onChange={(e) => {
                setValue(e.target.value)
                setIsChanged(true)
            }}
            onBlur={() => {
                if (isChanged) {
                    onSave(value)
                }
            }}
        />
    )
}
