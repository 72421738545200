import { createSlice, Dispatch } from "@reduxjs/toolkit"
import { apiFetchJobs, FetchJobFilters } from "src/api/data"
import { JobsState } from "src/@types/jobs"

const initialState: JobsState = {
    isLoading: false,
    error: null,
    jobs: {
        byId: {},
    },
}

const slice = createSlice({
    name: "jobs",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchJobsRunsSuccess(state, action) {
            console.log(action)
            state.isLoading = false
            state.error = null
            action.payload.forEach((job: any) => {
                state.jobs.byId[job.id] = job
            })

        },
        // fetchPayrollRunSuccess(state, action) {
        //     state.isLoading = false
        //     state.error = null
        //     const job = action.payload
        //     state.jobs.byId[job.id] = job
        // },
        // updatePayrollRunSuccess(state, action) {
        //     state.isLoading = false
        //     state.error = null
        //     const { id, changes } = action.payload
        //     const index = state.payrollRuns.findIndex((payrollRun) => payrollRun.id === id)
        //     if (index !== -1) {
        //         state.payrollRuns[index] = { ...state.payrollRuns[index], ...changes }
        //     }
        // },
    }
})




export function getJobs(filters: FetchJobFilters) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchJobs(filters)
        console.log(response)
        if (response.status === 200) {
            dispatch(slice.actions.fetchJobsRunsSuccess(response.data.results))
            return {
                jobs: response.data.results,
                count: response.data.count,
            }
        }
        return {
            jobs: [],
            count: 0,
        }

    }
}


// export function getPayrollRun(id: number) {
//     return async (dispatch: Dispatch) => {
//         dispatch(slice.actions.startLoading())
//         const response = await apiFetchPayrollRun(id)
//         if (response.status === 200) {
//             dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
//         }
//     }
// }


// export function updatePayrollRun(id: number, changes: any) {
//     return async (dispatch: Dispatch) => {
//         dispatch(slice.actions.startLoading())
//         const response = await apiUpdatePayrollRun(id, changes)
//         if (response.status === 200) {
//             dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
//         }
//     }
// }


// export function removeJobsFromPayrollRun(id: number, jobIds: number[]) {
//     return async (dispatch: Dispatch) => {
//         dispatch(slice.actions.startLoading())
//         const response = await apiRemoveJobsFromPayrollRun(id, jobIds)
//         if (response.status === 200) {
//             dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
//         }
//     }
// }


export default slice.reducer