import React, { useEffect, useRef } from "react"
import JSONEditor, { JSONEditorOptions } from "jsoneditor"
import "jsoneditor/dist/jsoneditor.css"

interface JsonEditorComponentProps {
    value: object
    onChange: (value: object) => void
    options?: JSONEditorOptions
}

const JsonEditorComponent: React.FC<JsonEditorComponentProps> = ({ value, onChange, options }) => {
    const editorRef = useRef<HTMLDivElement>(null)
    const jsonEditor = useRef<JSONEditor | null>(null)

    useEffect(() => {
        if (editorRef.current) {
            jsonEditor.current = new JSONEditor(editorRef.current, {
                mode: "tree",
                modes: ["code", "tree", "view", "form", "text", "preview"],
                onChange: () => {
                    if (jsonEditor.current) {
                        try {
                            const updatedValue = jsonEditor.current.get()
                            onChange(updatedValue)
                        } catch (error) {
                            console.error("Error updating JSON value:", error)
                        }
                    }
                },
                ...options,
            })

            // Set the initial value
            //   jsonEditor.current.set(value);
        }

        return () => {
            if (jsonEditor.current) {
                jsonEditor.current.destroy()
            }
        }
    }, [onChange, options])

    const valueString = JSON.stringify(value, null, 2)

    // Update the editor's content when the `value` prop changes, without redrawing the component
    useEffect(() => {
        if (jsonEditor.current) {
            const currentValue = jsonEditor.current.get()
            if (JSON.stringify(currentValue) !== JSON.stringify(value)) {
                jsonEditor.current.update(value)
            }
        }
    }, [valueString, value])

    return <div ref={editorRef} style={{ height: "400px" }} />
}

export default JsonEditorComponent
