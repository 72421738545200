import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import AddIcon from "@mui/icons-material/Add"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { Permissions } from "src/@types/security"
import { useAuthContext } from "src/auth/useAuthContext"
import { deletePackageType, getPackageTypes } from "src/redux/slices/package-types"
import { TPackageType } from "src/@types/package-type"

export default function PackageTypesListPage() {
    const dispatch = useDispatch()
    const { packageTypes } = useSelector((state) => state.packageTypes)

    const { hasPermission } = useAuthContext()

    useEffect(() => {
        dispatch(getPackageTypes())
    }, [dispatch])

    const onDelete = async (packageType: TPackageType) => {
        if (
            !window.confirm(`Are you sure you want to delete the package type ${packageType.name}?`)
        )
            return
        const response = await dispatch(deletePackageType(packageType.id))
        if (response.status === 204) {
            console.log(`Package type ${packageType.name} deleted successfully`)
        }
    }

    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>Package Types</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Package Types"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Types",
                        },
                        {
                            name: "Packages",
                            href: PATHS.quotes.packageTypes.list(),
                        },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.quotes.packageTypes.create())}
                            disabled={!hasPermission(Permissions.PACKAGE_TYPE_CREATE)}
                        >
                            Create a Package Type
                        </Button>
                    }
                />
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>ID</TableCell> */}
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Discount</TableCell>
                                    <TableCell>Order</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {packageTypes.map((pt) => (
                                    <TableRow key={pt.id} hover>
                                        {/* <TableCell>{material.id}</TableCell> */}
                                        <TableCell>{pt.name}</TableCell>
                                        <TableCell>{pt.description}</TableCell>
                                        <TableCell>{pt.discount_percent}</TableCell>
                                        <TableCell>{pt.order}</TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="outlined" size="small">
                                                <Button
                                                    onClick={() =>
                                                        navigate(
                                                            PATHS.quotes.packageTypes.details(pt.id)
                                                        )
                                                    }
                                                    disabled={
                                                        !hasPermission(
                                                            Permissions.PACKAGE_TYPE_UPDATE
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    color="error"
                                                    onClick={() => onDelete(pt)}
                                                    disabled={
                                                        !hasPermission(
                                                            Permissions.PACKAGE_TYPE_DELETE
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
