import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { useSnackbar } from "notistack"
import { getGroup, getPermissions, getUsers, updateGroup } from "src/redux/slices/groups"
import GroupEditForm from "src/forms/group-edit"
import { TGroupUpdate, Permissions } from "src/@types/security"
import { TPermission } from "src/@types/permission"
import { TUser } from "src/@types/user"

const DISPLAY_PERMISSIONS = Object.values(Permissions).map((item) => String(item))

const getUserDisplayName = (user: TUser) => {
    if (!user.first_name && !user.last_name) return user.username
    return `${user.first_name} ${user.last_name}`
}

export default function GroupDetailsPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const fetchGroupAsync = async () => {
            if (idAsNumber) {
                await dispatch(getGroup(idAsNumber))
                setIsLoading(false)
            }
        }
        fetchGroupAsync()
    }, [dispatch, idAsNumber])

    useEffect(() => {
        dispatch(getUsers())
        dispatch(getPermissions())
    }, [dispatch])

    const group = useSelector((state) => state.groups.groups.find((item) => item.id === idAsNumber))
    const permissions = useSelector((state) => state.groups.permissions)
    const users = useSelector((state) => state.groups.users)

    const [activePermissions, setActivePermissions] = useState<TPermission[]>([])
    const [activeUsers, setActiveUsers] = useState<TUser[]>([])

    useEffect(() => {
        if (group) {
            setActivePermissions(group.permissions)
            setActiveUsers(group.users)
        }
    }, [group])

    const activePermissionIds = activePermissions.map((item) => item.id)
    // const activeUserIds = activeUsers.map((item) => item.id)

    const onSavePermissions = async () => {
        const response = await dispatch(
            updateGroup(idAsNumber, { permission_ids: activePermissions.map((item) => item.id) })
        )
        if (response.status === 200) {
            enqueueSnackbar("Permissions updated", { variant: "success" })
        }
    }

    const onSaveUsers = async () => {
        const response = await dispatch(
            updateGroup(idAsNumber, { user_ids: activeUsers.map((item) => item.id) })
        )
        if (response.status === 200) {
            enqueueSnackbar("Users updated", { variant: "success" })
        }
    }

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (group === undefined) {
        return <div>Not found</div>
    }

    const onSubmit = async (data: TGroupUpdate) => {
        // const payload = {
        //     name: data.name,
        //     // permissions: data.permissions.map((item) => item.id),
        //     // users: data.users.map((item) => item.id),
        // }
        try {
            console.log(data)
            const response = await dispatch(updateGroup(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Group updated successfully", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Group {group.name}</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading={`Group ${group.name}`}
                    links={[
                        {
                            name: "Groups",
                            href: PATHS.auth.groups.list(),
                        },
                        {
                            name: group.name,
                        },
                    ]}
                />
                <GroupEditForm initialData={group} onSubmit={onSubmit} />
                <Stack direction="column" spacing={2}>
                    <Typography variant="h6">Users</Typography>
                    <Autocomplete
                        multiple
                        options={users}
                        getOptionLabel={(option) => getUserDisplayName(option)}
                        value={activeUsers}
                        onChange={(e, value) => {
                            setActiveUsers(value)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                // variant="standard"
                                label="Users"
                                placeholder="Users"
                            />
                        )}
                    />
                    <div>
                        <Button variant="contained" size="small" onClick={onSaveUsers}>
                            Save
                        </Button>
                    </div>

                    <Typography variant="h6">Permissions</Typography>
                    {permissions
                        .filter((permission) => DISPLAY_PERMISSIONS.includes(permission.codename))
                        .map((permission) => (
                            <FormControlLabel
                                key={permission.id}
                                control={
                                    <Checkbox
                                        checked={activePermissionIds.includes(permission.id)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setActivePermissions([
                                                    ...activePermissions,
                                                    permission,
                                                ])
                                            } else {
                                                setActivePermissions(
                                                    activePermissions.filter(
                                                        (item) => item.id !== permission.id
                                                    )
                                                )
                                            }
                                        }}
                                    />
                                }
                                label={permission.name}
                            />
                        ))}
                    <div>
                        <Button onClick={onSavePermissions} variant="contained" size="small">
                            Save
                        </Button>
                    </div>
                </Stack>
            </Container>
        </>
    )
}
