import { createSlice, Dispatch } from "@reduxjs/toolkit"
import { apiAddEmployeesToPayrollRun, apiAddJobsToPayrollRun, apiAddPayTypesToPayrollRun, apiCreatePayrollRun, apiDeletePayrollRun, apiFetchPayrollRun, apiFetchPayrollRuns, apiGeneratePayments, apiRemoveEmployeesFromPayrollRun, apiRemoveJobsFromPayrollRun, apiRemovePayTypesFromPayrollRun, apiUpdatePayrollRun, apiDeletePaymentRecordsFromPayrollRun } from "src/api/payroll"
import { PayrollRunState } from "src/@types/payrollRun"

const initialState: PayrollRunState = {
    isLoading: false,
    error: null,
    payrollRuns: [],
}

const slice = createSlice({
    name: "payrollRuns",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchPayrollRunsSuccess(state, action) {
            state.isLoading = false
            state.error = null
            state.payrollRuns = action.payload
        },
        fetchPayrollRunSuccess(state, action) {
            const payrollRun = action.payload
            state.isLoading = false
            state.error = null
            const index = state.payrollRuns.findIndex((pr) => pr.id === payrollRun.id)
            if (index !== -1) {
                state.payrollRuns[index] = payrollRun
            } else {
                state.payrollRuns.push(payrollRun)
            }
            state.payrollRuns = [...state.payrollRuns]
        },
        deletePayrollRunSuccess(state, action) {
            const id = action.payload
            state.isLoading = false
            state.error = null
            const index = state.payrollRuns.findIndex((pr) => pr.id === id)
            if (index !== -1) {
                state.payrollRuns.splice(index, 1)
            }
        }
    }})

export function getPayrollRuns() {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiFetchPayrollRuns()
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunsSuccess(response.data.results))
        }
    }
}


export function getPayrollRun(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchPayrollRun(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}


export function createPayrollRun(dateFrom: Date, dateTo: Date) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreatePayrollRun(dateFrom, dateTo)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
        return response
    }
}


export function deletePayrollRun(id: number) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiDeletePayrollRun(id)
        if (response.status === 204) {
            dispatch(slice.actions.deletePayrollRunSuccess(id))
        }
    }
}


export function updatePayrollRun(id: number, changes: any) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiUpdatePayrollRun(id, changes)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}


export function removeJobsFromPayrollRun(id: number, jobIds: number[]) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiRemoveJobsFromPayrollRun(id, jobIds)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}


export function addJobsToPayrollRun(id: number, jobIds: number[]) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiAddJobsToPayrollRun(id, jobIds)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}


export function addPayTypesToPayrollRun(id: number, payTypesId: number[]) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiAddPayTypesToPayrollRun(id, payTypesId)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}


export function removePayTypesFromPayrollRun(id: number, payTypesId: number[]) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiRemovePayTypesFromPayrollRun(id, payTypesId)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}


export function removeEmployeesFromPayrollRun(id: number, employeeIds: number[]) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiRemoveEmployeesFromPayrollRun(id, employeeIds)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}


export function addEmployeesToPayrollRun(id: number, employeeIds: number[]) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiAddEmployeesToPayrollRun(id, employeeIds)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}


export function generatePayments(id: number) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiGeneratePayments(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}

export function deletePaymentRecordsFromPayrollRun(id: number, paymentRecordIds: number[]) {
    return async (dispatch: Dispatch) => {
        // dispatch(slice.actions.startLoading())
        const response = await apiDeletePaymentRecordsFromPayrollRun(id, paymentRecordIds)
        if (response.status === 200) {
            dispatch(slice.actions.fetchPayrollRunSuccess(response.data))
        }
    }
}


export default slice.reducer