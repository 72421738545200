import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material"
import { useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { RHFTextField } from "src/components/hook-form"
import { useDispatch } from "src/redux/store"
// import { updateLineItem } from "src/redux/slices/quotes"
import { useSnackbar } from "notistack"
import { TQuoteTemplateSection } from "src/@types/quote-template-section"
import { updateQuoteTemplateSection } from "src/redux/slices/quote-templates"

type Props = {
    section: TQuoteTemplateSection | null
    onClose: () => void
}

export default function EditQuoteTemplateSectionDialog({ section, onClose }: Props) {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const open = Boolean(section)

    const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
    })

    const methods = useForm<TQuoteTemplateSection>({
        resolver: yupResolver(schema),
        defaultValues: section || {},
    })

    useEffect(() => {
        if (section) {
            methods.reset(section)
        }
    }, [section, methods])

    if (!section) return null

    const handleSubmit = async (data: TQuoteTemplateSection) => {
        const changes = {
            name: data.name,
            order: data.order,
        }
        const response = await dispatch(updateQuoteTemplateSection(section.id, changes))
        if (response.status === 200) {
            enqueueSnackbar("Line Item updated", { variant: "success" })
            onClose()
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Edit Section</DialogTitle>
            <DialogContent>
                <FormProvider methods={methods}>
                    <Stack direction="column" spacing={2} flex={1}>
                        <RHFTextField name="name" label="Name" required sx={{ mt: 1 }} />
                        <RHFTextField name="order" label="Order" required sx={{ mt: 1 }} />
                    </Stack>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">
                    Cancel
                </Button>
                <Button onClick={methods.handleSubmit(handleSubmit)}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
