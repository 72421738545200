import Handlebars from "handlebars"
import { TLineItem } from "src/@types/lineItem"
import { TLineItemType } from "src/@types/lineItemType"
import { TMaterial } from "src/@types/material"
import { TPackageItem } from "src/@types/package"
import { renderCustomFieldValue } from "../custom-field-service"

type Props = {
    lineItem: TLineItem
    packageItem?: TPackageItem
    material?: TMaterial
    materialsById?: {
        [key: number]: TMaterial
    }
    text: string
}

Handlebars.registerHelper("padZeroes", (number: number | string, length: number) => {
    let str = number.toString()
    while (str.length < length) {
        str = `0${str}`
    }
    return str
})

Handlebars.registerHelper("contains", (string, substring) => string.indexOf(substring) !== -1)

Handlebars.registerHelper("eq", (a, b) => a === b)

Handlebars.registerHelper("and", (...args) => {
    console.log({ args })
    return args.every(Boolean)
})

Handlebars.registerHelper("prepareSkuValue", (number, multiplier, length) => {
    // First, multiply the number and round it up
    const result = Math.ceil(number * multiplier)

    // Then, pad the result with zeroes
    let str = result.toString()
    while (str.length < length) {
        str = `0${str}`
    }

    // Return the final processed value
    return str
})

// Handlebars.registerHelper("ifAnd", (a, b, options) => {
//     if (a && b) {
//         // @ts-ignore
//         return options.fn(this)
//     }
//     // @ts-ignore
//     return options.inverse(this)
// })

const formatLineItemDescription = ({
    text,
    lineItem,
    packageItem,
    materialsById,
    material,
}: Props): string => {
    try {
        const customFields: {
            m: {
                [key: string]: string
            }
        } = {
            m: {},
        }

        const replacements: any = {
            line_item: lineItem,
            li: lineItem,
            measurements: {},
            m: {},
            material,
            cf: customFields,
        }
        if (packageItem && materialsById && materialsById[packageItem.material_id]) {
            replacements.package_item = {
                material: materialsById[packageItem.material_id],
            }
            replacements.pi = {
                material: materialsById[packageItem.material_id],
            }
            if (materialsById[packageItem.material_id].data.custom_fields) {
                Object.keys(materialsById[packageItem.material_id].data.custom_fields).forEach(
                    (key) => {
                        const field = materialsById[packageItem.material_id].data.custom_fields[key]
                        customFields.m[field.slug] = renderCustomFieldValue(field)
                    }
                )
            }
        }
        console.log(customFields, material)
        lineItem.measurements.forEach((measurement) => {
            measurement.m_fields.forEach((field) => {
                if (!replacements.measurements[measurement.slug])
                    replacements.measurements[measurement.slug] = {}

                if (!replacements.m[measurement.slug]) replacements.m[measurement.slug] = {}
                replacements.m[measurement.slug].value = measurement.value

                if (replacements.measurements[measurement.slug][field.slug]) return

                replacements.measurements[measurement.slug][field.slug] = field.value || ""
                replacements.m[measurement.slug][field.slug] = field.value || ""
            })
        })

        // first pass
        let template = Handlebars.compile(text)
        text = template(replacements)

        // second pass to account for nested variables after the first pass
        template = Handlebars.compile(text)
        text = template(replacements)
        return text.replace(/\n/g, "")
    } catch (error) {
        console.error(error)
        return text
    }
}

const getLineItemName = (lineItem: TLineItem | TLineItemType): string =>
    lineItem.display_name || lineItem.name || ""

export { formatLineItemDescription, getLineItemName }
