import { Container } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import { Unit } from "src/@types/units"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import MaterialForm from "src/forms/material-type-edit"
import { createMaterialType } from "src/redux/slices/material-types"
import { useDispatch } from "src/redux/store"
import { PATHS } from "src/routes/paths"

type FormValues = {
    name: string
    description: string
    display_name: string
    category_id: number | null
    base_unit: Unit
    price_unit: Unit
    formula: string
    code: string
    data: {
        [key: string]: any
    }
}

export default function MaterialTypeCreatePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = async (data: FormValues) => {
        try {
            const response = await dispatch(createMaterialType(data))
            if (response.status === 201) {
                navigate(PATHS.quotes.materialTypes.details(response.data.id))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create a Material</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Create a Material"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Materials",
                            href: PATHS.quotes.materialTypes.list(),
                        },
                        {
                            name: "Create",
                        },
                    ]}
                />
                <MaterialForm onSubmit={onSubmit} />
            </Container>
        </>
    )
}
