import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getCategories } from "src/redux/slices/categories"
import { useDispatch, useSelector } from "src/redux/store"
import AddIcon from "@mui/icons-material/Add"
import { PATHS } from "src/routes/paths"
import { Category } from "src/@types/category"
import { useSnackbar } from "notistack"
import {
    deleteQuoteTemplate,
    getQuoteTemplates,
    updateQuoteTemplate,
} from "src/redux/slices/quote-templates"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"

function StatusButton({
    status,
    updateStatus,
}: {
    status: string
    updateStatus: (status: "active" | "inactive") => void
}) {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<any | null>(null)

    const newStatus = status === "active" ? "inactive" : "active"
    const newStatusText = newStatus === "active" ? "Active" : "Inactive"

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setOpen(false)
    }
    return (
        <>
            <Button
                variant="text"
                size="small"
                onClick={handleToggle}
                color={status === "active" ? "success" : "error"}
                ref={anchorRef}
            >
                {status === "active" ? "Active" : "Inactive"}
            </Button>
            <Menu
                id="split-button-menu"
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
            >
                <MenuItem
                    id="status-menu"
                    onClick={() => {
                        updateStatus(newStatus)
                        setOpen(false)
                    }}
                    dense
                >
                    {newStatusText}
                </MenuItem>
            </Menu>
        </>
    )
}

const getCategoriesPath = (id: number, categoriesById: { [key: number]: Category }): string[] => {
    const category = categoriesById[id]
    if (category.parent_id === null) {
        return [category.name]
    }
    return [...getCategoriesPath(category.parent_id, categoriesById), category.name]
}

export default function QuoteTemplatesListPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const { hasPermission } = useAuthContext()

    const { quoteTemplates } = useSelector((state) => state.quoteTemplates)
    const {
        categories: { byId: categoriesById },
    } = useSelector((state) => state.categories)

    useEffect(() => {
        dispatch(getQuoteTemplates())
        dispatch(getCategories())
    }, [dispatch])

    // console.log({ quotes, categoriesById })

    const onDelete = async (id: number) => {
        if (window.confirm("Are you sure you want to delete this quote template?")) {
            const response = await dispatch(deleteQuoteTemplate(id))
            if (response.status === 204) {
                enqueueSnackbar("Quote template deleted successfully", { variant: "success" })
            }
        }
    }

    const onUpdateStatus = (id: number, status: "active" | "inactive") => {
        dispatch(updateQuoteTemplate(id, { status }))
    }

    return (
        <>
            <Helmet>
                <title>Quote Templates</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Quote Templates"
                    links={[
                        {
                            name: "Quote Templates",
                        },
                        // {
                        //     name: '',
                        //     href: PATHS.quotes.lineItemTypes.list()
                        // },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.quotes.quoteTemplates.create())}
                            disabled={!hasPermission(Permissions.QUOTE_TEMPLATE_CREATE)}
                        >
                            Create a Quote Template
                        </Button>
                    }
                />
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quoteTemplates.map((template) => {
                                    let categoriesPath: string[] = []
                                    if (
                                        template.category_id &&
                                        categoriesById[template.category_id]
                                    ) {
                                        // const category = categoriesById[template.category_id]
                                        categoriesPath = getCategoriesPath(
                                            template.category_id,
                                            categoriesById
                                        )
                                    }
                                    return (
                                        <TableRow key={template.id} hover>
                                            <TableCell>{template.name}</TableCell>
                                            <TableCell>{template.description}</TableCell>
                                            <TableCell>{categoriesPath.join(" > ")}</TableCell>
                                            <TableCell>
                                                <StatusButton
                                                    status={template.status}
                                                    updateStatus={(
                                                        status: "active" | "inactive"
                                                    ) => {
                                                        onUpdateStatus(template.id, status)
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ButtonGroup variant="outlined" size="small">
                                                    <Button
                                                        onClick={() =>
                                                            navigate(
                                                                PATHS.quotes.quoteTemplates.details(
                                                                    template.id
                                                                )
                                                            )
                                                        }
                                                        disabled={
                                                            !hasPermission(
                                                                Permissions.QUOTE_TEMPLATE_UPDATE
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => onDelete(template.id)}
                                                        color="error"
                                                        disabled={
                                                            !hasPermission(
                                                                Permissions.QUOTE_TEMPLATE_DELETE
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
