import { LoadingButton } from "@mui/lab"
import * as Yup from "yup"
import { Button, Card, CardContent, Stack } from "@mui/material"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { RHFTextField } from "src/components/hook-form"
import FormProvider from "src/components/hook-form/FormProvider"
import { PATHS } from "src/routes/paths"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import CategorySelectTree from "../category-select"

type CategoryFormData = {
    id: number | null
    name: string
    description: string
    parent_id: number | null
}

type CategoryFormProps = {
    initialData?: CategoryFormData
    onSubmit: (data: CategoryFormData) => void
}

export default function CategoryForm({ initialData, onSubmit }: CategoryFormProps) {
    const navigate = useNavigate()

    const defaultValues = {
        id: null,
        name: "",
        description: "",
        parent_id: null,
    }

    const CategorySchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        // description: Yup.string().required("Description is required"),
    })

    const methods = useForm<CategoryFormData>({
        resolver: yupResolver(CategorySchema),
        defaultValues,
    })

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData)
        }
    }, [initialData, methods])

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        <Stack direction="column" spacing={2} flex={1}>
                            <RHFTextField name="name" label="Name" required autoFocus />
                            <RHFTextField name="description" label="Description" />
                        </Stack>
                        <Stack direction="column" spacing={2} sx={{ mt: 2 }} flex={1}>
                            <CategorySelectTree
                                current={initialData?.parent_id?.toString() || "none"}
                                onChange={(value) => methods.setValue("parent_id", value)}
                                disable={[initialData?.id?.toString() || ""]}
                                emptyLabel="Root Category"
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.categories.list())}
                >
                    Cancel
                </Button>
                <LoadingButton
                    size="small"
                    variant="contained"
                    loading={false}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </LoadingButton>
            </Stack>
        </FormProvider>
    )
}
