import { Autocomplete, IconButton, Stack, TextField, Typography } from "@mui/material"
import { Draggable, Droppable } from "react-beautiful-dnd"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { TQuoteTemplateSection } from "src/@types/quote-template-section"
import { useDispatch } from "src/redux/store"
import {
    createQuoteTemplateItem,
    deleteQuoteTemplateSection,
} from "src/redux/slices/quote-templates"
import { useSnackbar } from "notistack"
import { TLineItemType } from "src/@types/lineItemType"
import { TQuoteTemplate, TQuoteTemplateItem } from "src/@types/quote-template"
import { useState } from "react"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"
import QuoteTemplateItem from "./item"
import EditQuoteTemplateSectionDialog from "./edit-section-dialog"

interface QuoteTemplateSectionProps {
    template: TQuoteTemplate
    section: TQuoteTemplateSection | null
    items: TQuoteTemplateItem[]
    lineItemTypes: TLineItemType[]
}

const QuoteTemplateSection = ({
    template,
    section,
    items,
    lineItemTypes,
}: QuoteTemplateSectionProps) => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { hasPermission } = useAuthContext()

    const [isSending, setIsSending] = useState(false)
    const [lineItemTypeToAdd, setLineItemTypeToAdd] = useState<TLineItemType | null>(null)
    const [editedSection, setEditedSection] = useState<TQuoteTemplateSection | null>(null)

    const onDeleteSection = async (sect: TQuoteTemplateSection) => {
        if (
            window.confirm("Are you sure you want to delete this section and all Services in it?")
        ) {
            const response = await dispatch(deleteQuoteTemplateSection(sect))
            if (response.status === 204) {
                enqueueSnackbar("Service deleted successfully", { variant: "success" })
            }
        }
    }

    const onCreateLineItemItem = async (
        lineItemType: TLineItemType | null,
        sectionId: number | null
    ) => {
        setLineItemTypeToAdd(lineItemType)
        if (!template.id || !lineItemType) return
        setIsSending(true)
        console.log("Service:", { lineItemType })
        let order = 0
        if (items.length > 0) {
            order = Math.max(...items.map((item) => item.order)) + 1
        }
        const response = await dispatch(
            createQuoteTemplateItem({
                quote_template_id: template.id,
                line_item_type_id: lineItemType.id,
                order,
                section_id: sectionId,
            })
        )
        if (response.status === 201) {
            setIsSending(false)
            setLineItemTypeToAdd(null)
            enqueueSnackbar(`Service ${lineItemType.name} added to the template`, {
                variant: "success",
            })
        } else {
            setIsSending(false)
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    return (
        <>
            <Droppable droppableId={`section-${section?.id || "null"}`}>
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Stack
                            direction="row"
                            spacing={0}
                            sx={{ mt: 4, mb: 2 }}
                            alignItems="center"
                        >
                            <Typography variant="h6" component="h2" mr={2}>
                                {section ? `${section.name} (${section.order})` : "No Section"}
                            </Typography>
                            {section && (
                                <>
                                    <IconButton
                                        size="small"
                                        color="default"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setEditedSection(section)
                                            // handleOpenMenu(e)
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        color="default"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onDeleteSection(section)
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            )}
                        </Stack>
                        {items.map((item, index) => {
                            if (!item.line_item_type) return null
                            if (item.section_id !== (section?.id || null)) return null
                            return (
                                <Draggable
                                    key={item.id}
                                    draggableId={`item_${item.id}`}
                                    index={index}
                                    isDragDisabled={
                                        !hasPermission(Permissions.QUOTE_TEMPLATE_UPDATE)
                                    }
                                >
                                    {(pr) => (
                                        <div
                                            ref={pr.innerRef}
                                            {...pr.draggableProps}
                                            {...pr.dragHandleProps}
                                        >
                                            <QuoteTemplateItem item={item} />
                                        </div>
                                    )}
                                </Draggable>
                            )
                        })}
                        {provided.placeholder}
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={1}
                            alignItems="center"
                            sx={{ mb: 0, mt: 1 }}
                        >
                            <Autocomplete
                                id="line-item-types-dropdown"
                                getOptionLabel={(option) => option.name}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: TLineItemType | null
                                ) => {
                                    onCreateLineItemItem(value, section?.id || null)
                                }}
                                value={lineItemTypeToAdd}
                                disabled={
                                    isSending ||
                                    !hasPermission(Permissions.QUOTE_TEMPLATE_ITEM_CREATE)
                                }
                                options={lineItemTypes}
                                sx={{ width: "100%" }}
                                renderInput={(params: any) => (
                                    <TextField {...params} label="Add a Service" />
                                )}
                            />
                        </Stack>
                    </div>
                )}
            </Droppable>
            <EditQuoteTemplateSectionDialog
                onClose={() => setEditedSection(null)}
                section={editedSection}
            />
        </>
    )
}

export default QuoteTemplateSection
