import { JobCategory, JobVisitType } from 'src/@types/jobs'
import axios from '../utils/axios'

export type FetchJobFilters = {
    status?: string[]
    orderBy?: string
    category?: JobCategory[]
    visitType?: JobVisitType[]
    visitFrom?: string
    visitTo?: string
    search?: string
    serializer?: string
    statusNot?: string[]
    hasVisits?: {
        dateFrom: string
        dateTo: string
        type: JobVisitType[]
    }
}


const apiFetchJobs = async (filters: FetchJobFilters) => {
    const params: any = {}
    if (filters.status && filters.status.length > 0) {
        params.status__in = filters.status.join(',')
    }
    if (filters.statusNot && filters.statusNot.length > 0) {
        params.status__not_in = filters.statusNot.join(',')
    }
    if (filters.hasVisits) {
        params.with_visits_of_types_and_in_range = `${filters.hasVisits.type.join(',')};${filters.hasVisits.dateFrom},${filters.hasVisits.dateTo}`
    }
    if (filters.category && filters.category.length > 0) {
        params.category__in = filters.category.join(',')
    }
    if (filters.orderBy) {
        params.order_by = filters.orderBy
    }
    if (filters.visitType && filters.visitType.length > 0) {
        params.visits__type__in = filters.visitType.join(',')
    }
    if (filters.visitFrom && filters.visitTo) {
        params.visits__date__range = `${filters.visitFrom},${filters.visitTo}`
    } else {
        if (filters.visitFrom) {
            params.visits__date__gte = filters.visitFrom
        }
        if (filters.visitTo) {
            params.visits__date__lte = filters.visitTo
        }
    }
    if (filters.search && filters.search?.length > 0) {
        params.search = filters.search
    }
    if (filters.serializer) {
        params.serializer = filters.serializer
    }

    const response = await axios.get('/api/v1/data/jobs', {params})
    return response;
}


const apiFetchEmployees = () => axios.get('/api/v1/data/employees')
    

export { apiFetchJobs, apiFetchEmployees }