import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import AddIcon from "@mui/icons-material/Add"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { deleteContainer, getContainers } from "src/redux/slices/containers"
import { TContainer } from "src/@types/containers"
import { useAuthContext } from "src/auth/useAuthContext"
import { Permissions } from "src/@types/security"

export default function ContainersListPage() {
    const dispatch = useDispatch()
    const containers = useSelector((state) => state.containers.containers)

    const navigate = useNavigate()
    const { hasPermission } = useAuthContext()

    useEffect(() => {
        dispatch(getContainers())
    }, [dispatch])

    const onDelete = async (container: TContainer) => {
        if (!window.confirm("Are you sure you want to delete this container?")) return
        const response = await dispatch(deleteContainer(container.id))
        if (response.status === 204) {
            console.log(`Container ${container.name} deleted successfully`)
        }
    }

    console.log(containers)

    return (
        <>
            <Helmet>
                <title>Containers</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Containers"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Containers",
                            href: PATHS.quotes.containers.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.quotes.containers.create())}
                            disabled={!hasPermission(Permissions.CONTAINER_CREATE)}
                        >
                            Create Container
                        </Button>
                    }
                />
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {containers.map((container) => (
                                    <TableRow key={container.id} hover>
                                        <TableCell>{container.name}</TableCell>
                                        <TableCell>{container.type}</TableCell>
                                        <TableCell>{container.value}</TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="outlined" size="small">
                                                <Button
                                                    onClick={() =>
                                                        navigate(
                                                            PATHS.quotes.containers.details(
                                                                container.id
                                                            )
                                                        )
                                                    }
                                                    disabled={
                                                        !hasPermission(Permissions.CONTAINER_UPDATE)
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="error"
                                                    onClick={() => onDelete(container)}
                                                    disabled={
                                                        !hasPermission(Permissions.CONTAINER_DELETE)
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
